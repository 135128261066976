import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { RolePermissionConfigurationComponent } from './role-permission-configuration.component';
import { MsalGuard } from '@azure/msal-angular';
import { PermissionGuard } from '../authorization/permission.guard';

const rolePermissionConfiguraionRoutes: Routes = [
  {
    path: '',
    //component: AppLayoutComponent,
    children: [
      {
        path: 'RolePermissionConfiguration', component: RolePermissionConfigurationComponent,
        canActivate: [MsalGuard, PermissionGuard], data: { perminssion: 'rolepermission.create', title: 'Create Role Permission Configuration' }
      }
    ]
  }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(rolePermissionConfiguraionRoutes)
  ]
})
export class RolePermissionConfigurationRoutingModule { }
