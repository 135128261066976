import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NGXLogger } from 'ngx-logger';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonFunction } from '../../../app_data/commonFunction';
import { ReferenceLink } from '../../../app_data/referenceLink';
import { DialogService } from '../../../services/dialog.service';
import * as myGlobals from '../../../app_data/globals';
import { SafeDomainList } from '../../../app_data/safedomainlist';
import { CommunicationService } from '../../../services/communication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { RolePermissionService } from '../../../services/rolepermission.service';
import { ProjectService } from '../../../services/project.service';
import { Title } from '@angular/platform-browser';

let ReferenceLinklists: ReferenceLink[] = [];

@Component({
  selector: 'app-reference-link-edit',
  templateUrl: './reference-link-edit.component.html',
  styleUrls: ['./reference-link-edit.component.css']
})
export class ReferenceLinkEditComponent implements OnInit, OnChanges {
  noRecordFoundMsg: string = myGlobals.NoRecordFound;
  referenceLink !: ReferenceLink;
  refLinks: ReferenceLink[] = [];
  docLinkForm !: FormGroup;
  referenceLinkCount: string = "0";
  safedomainval: boolean = false;
  linkCount: number = 0;
  commonFunction !: CommonFunction;

  _contractorID = parseInt(sessionStorage.getItem("contractorId")!);
  _userName = sessionStorage.getItem("userFName") + " " + sessionStorage.getItem("userLName");
  _userIdLogin = parseInt(sessionStorage.getItem("userId")!);
  _projectID = parseInt(sessionStorage.getItem("projectID")!);
  _projectTimeZone = sessionStorage.getItem("projectTimeZone")!;
  _dateTimeFormat = sessionStorage.getItem("projectDateTimeformat")!;
  _userRoleMappingId = parseInt(sessionStorage.getItem("userRoleMappingId")!);
  utcoffsetvalue: string = sessionStorage.getItem("projectTimeZone")!.substring(0, sessionStorage.getItem("projectTimeZone")!.lastIndexOf(':'))!;

  displayedColumns: string[] = ['Title', 'SubmittedByUserID', 'SubmittedDate', 'Action'];
  dataSourceLinks = new MatTableDataSource<ReferenceLink>(ReferenceLinklists);

  @Input() domainlist: SafeDomainList[] = [];
  @Output() refLinksadded: EventEmitter<ReferenceLink[]> = new EventEmitter();
  @Output() refLinkCountadded: EventEmitter<string> = new EventEmitter();
  @Input() isControlDisable: boolean = false;
  constructor(private logger: NGXLogger, private communicationService: CommunicationService,
    private rolePermissionService: RolePermissionService, private projectService: ProjectService,
    private activatedRoute: ActivatedRoute,  private title: Title,
    private SpinnerService: NgxSpinnerService, private dialog: DialogService, private router:Router) {
    this.referenceLink = new ReferenceLink();
    this.commonFunction = new CommonFunction(router, projectService, rolePermissionService, activatedRoute, title);
  }

  @ViewChild('paginatorLinks') paginatorLinks !: MatPaginator;
  @ViewChild('sortLinks') sortLinks !: MatSort;

  @ViewChild('sortLinks') set matSort2(ms: MatSort) {
    this.sortLinks = ms;
    this.setDataSourceAttributes2();
  }

  setDataSourceAttributes2() {
    this.dataSourceLinks.paginator = this.paginatorLinks;
    this.dataSourceLinks.sort = this.sortLinks;
  }

  ngOnInit(): void {
    this.docLinkForm = new FormGroup({
      docLink: new FormControl(null, [Validators.pattern("((http|https)://)(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)")]),
      docLinkTitle: new FormControl()
    });

    this.communicationService.is_safedomainList$.subscribe(emivalue => {
      this.domainlist = emivalue.filter(x => !x.IsDeleted);
    });

    this.communicationService.is_reflinks$.subscribe(emivalue => {
      this.refLinks = emivalue.filter(x => !x.IsDeleted);
      this.dataSourceLinks.data = emivalue.filter(x => !x.IsDeleted);
    });     
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      !!changes &&
      !!changes['isControlDisable'] &&
      !!changes['isControlDisable'].currentValue
    ) {
      if (this.isControlDisable) {
        this.docLinkForm.disable();
        this.isControlDisable = true;
      } else {
        this.docLinkForm.enable();
        this.isControlDisable = false;
      }
    }
  }
  
  addLink() {
    try {
      if (this.domainlist.length > 0) {
        this.safedomainval = CommonFunction.ValidateURLWithSafeDomain(this.referenceLink.Link, this.domainlist);
        if (this.safedomainval) {
          this.linkCount = this.refLinks.length;
          let uniqueCheck = this.refLinks.filter(x => !x.IsDeleted && x.Title.toLowerCase() == this.referenceLink.Title.toLowerCase());
          if (uniqueCheck.length <= 0) {
            this.refLinks.push({ Id: 0, Link: this.referenceLink.Link, Title: this.referenceLink.Title, ContractorID: this._contractorID, ProjectId: this._projectID, IsDeleted: false, CreatedBy: this._userRoleMappingId })
            var oldlinks = this.refLinks.length;
            this.dataSourceLinks.data = this.refLinks.filter(x => !x.IsDeleted).reverse();
            this.refLinksadded.emit(this.refLinks.filter(x => !x.IsDeleted));
            var newlinks = Number(this.linkCount) == oldlinks ? oldlinks : (oldlinks - Number(this.linkCount));
            var finaldoc = this.linkCount + " + " + newlinks + "*";
            this.referenceLinkCount = finaldoc;
            this.refLinkCountadded.emit(this.referenceLinkCount);
            this.docLinkForm.reset();
          }
          else {
            this.dialog.confirmDialog({
              title: 'Warning',
              message: 'Title already exists',
              cancelCaption: 'Close',
            });
          }
        }
        else {
          this.docLinkForm.get('docLink')?.setErrors({ safedomainval: true })
        }
      } else {
        let uniqueCheck = this.refLinks.filter(x => !x.IsDeleted && x.Title.toLowerCase() == this.referenceLink.Title.toLowerCase());
        if (uniqueCheck.length <= 0) {
          this.linkCount = this.refLinks.length;
          this.refLinks.push({ Id: 0, Link: this.referenceLink.Link, Title: this.referenceLink.Title, ContractorID: this._contractorID, ProjectId: this._projectID, IsDeleted: false, CreatedBy: this._userRoleMappingId })
          var oldlinks = this.refLinks.length;
          this.dataSourceLinks.data = this.refLinks.filter(x => !x.IsDeleted).reverse();
          this.refLinksadded.emit(this.refLinks.filter(x => !x.IsDeleted));
          var newlinks = Number(this.linkCount) == oldlinks ? oldlinks : (oldlinks - Number(this.linkCount));
          var finaldoc = this.linkCount + " + " + newlinks + "*";
          this.referenceLinkCount = finaldoc;
          this.refLinkCountadded.emit(this.referenceLinkCount);
          this.docLinkForm.reset();
        }
        else {
          this.dialog.confirmDialog({
            title: 'Warning',
            message: 'Title already exists',
            cancelCaption: 'Close',
          });
        }
      }
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  openLink(url: string) {
    window.open(url, "_blank");
  }

  deleteLink(title: any) {
    try {
      let refLinkModel = this.refLinks.find(item => !item.IsDeleted && item.Title === title);
      if (refLinkModel) {
        refLinkModel.IsDeleted = true;
        refLinkModel.ModifiedBy = this._userRoleMappingId;
      }
      this.dataSourceLinks.data = this.refLinks.filter(x => !x.IsDeleted).reverse();
      this.refLinksadded.emit(this.refLinks);
      this.referenceLinkCount = this.dataSourceLinks.data.length.toString();
      this.refLinkCountadded.emit(this.referenceLinkCount);
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  confirmCancelDialog(Title: string, Type: string) {
    try {
      this.dialog
        .confirmDialog({
          title: 'Confirm Action',
          message: 'Do you want to delete this ' + Type + '?',
          confirmCaption: 'Confirm',
          cancelCaption: 'Cancel',
        })
        .subscribe((confirmed) => {
          if (confirmed) {
            if (Type != 'document') {
              this.deleteLink(Title);
            }
          }
        });
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }

  }
}
