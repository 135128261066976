import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactCreateComponent } from './contact-create.component';
import { RouterModule, Routes } from '@angular/router';
import { ContactListComponent } from './contact-list.component';
import { ContactEditComponent } from './contact-edit.component';
import { AppLayoutComponent } from '../app-layout/app-layout.component';
import { PermissionGuard } from '../authorization/permission.guard';
import { MsalGuard } from '@azure/msal-angular';

const contactRoutes: Routes = [
  {
    path: '',
    //component: AppLayoutComponent,
    children: [
      {
        path: 'Contact', component: ContactCreateComponent,
        canActivate: [MsalGuard,PermissionGuard], data: { perminssion: 'contact.create', title: 'Create Contact' }
      },
      {
        path: 'Contacts', component: ContactListComponent,
        canActivate: [MsalGuard,PermissionGuard], data: { perminssion: 'contact.view', title: 'Contacts' }
      },
      {
        path: 'ContactEdit/:id', component: ContactEditComponent,
        canActivate: [MsalGuard, PermissionGuard], data: { perminssion: 'contact.edit', title: 'Edit Contact' }
      }
    ]
  }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(contactRoutes)
  ]
})
export class ContactRoutingModule { }
