import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { response } from '../app_data/OdataResponse';
import { RolePermission } from '../app_data/rolepermission';

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
    "Accept": "*/*",
  }
  )
}

@Injectable({
  providedIn: 'root'
})
export class RolePermissionService {
  private apiUrl = environment.apiURl + 'RolePermissions';
  constructor(private http: HttpClient) { }

  getRolePermissions(): Observable<RolePermission[]> {
    return this.http.get<response>(this.apiUrl)
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  getPermissionByRole(roleId: number): Observable<RolePermission[]> {
    return this.http.get<response>(this.apiUrl + "?$expand=Permission($expand=ModulePermissionMappings)&$filter=IsDeleted eq false and RoleId eq " + roleId)
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  getPermissionByRoleWithPromise(roleId: number): Promise<any> {
    return this.http.get<response>(this.apiUrl + "?$expand=Permission($expand=ModulePermissionMappings)&$filter=IsDeleted eq false and RoleId eq " + roleId).toPromise();
  }

  private handleError(err: HttpErrorResponse) {

    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {

      errorMessage = `An error occurred: ${err.error.message}`;
    } else {

      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    return throwError(errorMessage);
  }
}
