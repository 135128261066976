import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { response } from '../app_data/OdataResponse';
import { Project } from '../app_data/project';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
    "Accept": "*/*",
  }
  )
}

@Injectable({
  providedIn: 'root'
})

export class ProjectService {
  private apiUrl = environment.apiURl + 'Projects';

  constructor(private http: HttpClient) { }
  projectModel !: Project;
  errorMessage = '';

  getProjects(): Observable<Project[]> {
    return this.http.get<response>(this.apiUrl + "?$filter=IsDeleted eq false &$expand=Projectstatus")
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  getProject(id: number): Observable<Project[]> {
    return this.http.get<response>(this.apiUrl + "?$filter=Id eq " + id + "&$expand=Settings,SafeDomainLists,Projectstatus,ProjectFieldsConfigurations,ModuleProjectMappings($filter=IsDeleted eq false;$expand=Module)")
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  getProjectWithPromise(id: number): Promise<any> {
    return this.http.get<response>(this.apiUrl + "?$filter=Id eq " + id + "&$expand=Settings,SafeDomainLists,Projectstatus,ProjectFieldsConfigurations,ModuleProjectMappings($filter=IsDeleted eq false;$expand=Module)").toPromise();
  }

  getProjectByUserId(userId: number, projectIds: string):Observable<Project[]>{    
    return this.http.get<response>(this.apiUrl + "?$expand=UserRoleProjects($filter=UserId eq " + userId +" and IsDeleted eq false and Contractor/IsDeleted eq false;$expand=role,project,Contractor;$orderby=ContractorId)&$filter=Id in ("+ projectIds +")")
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  post(model: Project): Observable<Project> {
    return this.http.post<Project>(this.apiUrl, model, httpOptions);
  }

  put(model: Project): Observable<Project> {
    return this.http.put<Project>(this.apiUrl + "/" + model.Id, model, httpOptions);
  }

  private handleError(err: HttpErrorResponse) {

    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {

      errorMessage = `An error occurred: ${err.error.message}`;
    } else {

      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    return throwError(errorMessage);
  }
}
