<div class="container-fluid">
  <mat-card class="">
    <mat-card-content>
      <p class="pPageHeading">{{pageTitle}}</p>
      <form [formGroup]="form" [appMatchDate]="['ifiDateFormControl','iaiDateFormControl']" class="interfaceTab">
        <mat-tab-group animationDuration="0ms">

          <mat-tab label="Technical Query">
            <div class="h500tabMin scroller">
              <div class="row">
                <div class="col-xl-8 col-l-8 col-md-8 col-sm-12 col-xs-12">
                  <mat-form-field appearance="standard">
                    <mat-label>Title</mat-label>
                    <input matInput #input autocomplete="off" maxlength="256" placeholder="Title" name="Title" [(ngModel)]="interfaceModel.Title"
                           formControlName="titleFormControl" required appTrimWhitespace>
                    <mat-error *ngIf="this.form.get('titleFormControl')?.hasError('required')">
                      Title is <strong>required</strong>
                    </mat-error>
                    <mat-hint align="end">{{interfaceModel.Title?.length || 0}}/256</mat-hint>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-4 col-l-4 col-md-8 col-sm-12 col-xs-12">
                  <mat-form-field appearance="standard">
                    <mat-label>Interface Group</mat-label>
                    <mat-select [(value)]="selectedInterfaceGroup" [(ngModel)]="interfaceModel.InterfaceGroupID" (selectionChange)="onInterfaceGroupSelected(selectedInterfaceGroup)"
                                formControlName="interfaceGroupFormControl" [disabled]="_isRevision" required>
                      <mat-option *ngFor="let ig of interfaceGroups" [value]="ig.Id">{{ ig.Name }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="this.form.get('interfaceGroupFormControl')?.hasError('required')">
                      Interface Group is <strong>required</strong>
                    </mat-error>
                  </mat-form-field>
                </div>

                <div class="col-xl-4 col-l-4 col-md-8 col-sm-12 col-xs-12">
                  <mat-form-field appearance="standard">
                    <mat-label>Status</mat-label>
                    <mat-select [(value)]="selectedStatus" name="Status" [(ngModel)]="interfaceModel.Status"
                                formControlName="statusFormControl" required>
                      <mat-option *ngFor="let st of status" [value]="st.Id">{{ st.Name }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="this.form.get('statusFormControl')?.hasError('required')">
                      Status is <strong>required</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <!--<div class="row" *ngIf="this.isDisciplineLead">
                <div class="col-xl-4 col-l-4 col-md-8 col-sm-12 col-xs-12">
                  <mat-form-field appearance="standard">
                    <mat-label>Request Approver</mat-label>
                    <mat-select [(value)]="selectedApprover" name="RequestApproverId" [(ngModel)]="interfaceModel.RequestApproverId"
                                formControlName="requestApproverFormControl" [required]="this.isDisciplineLead">
                      <mat-option *ngFor="let ap of approvers" [value]="ap.Id">{{ ap.FirstName + ' '+ap.LastName }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="this.form.get('requestApproverFormControl')?.hasError('required')">
                      Request Approver is <strong>required</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>-->
              <div class="row">
                <div class="col-xl-4 col-l-4 col-md-8 col-sm-12 col-xs-12">
                  <mat-form-field appearance="standard">
                    <mat-label>{{requestingContractorLabel}}</mat-label>
                    <mat-select [(value)]="selectedRequestingContractor" (selectionChange)="onRequestingContractorSelected(selectedRequestingContractor)" name="ReceivingContractorID"
                                [(ngModel)]="interfaceModel.ReceivingContractorID" formControlName="requestingContractorFormControl" [disabled]="true"
                                required>
                      <mat-option *ngFor="let reqCon of requestingContractors" [value]="reqCon.Id">
                        {{ reqCon.Name }}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="this.form.get('requestingContractorFormControl')?.hasError('required')">
                      {{requestingContractorLabel}} is <strong>required</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-xl-4 col-l-8 col-md-8 col-sm-12 col-xs-12">
                  <mat-form-field appearance="standard">
                    <mat-label>{{providerContractorLabel}}</mat-label>
                    <mat-select [(value)]="selectedProviderContractor" (selectionChange)="onProviderContractorSelected(selectedProviderContractor)" name="ResponsibleContractorID"
                                [(ngModel)]="interfaceModel.ResponsibleContractorID" formControlName="providerContractorFormControl" [disabled]="true"
                                required>
                      <mat-option *ngFor="let prCon of providerContractors" [value]="prCon.Id">{{ prCon.Name }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="this.form.get('providerContractorFormControl')?.hasError('required')">
                      {{providerContractorLabel}} is <strong>required</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-4 col-l-4 col-md-8 col-sm-12 col-xs-12">
                  <mat-form-field appearance="standard">
                    <mat-label>{{this.isDualDisciplinebool ? requestingDisciplineLabel : "Discipline"}}</mat-label>
                    <mat-select [(value)]="selectedRequestingDisciplines" name="ReceivingContractorDisciplineID"
                                [(ngModel)]="interfaceModel.ReceivingContractorDisciplineID" [disabled]="_isRevision"
                                formControlName="requestingDisciplineFormControl" required>
                      <mat-option *ngFor="let disc of requestingDisciplines" [value]="disc.Id">{{ disc.Name }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="this.form.get('requestingDisciplineFormControl')?.hasError('required')">
                      {{this.isDualDisciplinebool ? requestingDisciplineLabel : "Discipline"}} is <strong>required</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-xl-4 col-l-4 col-md-8 col-sm-12 col-xs-12" *ngIf="this.isDualDisciplinebool">
                  <mat-form-field appearance="standard">
                    <mat-label>{{providerDisciplineLabel}}</mat-label>
                    <mat-select [(value)]="selectedProviderDisciplines" name="ResponsibleContractorDisciplineID"
                                [(ngModel)]="interfaceModel.ResponsibleContractorDisciplineID" [disabled]="_isRevision"
                                formControlName="providerDisciplineFormControl" required>
                      <mat-option *ngFor="let disc of disciplines" [value]="disc.Id">{{ disc.Name }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="this.form.get('providerDisciplineFormControl')?.hasError('required')">
                      {{providerDisciplineLabel}} is <strong>required</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-2 col-l-2 col-md-2 col-sm-12 col-xs-12">
                  <mat-form-field appearance="standard">
                    <mat-label>Is Confidential?</mat-label>
                    <mat-select name="IsConfidential" [(ngModel)]="this.isConfidential" (selectionChange)="onConfidentialitySelected(this.isConfidential!)"
                                formControlName="isConfidentialFormControl" required>
                      <mat-option *ngFor="let option of confidentialityOptions" [value]="(option==1)?true:false">{{ IsConfidential2LabelMapping[option] }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="this.form.get('isConfidentialFormControl')?.hasError('required')">
                      Is Confidential is <strong>required</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-xl-6 col-l-6 col-md-6 col-sm-12 col-xs-12">
                  <mat-form-field appearance="standard" *ngIf="this.isConfidential">
                    <mat-label>Confidential Justification</mat-label>
                    <input matInput #input autocomplete="off" maxlength="1000" placeholder="Confidential Justification" name="ConfidentialJustification"
                           formControlName="confidentialJustificationFormControl" [(ngModel)]="technicalQuery.ConfidentialJustification" [required]="technicalQuery.IsConfidential" appTrimWhitespace>
                    <mat-error *ngIf="this.form.get('confidentialJustificationFormControl')?.hasError('required')">
                      Confidential Justification is <strong>required</strong>
                    </mat-error>
                    <mat-hint align="end">{{technicalQuery.ConfidentialJustification?.length || 0}}/1000</mat-hint>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-2 col-l-2 col-md-2 col-sm-12 col-xs-12">
                  <mat-form-field appearance="standard">
                    <mat-label>Priority</mat-label>
                    <mat-select [(ngModel)]="interfaceModel.Priority" name="Priority" (selectionChange)="onPrioritySelected(selectedPriority!, true)"
                                formControlName="priorityFormControl" [(value)]="selectedPriority" required>
                      <mat-option *ngFor="let pr of priority" [value]="pr.Id">{{ pr.Name }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="this.form.get('priorityFormControl')?.hasError('required')">
                      Priority is <strong>required</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-xl-6 col-l-6 col-md-6 col-sm-12 col-xs-12">
                  <mat-form-field appearance="standard" *ngIf="showJustification">
                    <mat-label>Priority Justification</mat-label>
                    <input matInput #input autocomplete="off" maxlength="1000" placeholder="Priority Justification" name="PriorityJustification"
                           formControlName="priorityJustificationFormControl" [(ngModel)]="technicalQuery.PriorityJustification" [required]="this.showJustification" appTrimWhitespace>
                    <mat-error *ngIf="this.form.get('priorityJustificationFormControl')?.hasError('required')">
                      Priority Justification is <strong>required</strong>
                    </mat-error>
                    <mat-hint align="end">{{technicalQuery.PriorityJustification?.length || 0}}/1000</mat-hint>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-4 col-l-4 col-md-8 col-sm-12 col-xs-12">
                  <mat-form-field appearance="standard">
                    <mat-label>Query Type</mat-label>
                    <mat-select name="QueryType" [(ngModel)]="technicalQuery.QueryTypeId"
                                formControlName="queryTypeFormControl" required>
                      <mat-option *ngFor="let qt of queryTypes" [value]="qt.Id">{{ qt.Name }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="this.form.get('queryTypeFormControl')?.hasError('required')">
                      Query Type is <strong>required</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-xl-4 col-l-4 col-md-8 col-sm-12 col-xs-12">
                </div>
              </div>
              <div class="row">
                <div class="col-xl-4 col-l-4 col-md-2 col-sm-12 col-xs-12 hide-blue-border">
                  <mat-form-field appearance="standard">
                    <mat-label>{{expectedResponseDateLabel}}</mat-label>
                    <input matInput [matDatepicker]="picker" [min]="minDate" formControlName="ifiDateFormControl" [(ngModel)]="interfaceModel.FirstIssuePlanned"
                           (dateChange)="onFirstResDateChange('change', $event)" disabled>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker disabled="false"></mat-datepicker>
                    <mat-error *ngIf="this.form.get('ifiDateFormControl')?.hasError('required')">
                      {{expectedResponseDateLabel}} is <strong>required</strong>
                    </mat-error>
                    <mat-error *ngIf="this.form.get('ifiDateFormControl')?.hasError('dateMismatch')">
                      {{expectedResponseDateLabel}} should not be greater than {{closeOutDateLabel}}.
                    </mat-error>
                  </mat-form-field>

                </div>

                <div class="col-xl-4 col-l-4 col-md-2 col-sm-12 col-xs-12 hide-blue-border">
                  <mat-form-field appearance="standard">
                    <mat-label>{{closeOutDateLabel}}</mat-label>
                    <input matInput [matDatepicker]="topicker" [min]="minCloseOutDate" [(ngModel)]="interfaceModel.ApprovedIssuePlanned" formControlName="iaiDateFormControl"
                           (dateChange)="onApprovedResDateChange('change', $event)" disabled>
                    <mat-datepicker-toggle matSuffix [for]="topicker"></mat-datepicker-toggle>
                    <mat-datepicker #topicker disabled="false"></mat-datepicker>
                    <mat-error *ngIf="this.form.get('iaiDateFormControl')?.hasError('required')">
                      {{closeOutDateLabel}} is <strong>required</strong>
                    </mat-error>
                    <mat-error *ngIf="this.form.get('iaiDateFormControl')?.hasError('dateMismatch')">
                      {{closeOutDateLabel}} should be greater than {{expectedResponseDateLabel}}.
                    </mat-error>
                  </mat-form-field>

                </div>
              </div>
              <div class="row">
                <div class="col-xl-6 col-l-6 col-md-6 col-sm-12 col-xs-12">
                  <mat-form-field appearance="standard">
                    <mat-label>Request Description</mat-label>
                    <textarea matInput formControlName="descriptionFormControl" maxlength="3000" name="Description"
                              [(ngModel)]="interfaceModel.Description" class="heightTextArea" required appTrimWhitespace></textarea>
                    <mat-error *ngIf="this.form.get('descriptionFormControl')?.hasError('required')">
                      Description is <strong>required</strong>
                    </mat-error>
                    <mat-hint align="end">{{interfaceModel.Description?.length || 0}}/3000</mat-hint>
                  </mat-form-field>
                </div>

                <div class="col-xl-6 col-l-6 col-md-6 col-sm-12 col-xs-12">
                  <mat-form-field appearance="standard">
                    <mat-label>Additional Comments</mat-label>
                    <textarea matInput class="heightTextArea" maxlength="1500" formControlName="comments" name="Comments"
                              [(ngModel)]="interfaceModel.Comments" appTrimWhitespace></textarea>
                    <mat-hint align="end">{{interfaceModel.Comments?.length || 0}}/1500</mat-hint>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </mat-tab>

          <mat-tab label="Documents  {{documentCount != ''? '(' + documentCount +')' : ''}}">
            <div class="h500tabMin">
              <app-document-create [maxFileSize]="maxFileSize" (documentCountadded)="documentCountaddedHandler($event)" (documentsadded)="documentsaddedHandler($event)" (uploadDocsadded)="uploadDocsaddedHandler($event)">
              </app-document-create>
            </div>
          </mat-tab>

          <mat-tab label="Links {{referenceLinkCount != ''? '(' + referenceLinkCount +')' : ''}}">
            <div class="h500tabMin scroller">
            <app-reference-link-create [domainlist]="domainlist" (refLinksadded)="refLinksaddedHandler($event)" (refLinkCountadded)="refLinkCountaddedHandler($event)"></app-reference-link-create>
              </div>
          </mat-tab>

          <mat-tab label="Tags" *ngIf="this.showOtherTab">
            <div class="h500tabMin scroller">
            <app-project-fields-configuration-create (projectFieldsConfigadded)="projectFieldsConfigaddedHandler($event)" [interfaceType]="this.interfaceType"></app-project-fields-configuration-create>
              </div>
          </mat-tab>

        </mat-tab-group>

        <div class="mt-2 mb-2" align="right">
          <button mat-stroked-button class="WIRbutton WIRbutton-primary" [disabled]="this.form.invalid" (click)='save()'>
            <mat-icon class="WIRbutton-icons">save</mat-icon>Save
          </button>
          <button mat-stroked-button type="button" class="WIRbutton WIRbutton-secondary" [appConfirmBackButton]="[this.emptyInterfaceModel,this.interfaceModel]">
            <mat-icon class="WIRbutton-icons">arrow_back</mat-icon>Back
          </button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
