import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NGXLogger } from 'ngx-logger';
import { EmailSubscription } from '../../app_data/emailSubscription';
import { User } from '../../app_data/user';
import { SystemLogService } from '../../services/systemLog.service';
import { UserService } from '../../services/user.service';
import * as myGlobals from '../../app_data/globals';
import { ActivatedRoute, Router } from '@angular/router';
import { CommunicationService } from '../../services/communication.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogService } from '../../services/dialog.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { ContactService } from 'src/app/services/contact.service';
import { Contact } from 'src/app/app_data/contact';
import { SnackbarComponent } from '../../shared/components/snackbar/snackbar.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { UserRoleProject } from '../../app_data/userroleproject';
import { ContactContractorMappingsService } from '../../services/contactContractorMappings.service';
import { MatPaginator } from '@angular/material/paginator';
import { Title } from '@angular/platform-browser';
import { ProjectService } from 'src/app/services/project.service';
import { RolePermissionService } from 'src/app/services/rolepermission.service';
import { CommonFunction } from 'src/app/app_data/commonFunction';

let RoleLists: UserRoleProject[] = [];

@Component({
  selector: 'app-userprofile',
  templateUrl: './userprofile.component.html',
  styleUrls: ['./userprofile.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class UserprofileComponent implements OnInit {
  form!: FormGroup;
  user!: User;
  contact!: Contact;
  emailSubscriptionUsers: EmailSubscription[] = [];
  errorMessage: string = "";
  isProjectAdmin: boolean = false;
  displayedColumns: string[] = ['Role.Name', 'Contractor.Name', 'Action'];
  userRoledataSource = new MatTableDataSource<UserRoleProject>(RoleLists);
  lastLoginDate!: Date;

  _projectID = parseInt(sessionStorage.getItem("projectID")!);
  _contractorID = parseInt(sessionStorage.getItem("contractorId")!);
  _userIdLogin = parseInt(sessionStorage.getItem("userId")!);
  _roleID = parseInt(sessionStorage.getItem("roleId")!);
  _projectTimeZone = sessionStorage.getItem("projectTimeZone")!;
  _dateTimeFormat = sessionStorage.getItem("projectDateTimeformat")!;
  utcoffsetvalue: string = sessionStorage.getItem("projectTimeZone")!.substring(0, sessionStorage.getItem("projectTimeZone")!.lastIndexOf(':'))!;
  commonFunction!: CommonFunction;
  @ViewChild('sortrole') sortrole !: MatSort;
  @ViewChild('paginatorrole') paginatorrole !: MatPaginator;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sortrole = ms;
    this.userRoledataSource.sort = this.sortrole;
    this.userRoledataSource.paginator = this.paginatorrole;
  }

  constructor(private userService: UserService, private logger: NGXLogger,
    private systemLogService: SystemLogService, private router: Router,
    private SpinnerService: NgxSpinnerService,
    private activatedRoute: ActivatedRoute,  private title: Title,
    private projectService: ProjectService,private rolePermissionService: RolePermissionService,
    private communicationService: CommunicationService, private dialog: DialogService,
    private snackBar: MatSnackBar, private route: ActivatedRoute,
    private contactContractorService: ContactContractorMappingsService,
    private emailSubscribeService: SubscriptionService,
    private contactService: ContactService) {
    this.user = new User();
    this.contact = new Contact();
    this.commonFunction = new CommonFunction(router, projectService, rolePermissionService, activatedRoute, title);
  }

  ngOnInit(): void {
    this.commonFunction.setPageTitle();
    this.form = new FormGroup({
      FirstName: new FormControl('', [Validators.required]),
      LastName: new FormControl('', [Validators.required]),
      Email: new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$")]),
      Phone: new FormControl('', [Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
      City: new FormControl(''),
      State: new FormControl(''),
      Country: new FormControl(''),
      IsDeleted: new FormControl(false)
    });
    this.form.controls['Email'].disable();
    this.user.Id = this.route.snapshot.params['id'];
    if (this.user.Id > 0) {
      this.getUserById(this.user.Id, this._projectID)

      if (this.user.Id == this._userIdLogin) {
        this.form.enable();
        this.form.controls['Email'].disable();
      } else {
        this.form.disable();
      }

    } else {
      this.getUserById(this._userIdLogin, this._projectID)
    }
  }

  getContactByUser(userEmail: string, contractorId: number) {
    try {
      this.SpinnerService.show();
      this.contactService.getContactByUser(userEmail, contractorId).subscribe({
        next: Data => {
          this.contact = Data[0];
          this.communicationService.setEmailSubscriptiondata(this.contact);
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  getUserById(Id: number, projectid: number) {
    try {
      this.SpinnerService.show();
      this.userService.getUserAndRoles(Id, projectid).subscribe({
        next: Data => {
          this.user = Data[0];
          if (this.user.Id == this._userIdLogin && this._roleID == Number(myGlobals.ProjectAdminRoleId)) {
            this.isProjectAdmin = true;
          } else {
            this.isProjectAdmin = false;
          }

          if (this.user.UserRoleProjects.length > 0) {
            this.userRoledataSource.data = this.user.UserRoleProjects;
            var filterdata = this.user.UserRoleProjects.filter(x => x.IsDefault);
            this.lastLoginDate = this.user.LastActivityDate!;
          } else {
            this.router.navigateByUrl('/Users');
          }
          this.getContactByUser(this.user.Email, this._contractorID);
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  subscriptionaddedHandler(emailSubscription: EmailSubscription[] = []) {
    this.emailSubscriptionUsers = emailSubscription;
  }

  save() {
    try {
      this.SpinnerService.show();
      if (this.user.Id > 0) {
        this.emailSubscriptionUsers = this.emailSubscriptionUsers.filter(x => (x.Id != 0 || !x.IsDeleted));

        this.emailSubscriptionUsers.forEach((row) => {
          if (row.Id == 0) {
            row.Discipline = null;
            row.InterfaceGroup = null;
            row.Contractor = null;
            row.Contact = null;
            row.Stage = null!;
            row.Module = null!;
            row.ContactId = this.contact.Id;
            this.emailSubscribeService.post(row).subscribe({
              next: users => {
                this.SpinnerService.hide();
                this.redirectToDashboardPage();
              },
              error: err => {
                this.SpinnerService.hide();
                this.errorMessage = err
                this.dialog.confirmDialog({
                  title: 'Error',
                  message: myGlobals.exceptionmessage,
                  cancelCaption: 'Close',
                });
                this.logger.error(err);
              }
            });
          } else {
            row.ContactId = this.contact.Id;
            this.emailSubscribeService.put(row).subscribe({
              next: users => {
                this.SpinnerService.hide();
                this.redirectToDashboardPage();
              },
              error: err => {
                this.SpinnerService.hide();
                this.errorMessage = err
                this.dialog.confirmDialog({
                  title: 'Error',
                  message: myGlobals.exceptionmessage,
                  cancelCaption: 'Close',
                });
                this.logger.error(err);
              }
            });
          }

          //Write a log
          this.systemLogService.writeLog(this._projectID, this._userIdLogin, "Edit Subscription", "Email Subscription", row.InterfaceGroupId + " - " + row.ModuleId + " - " + row.StageId + " - " + row.DisciplineId, this._contractorID, this._roleID);
        });
        this.emailSubscriptionUsers = [];
        //update interface
        this.userService.put(this.user).subscribe(
          {
            next: userDataUpdated => {
              this.contactService.getContactByEmail(this.user.Email).subscribe({
                next: contacts => {
                  if (contacts.length > 0) {
                    this.contact = contacts[0];
                    if (this.contact.ContactContractorMappings.length > 1) {
                      let userRoleMapp = this.user.UserRoleProjects.filter(x => x.IsDeleted);
                      let contactmapp = this.contact.ContactContractorMappings.filter(x => x.ContractorId == userRoleMapp[0].ContractorId);
                      contactmapp[0].IsDeleted = true;
                      contactmapp[0].ModifiedBy = this._userIdLogin;
                      this.contactContractorService.put(contactmapp[0]).subscribe({
                        next: allcontact => {
                          this.SpinnerService.hide();
                          this.snackBar.openFromComponent(SnackbarComponent, {
                            data: "User profile updated successfully!",
                            duration: myGlobals.snackBarDuration,
                            verticalPosition: myGlobals.snackBarVerticalPosition,
                            horizontalPosition: myGlobals.snackBarHorizontalPosition
                          });
                          this.getUserById(this.user.Id, this._projectID);
                        }
                      });
                    } else {
                      this.contact.IsDeleted = true;
                      this.contact.ModifiedBy = this._userIdLogin;
                      this.contact.ContactContractorMappings[0].IsDeleted = true;
                      this.contact.ContactContractorMappings[0].ModifiedBy = this._userIdLogin;
                      this.contactService.put(this.contact).subscribe({
                        next: allcontact => {
                          this.SpinnerService.hide();
                          this.snackBar.openFromComponent(SnackbarComponent, {
                            data: "User profile updated successfully!",
                            duration: myGlobals.snackBarDuration,
                            verticalPosition: myGlobals.snackBarVerticalPosition,
                            horizontalPosition: myGlobals.snackBarHorizontalPosition
                          });
                          this.getUserById(this.user.Id, this._projectID);
                        }
                      })
                    }
                  }
                }
              });
              this.SpinnerService.hide();

              if (this.user.IsDeleted) {
                this.redirectToDashboardPage();
              }
            },
            error: err => {
              this.SpinnerService.hide();
              this.errorMessage = err
              this.dialog.confirmDialog({
                title: 'Error',
                message: myGlobals.exceptionmessage,
                cancelCaption: 'Close',
              });
              this.logger.error(err);
            }
          });
      }
      this.SpinnerService.hide();
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  redirectToDashboardPage() {
    if (this.user.Id == this._userIdLogin) {
      this.getUserById(this._userIdLogin, this._projectID)
      this.router.navigateByUrl('/UserProfile');
    } else {
      this.router.navigateByUrl('/Users');
    }
    this.snackBar.openFromComponent(SnackbarComponent, {
      data: "Profile saved successfully!",
      duration: myGlobals.snackBarDuration,
      verticalPosition: myGlobals.snackBarVerticalPosition,
      horizontalPosition: myGlobals.snackBarHorizontalPosition
    });
  }

  confirmCancelDialog(userRoleMappingId: number, userId: number) {
    try {
      this.dialog
        .confirmDialog({
          title: 'Confirm Action',
          message: 'Do you want to remove this role?',
          confirmCaption: 'Confirm',
          cancelCaption: 'Cancel',
        })
        .subscribe((confirmed) => {
          if (confirmed) {
            this.delete(userRoleMappingId, userId);
          }
        });
    }
    catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  delete(userRoleMappingId: number, userId: number) {
    try {
      this.SpinnerService.show();
      this.userService.getUserAndRolesFromAllProject(userId).subscribe({
        next: users => {
          this.user = users[0];
          let userRoleMappingsData = this.user.UserRoleProjects.filter(a => a.Id == userRoleMappingId && a.ProjectId == this._projectID);
          let userRoleMappingsData2 = this.user.UserRoleProjects.filter(a => a.Id != userRoleMappingId && a.ProjectId == this._projectID);

          userRoleMappingsData[0].IsDefault = false;
          userRoleMappingsData[0].IsDeleted = true;

          if (userRoleMappingsData2.length > 0) {
            userRoleMappingsData2[0].IsDefault = true;
          }

          var isUserRoleExists = this.user.UserRoleProjects.filter(x => !x.IsDeleted);

          if (isUserRoleExists.length == 0) {
            this.user.IsDeleted = true;
          }
          this.userRoledataSource.data.filter(a => a.Id == userRoleMappingId)[0].IsDeleted = true;
          this.userRoledataSource.data = this.userRoledataSource.data.filter(x => !x.IsDeleted);
          this.SpinnerService.hide();


        }
      })

    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

}
