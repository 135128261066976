import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NGXLogger } from 'ngx-logger';
import { NgxSpinnerService } from 'ngx-spinner';
import { Role } from '../../app_data/role';
import { DialogService } from '../../services/dialog.service';
import { RoleService } from '../../services/role.service';
import * as myGlobals from '../../app_data/globals';
import { SnackbarComponent } from '../../shared/components/snackbar/snackbar.component';
import { CommonFunction } from 'src/app/app_data/commonFunction';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectService } from 'src/app/services/project.service';
import { Title } from '@angular/platform-browser';
import { RolePermissionService } from 'src/app/services/rolepermission.service';

@Component({
  selector: 'app-role-create',
  templateUrl: './role-create.component.html',
  styleUrls: ['./role-create.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class RoleCreateComponent implements OnInit {
  form !: FormGroup;
  role !: Role;
  isCompanyText: string = myGlobals.IsCompanytooltip;
  commonFunction!: CommonFunction;
  constructor(private roleService: RoleService,private router: Router, private SpinnerService: NgxSpinnerService,
    private activatedRoute: ActivatedRoute,  private title: Title,
    private projectService: ProjectService,private rolePermissionService: RolePermissionService,
     private snackBar: MatSnackBar,
    private logger: NGXLogger, private dialog: DialogService) {
    this.role = new Role();
    this.commonFunction = new CommonFunction(router, projectService, rolePermissionService, activatedRoute, title);
  }

  ngOnInit(): void {
    this.commonFunction.setPageTitle();
    this.form = new FormGroup({
      roleNameFormControl: new FormControl([Validators.required]),
      isCompanyFormControl: new FormControl(false),
    });
  }

  save() {
    try {
      if (this.form.valid) {
        this.SpinnerService.show();
        this.roleService.post(this.role).subscribe({
          next: rolesData => {
            this.SpinnerService.hide();
            this.form.reset();
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: "Role saved successfully!",
              duration: myGlobals.snackBarDuration,
              verticalPosition: myGlobals.snackBarVerticalPosition,
              horizontalPosition: myGlobals.snackBarHorizontalPosition
            });
          }
        })
      }
      this.SpinnerService.hide();
    }
    catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }
}
