import { ActivatedRoute, Router } from "@angular/router";
import { ProjectService } from "../services/project.service";
import { RolePermissionService } from "../services/rolepermission.service";
import { DefinitionSetting } from "./definitionSetting";
import * as myGlobals from "./globals";
import { domainlist } from "./globals";
import * as XLSX from 'xlsx-js-style';
import { ProjectSetting } from "./projectSetting";
import { RolePermission } from "./rolepermission";
import { SafeDomainList } from "./safedomainlist"
import { User } from "./user";
import { WorkflowMapping } from "./workflowMappings";
import { DatePipe } from "@angular/common";
import { UserRoleProject } from "./userroleproject";
import { Title } from "@angular/platform-browser";

export class CommonFunction {
  nextStageWorkflowMapping: WorkflowMapping[] = [];
  definitionSetting!: DefinitionSetting;
  projectSettings: ProjectSetting[] = [];
  userRoleProject!: UserRoleProject;
  datePipe: DatePipe = new DatePipe('en-US');

  constructor(private router: Router, private projectService: ProjectService, private rolePermissionService: RolePermissionService,
    private activatedRoute: ActivatedRoute,
    private title: Title,
  ) {
    this.definitionSetting = new DefinitionSetting();
    this.userRoleProject = new UserRoleProject();
    this.definitionSetting.ProviderContractorName = myGlobals.ProviderContractorLabel;
    this.definitionSetting.RequestingContractorName = myGlobals.RequestingContractorLabel;
    this.definitionSetting.ProviderDisciplineName = myGlobals.ProviderDisciplineLabel;
    this.definitionSetting.RequestingDisciplineName = myGlobals.RequestingDisciplineLabel;
    this.definitionSetting.ExpectedResponseDateName = myGlobals.ExpectedResponseDateLabel;
    this.definitionSetting.CloseOutDateName = myGlobals.CloseOutDateLabel;
    this.definitionSetting.ResponseDateActualName = myGlobals.ResponseDateActualLabel;
    this.definitionSetting.CloseOutDateActualName = myGlobals.CloseOutDateActualLabel;
  }
  public static ValidateURLWithSafeDomain(refUrl: string, safeDomainList: SafeDomainList[]): boolean {
    let returnval: boolean = false;
    safeDomainList.forEach(
      x => {
        if (refUrl.toLowerCase().indexOf(x.DomainName.replace(domainlist[0], '').replace(domainlist[1], '').replace(domainlist[2], '').replace(domainlist[3], '')
          .replace(domainlist[4], '').replace(domainlist[5], '').replace(domainlist[6], '').toLowerCase()) > -1) {
          returnval = true;
        }
      }
    )
    return returnval;
  }


  public static getFileType(fileName: string): string {
    var fileExtension = fileName?.slice((fileName?.lastIndexOf(".") >>> 0));

    switch (fileExtension.toLowerCase()) {
      case ".jpeg":
      case ".jpg":
      case ".png":
      case ".gif":
        return "Image file";

      case ".pdf":
        return "Portable Document Format (PDF)";

      case ".txt":
        return "Plain text (TXT)";

      case ".csv":
        return "Comma Separated Values (CSV)";

      case ".xls":
      case ".xlsx":
        return "Microsoft Excel (XLS and XLSX)";

      case ".zip":
        return "Zip File";

      case ".doc":
      case ".docx":
        return "Microsoft Word (DOCX)";

      default:
        return "Unknown File Type";
    }
  }

  public static getTimeZoneDateUTC(projectTimeZone: string) {
    const now = new Date();
    //Get current date UTC
    var dt = new Date(now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds()
    );

    var timeZoneDate = CommonFunction.ConvertUTCToTimeZoneTime(dt, projectTimeZone, false);

    return timeZoneDate;
  }

  public static getNowUTC() {
    const now = new Date();
    //Get current date UTC
    var dt = new Date(now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds()
    );

    return dt;
  }

  ///This function returns UTC date time of given Local date time  
  public static ConvertLocalTimeToUTC(localDate: Date): Date {
    var targetTime = new Date(localDate);

    //get the UTC timezone offset from local time in minutes
    var timezoneDifference = targetTime.getTimezoneOffset();

    //convert the UTC offset to milliseconds, add to targetTime, and make a new Date
    var utcDate = new Date(targetTime.getTime() + timezoneDifference * 60 * 1000);

    //Return UTC time
    return utcDate;
  }

  ///This function returns Local date time of given UTC date time
  public static ConvertUTCToLocalTime(localDate: Date): Date {
    var targetTime = new Date(localDate);

    //get the UTC timezone offset from local time in minutes
    var timezoneDifference = targetTime.getTimezoneOffset();

    //convert the UTC offset to milliseconds, add to targetTime, and make a new Date
    var utcDate = new Date(targetTime.getTime() - timezoneDifference * 60 * 1000);

    //Return UTC time
    return utcDate;
  }

  ///This function returns UTC date time of given Timezone date time  
  public static ConvertTimeZoneTimeToUTC(timeZoneDate: Date, projectTimeZone: string, isDateOnly: boolean): Date {
    if (isDateOnly) {
      var utcDate = new Date(timeZoneDate);
      utcDate.setHours(0, 0, 0, 0);

      //Return UTC date
      return utcDate;
    } else {
      var targetTime = new Date(timeZoneDate);

      var projectHourTimeZoneval = Number(projectTimeZone.split(":")[0]);
      var projectMinTimeZoneval = Number(projectTimeZone.split(":")[1]);

      //get the timezone offset from local time in minutes
      var timezoneDifference = projectHourTimeZoneval * 60 + projectMinTimeZoneval;

      //convert the offset to milliseconds, add to targetTime, and make a new Date
      var utcDate = new Date(targetTime.getTime() - timezoneDifference * 60 * 1000);

      //Return UTC date time
      return utcDate;
    }
  }


  ///This function returns Timezone date time form given UTC date time
  public static ConvertUTCToTimeZoneTime(utcDate: Date, projectTimeZone: string, isDateOnly: boolean): Date {

    if (isDateOnly) {
      var timezoneDate = new Date(utcDate);
      timezoneDate.setHours(0, 0, 0, 0);

      //Return Timezone date
      return timezoneDate;
    } else {
      var targetTime = new Date(utcDate);
      var projectHourTimeZoneval = 0;
      var projectMinTimeZoneval = 0;

      if (projectTimeZone != null) {
        projectHourTimeZoneval = Number(projectTimeZone.split(":")[0]);
        projectMinTimeZoneval = Number(projectTimeZone.split(":")[1]);
      }

      //get the timezone offset from local time in minutes
      var timezoneDifference = projectHourTimeZoneval * 60 + projectMinTimeZoneval;
      //convert the offset to milliseconds, add to targetTime, and make a new Date
      var timezoneDate = new Date(targetTime.getTime() + timezoneDifference * 60 * 1000);

      //Return date as per timezone 
      return timezoneDate;
    }
  }

  public static getTimeZoneNowUTC(_projectTimeZone: string): Date {
    const now = new Date();
    var dt = new Date(now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds()
    );
    var timeZoneDate = CommonFunction.ConvertUTCToTimeZoneTime(dt, _projectTimeZone, false);
    return timeZoneDate;
  }

  public static IsApprovedIssuedPlannedOverDue(ApprovedIssuedPlannedDate: Date, currentDate: Date, status: number): boolean {
    var currDate = currentDate;
    currDate.setHours(0, 0, 0, 0);
    if (ApprovedIssuedPlannedDate != null) {
      ApprovedIssuedPlannedDate!.setHours(0, 0, 0, 0);
    }

    if (ApprovedIssuedPlannedDate < currDate && (status != 1 && status != 3 && status != 4)) {
      return true;
    }
    return false;
  }




  public static IsFirstIssuePlannedOverdue(FirstIssuePlannedDate: Date, currentDate: Date, status: number): boolean {
    var currDate = currentDate;
    currDate.setHours(0, 0, 0, 0);
    if (FirstIssuePlannedDate != null) {
      FirstIssuePlannedDate!.setHours(0, 0, 0, 0);
    }

    if (FirstIssuePlannedDate < currDate && (status == 5 || status == 6 || status == 10 || status == 11)) {
      return true;
    }
    return false;
  }


  public static IsRequestorActionPending(status: number, response: string) {

    switch (status) {
      case 1: //identified:
        return true;
      case 2: //open:
        return (response != null && response != '') ? true : false;
      case 3: //closed
        return false;
      case 4: //Awaiting for Request approval
        return true;
      case 5: //Awaiting Response
        return false;
      case 6: //Awaiting for Response approval
        return false;
      case 7: //Awaiting for Response acceptance
        return true;
      case 8: //Awaiting for close out
        return true;
      case 9://Responded
        return true;
      case 10://Response rejected
        return false;
      case 11://acceptance rejected
        return false;
    }

    return false;
  }



  public static IsProviderActionPending(status: number, response: string) {

    switch (status) {
      case 1: //identified:
        return false;
      case 2: //open:
        return (response != null && response != '') ? false : true;
      case 3: //closed
        return false;
      case 4: //Awaiting for Request approval
        return false;
      case 5: //Awaiting Response
        return true;
      case 6: //Awaiting for Response approval
        return true;
      case 7: //Awaiting for Response acceptance
        return false;
      case 8: //Awaiting for close out
        return false;
      case 9://Responded
        return false;
      case 10://Response rejected
        return true;
      case 11://Acceptance Rejected
        return true;
    }

    return false;
  }


  public static compareModels(oldModel: any, newModel: any): boolean {
    let isModelChanged = false;
    const pipe = new DatePipe('en-US');

    Object.keys(newModel).forEach(key => {
      const oldValue = oldModel[key];
      const newValue = newModel[key];

      if (key !== 'Status' && key !== 'Priority' && key !== 'Discussion' && key !== 'ApprovedIssuePlanned' && key !== 'MeetingDate' && key !== 'FirstIssuePlanned'
        && key !== 'FirstIssuePlannedstr' && key !== 'ApprovedIssuePlannedstr'
        && (newModel[key] !== oldModel[key]) && !Array.isArray(oldValue)
        || ((Array.isArray(oldValue) && Array.isArray(newValue) && newValue.length !== oldValue.length))) {
        isModelChanged = true;
      } else if (key == 'FirstIssuePlanned' || key == 'ApprovedIssuePlanned' || key == 'MeetingDate') {
        if (typeof oldValue != 'undefined' && typeof newValue != 'undefined' && pipe.transform(new Date(oldValue), 'yyyy-MM-dd') !== pipe.transform(new Date(newValue), 'yyyy-MM-dd')) {
          isModelChanged = true;
        }
      }
    });

    return isModelChanged;
  }

  public static convertBlobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }

  public redirectToRegisterpage(moduleId: number) {
    if (moduleId == myGlobals.InterfaceModule) {
      this.router.navigateByUrl('/Interfaces');
    } else if (moduleId == myGlobals.TQModule) {
      this.router.navigateByUrl('/TechnicalQueries');
    }
  }

  public addLeadingZeros(num: string, totalLength: number): string {
    return String(num).padStart(totalLength, '0');
  }

  public getStatusText(statusId: number): string {
    switch (statusId) {
      case 1: //identified:
        return myGlobals.IdentifiedStatusName;
      case 2: //open:
        return myGlobals.OpenStatusName;
      case 3: //closed
        return myGlobals.ClosedStatusName;
      case 4: //Awaiting For Request Approval
        return myGlobals.AwaitingForRequestApprovalStatusName;
      case 5: //Awaiting Response
        return myGlobals.AwaitingResponseStatusName;
      case 6: //Awaiting For Response Approval
        return myGlobals.AwaitingForResponseApprovalStatusName;
      case 7: //Awaiting For Response Acceptance
        return myGlobals.AwaitingForResponseAcceptanceStatusName;
      case 8: //Awaiting For CloseOut
        return myGlobals.AwaitingForCloseOutStatusName;
      case 9://Responded
        return myGlobals.RespondedStatusName;
      case 10://Response Rejected
        return myGlobals.ResponseRejectedStatusName;
      case 11://Acceptance Rejected
        return myGlobals.AcceptanceRejectedStatusName;
      case 12://Voided
        return myGlobals.VoidedStatusName;
      case 13://Additional Information Required
        return myGlobals.AdditionalInformationRequiredStatusName;
      case 14://Propose to Close
        return myGlobals.ProposetoCloseStatusName;
      case 15://Propose to Close Rejected
        return myGlobals.ProposetoCloseRejectedStatusName;
      default:
        return "";
    }
  }


  public getCardColorOnStatus(status: number, IsApprovedIssuePlannedOverdue: boolean, IsFirstIssuePlannedOverdue: boolean, response: string): string {

    switch (status) {
      case 1: //identified:
        return "status-identified";
      case 2: //open:
        return (IsFirstIssuePlannedOverdue && response == "") ? "status-responseoverdue" : (IsApprovedIssuePlannedOverdue && response != "") ? "status-overdue" : "status-open";
      case 3: //closed
        return "status-closed";
      case 4: //Awaiting For Request Approval
        return "status-open";
      case 5: //Awaiting Response
        return (IsFirstIssuePlannedOverdue && response == "") ? "status-responseoverdue" : (IsApprovedIssuePlannedOverdue && response != "") ? "status-overdue" : "status-open";
      case 6: //Awaiting For Response Approval
        return (IsFirstIssuePlannedOverdue && response != "") ? "status-responseoverdue" : "status-open";
      case 7: //Awaiting For Response Acceptance
        return (IsFirstIssuePlannedOverdue && response == "") ? "status-responseoverdue" : (IsApprovedIssuePlannedOverdue && response != "") ? "status-overdue" : "status-open";
      case 8: //Awaiting For CloseOut
        return (IsFirstIssuePlannedOverdue && response == "") ? "status-responseoverdue" : (IsApprovedIssuePlannedOverdue && response != "") ? "status-overdue" : "status-open";
      case 9: //Responded
        return (IsFirstIssuePlannedOverdue && response == "") ? "status-responseoverdue" : (IsApprovedIssuePlannedOverdue && response != "") ? "status-overdue" : "status-open";
      case 10: //Response Rejected 
        return (IsFirstIssuePlannedOverdue && response == "") ? "status-responseoverdue" : (IsApprovedIssuePlannedOverdue && response != "") ? "status-overdue" : "status-open";
      case 11: //Acceptance Rejected 
        return (IsFirstIssuePlannedOverdue && response == "") ? "status-responseoverdue" : (IsApprovedIssuePlannedOverdue && response != "") ? "status-overdue" : "status-open";
      case 12: //Voided
        return "status-voided";
      case 13: //Additional Information Required
        return "status-additionalinfo";
      case 14: //Propose to Close
        return "status-proposetoclose";
      default:
        return '';
    }

  }

  public getModuleName(moduleId: number): string {
    switch (moduleId) {
      case 1: //Interface:
        return myGlobals.InterfaceModuleName;
      case 2: //open:
        return myGlobals.TQModuleName;
      default:
        return "";
    }
  }

  public async setUserSession(user: User) {
    try {
      sessionStorage.setItem('userId', user.Id.toString());
      sessionStorage.setItem('userFName', user.FirstName);
      sessionStorage.setItem('userLName', user.LastName);
      sessionStorage.setItem('contractorId', user.UserRoleProjects.filter(x => x.IsDefault == true)[0].ContractorId.toString());
      sessionStorage.setItem('contractorName', user.UserRoleProjects.filter(x => x.IsDefault == true)[0].Contractor.Name.toString());
      sessionStorage.setItem('projectID', user.UserRoleProjects.filter(x => x.IsDefault == true)[0].ProjectId.toString());
      sessionStorage.setItem('roleId', user.UserRoleProjects.filter(x => x.IsDefault == true)[0].RoleId.toString());
      sessionStorage.setItem('roleName', user.UserRoleProjects.filter(x => x.IsDefault == true)[0].Role.Name!);
      sessionStorage.setItem('isCompany', user.UserRoleProjects.filter(x => x.IsDefault == true)[0].Contractor.IsCompany);
      sessionStorage.setItem('userRoleMappingId', user.UserRoleProjects.filter(x => x.IsDefault == true)[0].Id.toString());

      this.projectService.getProject(user.UserRoleProjects.filter(x => x.IsDefault == true)[0].ProjectId).subscribe({
        next: projects => {
          if (projects[0].Settings.length > 0) {
            this.projectSettings = projects[0].Settings;
            var timeformat = this.projectSettings.filter(x => x.ConfigKey == "DateTimeFormat")[0].ConfigValue;
            sessionStorage.setItem('projectDateTimeformat', timeformat.toString());
            var index = this.projectSettings.findIndex(x => x.ConfigKey == "UTCOffset");
            if (index > -1) {
              var offsetValue = this.projectSettings.filter(x => x.ConfigKey == "UTCOffset")[0].ConfigValue.split("|")[0]!;
              sessionStorage.setItem('projectTimeZone', offsetValue.toString());
            }

            //Definition settings
            var index = this.projectSettings.findIndex(x => x.ConfigKey == "ProviderContractorName");
            if (index > -1) {
              this.definitionSetting.ProviderContractorName = this.projectSettings.filter(x => x.ConfigKey == "ProviderContractorName")[0].ConfigValue;
            }

            var index = this.projectSettings.findIndex(x => x.ConfigKey == "RequestingContractorName");
            if (index > -1) {
              this.definitionSetting.RequestingContractorName = this.projectSettings.filter(x => x.ConfigKey == "RequestingContractorName")[0].ConfigValue;
            }

            var index = this.projectSettings.findIndex(x => x.ConfigKey == "ProviderDisciplineName");
            if (index > -1) {
              this.definitionSetting.ProviderDisciplineName = this.projectSettings.filter(x => x.ConfigKey == "ProviderDisciplineName")[0].ConfigValue;
            }

            var index = this.projectSettings.findIndex(x => x.ConfigKey == "RequestingDisciplineName");
            if (index > -1) {
              this.definitionSetting.RequestingDisciplineName = this.projectSettings.filter(x => x.ConfigKey == "RequestingDisciplineName")[0].ConfigValue;
            }

            var index = this.projectSettings.findIndex(x => x.ConfigKey == "ExpectedResponseDateName");
            if (index > -1) {
              this.definitionSetting.ExpectedResponseDateName = this.projectSettings.filter(x => x.ConfigKey == "ExpectedResponseDateName")[0].ConfigValue;
            }

            var index = this.projectSettings.findIndex(x => x.ConfigKey == "CloseOutDateName");
            if (index > -1) {
              this.definitionSetting.CloseOutDateName = this.projectSettings.filter(x => x.ConfigKey == "CloseOutDateName")[0].ConfigValue;
            }

            var index = this.projectSettings.findIndex(x => x.ConfigKey == "ResponseDateActualName");
            if (index > -1) {
              this.definitionSetting.ResponseDateActualName = this.projectSettings.filter(x => x.ConfigKey == "ResponseDateActualName")[0].ConfigValue;
            }

            var index = this.projectSettings.findIndex(x => x.ConfigKey == "CloseOutDateActualName");
            if (index > -1) {
              this.definitionSetting.CloseOutDateActualName = this.projectSettings.filter(x => x.ConfigKey == "CloseOutDateActualName")[0].ConfigValue;
            }

            var definitionSettingsJSON = JSON.stringify(this.definitionSetting);
            sessionStorage.setItem('definitionSettings', definitionSettingsJSON);
          }

          var activeModules = '';
          if (projects[0].ModuleProjectMappings.filter(x => !x.IsDeleted).length > 0) {
            projects[0].ModuleProjectMappings.filter(x => !x.IsDeleted).forEach(x => {
              activeModules += x.ModuleId + ',';
            })

            if (activeModules.length > 0)
              activeModules = activeModules.slice(0, -1);

            sessionStorage.setItem('activeModules', activeModules.toString());
          }
        }
      });

      let data = await this.rolePermissionService.getPermissionByRoleWithPromise(user.UserRoleProjects.filter(x => x.IsDefault == true)[0].RoleId);
      var permissions = data.value;

      var rolePermissions = '';
      permissions.forEach((permission: RolePermission) => {
        rolePermissions += permission.Permission!.Name + ',';
      })

      if (rolePermissions.length > 0)
        rolePermissions = rolePermissions.slice(0, -1);

      sessionStorage.setItem('rolePermissions', rolePermissions);
    } catch (e) {

    }
  }

  public exportToExcel(model: any, heading: any, projectName: string, fileName: string) {
    const worksheetData: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
    let _projectTimeZone = sessionStorage.getItem('projectTimeZone')!;

    XLSX.utils.sheet_add_aoa(worksheetData, [['Project Name :' + projectName]], { origin: 0 })
    XLSX.utils.sheet_add_aoa(worksheetData,
      [['Export Created :' + this.datePipe.transform(CommonFunction.getTimeZoneNowUTC(_projectTimeZone), 'dd/MM/yyyy hh:mm:ss')]],
      { origin: 1 }
    );

    XLSX.utils.sheet_add_json(worksheetData, model, { origin: 3 });
    XLSX.utils.sheet_add_aoa(worksheetData, heading, { origin: 3 });

    //Generate workbook and add the worksheet
    const workbook: XLSX.WorkBook = XLSX.utils.book_new();

    // Make first row bold
    for (let i = 0; i < heading[0].length; i++) {
      const cell = worksheetData[XLSX.utils.encode_cell({ r: 3, c: i })];
      // Create new style if cell doesnt have a style yet
      if (!cell.s) { cell.s = {}; }
      if (!cell.s.font) { cell.s.font = {}; }
      // Set bold
      cell.s.font.bold = true;
    }

    XLSX.utils.book_append_sheet(workbook, worksheetData, fileName);

    //Save to file
    XLSX.writeFile(workbook, fileName + '.xlsx');
  }

  public setPageTitle(): void {
    const defaultPageTitle = 'Web Interface Register';
    if (this.activatedRoute.snapshot.data != null) {
      this.title.setTitle(this.activatedRoute.snapshot.data.title);
    } else {
      this.title.setTitle(defaultPageTitle);
    }
  }
}
