
export const environment = {
  environrmentMode: "test",
  appVersion: require('../../package.json').version + '-test',
  production: true,
  scopes: ["https://adtestwir.onmicrosoft.com/28201e71-30c6-42f5-933b-5e76eb66dd22/Read"],
  apiURl: 'https://test.webinterfaceregister.com/API1/',
  apiDomainURL: 'https://testapi.webinterfaceregister.com/Notify',
  reportAPIURL: 'https://test.webinterfaceregister.com/API2/',
  storageAPIURL: 'https://test.webinterfaceregister.com/API3/',
  imageURl: "/assets/images/",
  iconURl: "/assets/icons/",
  documentURl: "/assets/",
  storageAccessKey: "Sp9tRzhh6mfRQ2wJaUAyuKBoF/ywW2NIBcXiJAZ8wHfmIFyRDmSd0gpbXFLUycYL1iLmF/bmN2YN+AStkwfPqA==",
  storageContainerName: "testcont",
  storageAccountName:"wirv2storage",
  baseURL: "https://test.webinterfaceregister.com/#/",
  //#region  Authenication Azure B2C config
  b2cPolicies : {
    names: {
        signUpSignIn: "B2C_1_signup_signin_wir",
        editProfile: "B2C_1_wirprofile_edit",
        paaswordReset:"B2C_1_wir_reset_password"
    },
    authorities: {
        signUpSignIn: {
            authority: "https://adtestwir.b2clogin.com/adtestwir.onmicrosoft.com/B2C_1_signup_signin_wir",
        },
        editProfile: {
            authority: "https://adtestwir.b2clogin.com/adtesstwir.onmicrosoft.com/B2C_1_wirprofile_edit"
        },
        passwordReset:{
            authority: "https://adtestwir.b2clogin.com/adtesstwir.onmicrosoft.com/B2C_1_wir_reset_password"
        }
        
    },
    authorityDomain: "adtestwir.b2clogin.com"
},
b2cClientId: '699c106d-6cde-4696-b6a7-98586931716d',
  redirectUri: 'https://test.webinterfaceregister.com/',
  postLogoutRedirectUri: 'https://test.webinterfaceregister.com/',
//#endregion  
};
