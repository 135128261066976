<div class="container-fluid">
  <mat-card class="">
    <mat-card-content>
      <p class="pPageHeading">{{pageTitle}}</p>
      <form [formGroup]="form" [appMatchDate]="['ifiDateFormControl','iaiDateFormControl']" class="interfaceTab">
        <mat-tab-group animationDuration="0ms">

          <mat-tab label="Technical Query">
            <div class="h500tabMin scroller">
              <div class="row">
                <div class="col-8">
                  <div class="row">
                    <div class="col-xl-4 col-l-4 col-md-4 col-sm-12 col-xs-12">
                      <mat-form-field appearance="standard">
                        <mat-label>Number</mat-label>
                        <input matInput autocomplete="off" #input maxlength="256" name="Number" [(ngModel)]="interfaceModel.Number" readonly
                               formControlName="numberFormControl" appTrimWhitespace>
                      </mat-form-field>
                    </div>
                    <div class="col-xl-8 col-l-8 col-md-8 col-sm-12 col-xs-12">
                      <mat-form-field appearance="standard">
                        <mat-label>Title</mat-label>
                        <input matInput autocomplete="off" #input maxlength="256" placeholder="Title" name="Title"
                               [(ngModel)]="interfaceModel.Title" formControlName="titleFormControl" required>
                        <mat-error *ngIf="this.form.get('titleFormControl')?.hasError('required')">
                          Title is <strong>required</strong>
                        </mat-error>
                        <mat-hint align="end">{{interfaceModel.Title?.length || 0}}/256</mat-hint>
                      </mat-form-field>
                    </div>

                  </div>
                  <div class="row">
                    <div class="col-xl-6 col-l-6 col-md-6 col-sm-12 col-xs-12">
                      <mat-form-field appearance="standard">
                        <mat-label>Interface Group</mat-label>
                        <mat-select [(value)]="selectedInterfaceGroupId" [(ngModel)]="interfaceModel.InterfaceGroupID"
                                    (selectionChange)="onInterfaceGroupSelected(selectedInterfaceGroupId)"
                                    formControlName="interfaceGroupFormControl" [disabled]="this.isDisabledInterfaceGroup" required>
                          <mat-option *ngFor="let ig of interfaceGroups" [value]="ig.Id">{{ ig.Name }}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="this.form.get('interfaceGroupFormControl')?.hasError('required')">
                          Interface Group is <strong>required</strong>
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div class="col-xl-6 col-l-6 col-md-6 col-sm-12 col-xs-12" *ngIf="this.isStatusDropdownvisible">
                      <mat-form-field appearance="standard">
                        <mat-label>Status</mat-label>
                        <mat-select [(value)]="selectedStatusId" name="Status" [(ngModel)]="interfaceModel.Status"
                                    formControlName="statusFormControl" required>
                          <mat-option *ngFor="let st of statuses" [value]="st.Id">{{ st.Name }}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="this.form.get('statusFormControl')?.hasError('required')">
                          Status is <strong>required</strong>
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div class="col-xl-6 col-l-6 col-md-6 col-sm-12 col-xs-12" *ngIf="!this.isStatusDropdownvisible">
                      <label class="statusHeaderLabel margin-bottom-0">Status</label><br />
                      <span class="status status-{{statusShortName | lowercase}} mb-0"></span><label class="toolbarFontSize">{{statusText}}</label>
                    </div>

                  </div>
                  <!--<div class="row">
                    <div class="col-xl-6 col-l-6 col-md-6 col-sm-12 col-xs-12">
                      <mat-form-field appearance="standard">
                        <mat-label>Request Approver</mat-label>
                        <mat-select name="RequestApproverId" [(ngModel)]="interfaceModel.RequestApproverId" [disabled]="!this.isDisciplineLead"
                                    formControlName="requestApproverFormControl" [required]="this.isDisciplineLead">
                          <mat-option *ngFor="let ap of approvers" [value]="ap.Id">{{ ap.FirstName + ' '+ap.LastName }}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="this.form.get('requestApproverFormControl')?.hasError('required')">
                          Request Approver is <strong>required</strong>
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>-->
                  <div class="row">
                    <div class="col-xl-6 col-l-6 col-md-6 col-sm-12 col-xs-12">
                      <mat-form-field appearance="standard">
                        <mat-label>{{requestingContractorLabel}}</mat-label>
                        <mat-select [(value)]="selectedRequestingContractorId"
                                    (selectionChange)="onRequestingContractorSelected(selectedRequestingContractorId)"
                                    name="ReceivingContractorID" [(ngModel)]="interfaceModel.ReceivingContractorID"
                                    formControlName="requestingContractorFormControl" [disabled]="true" required>
                          <mat-option *ngFor="let reqCon of requestingContractors" [value]="reqCon.Id">
                            {{ reqCon.Name }}
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="this.form.get('requestingContractorFormControl')?.hasError('required')">
                          {{requestingContractorLabel}} is <strong>required</strong>
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-xl-6 col-l-6 col-md-6 col-sm-12 col-xs-12">
                      <mat-form-field appearance="standard">
                        <mat-label>{{providerContractorLabel}}</mat-label>
                        <mat-select [(value)]="selectedProviderContractorId"
                                    (selectionChange)="onProviderContractorSelected(selectedProviderContractorId)"
                                    name="ResponsibleContractorID" [(ngModel)]="interfaceModel.ResponsibleContractorID"
                                    formControlName="providerContractorFormControl" [disabled]="true" required>
                          <mat-option *ngFor="let prCon of providerContractors" [value]="prCon.Id">{{ prCon.Name }}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="this.form.get('providerContractorFormControl')?.hasError('required')">
                          {{providerContractorLabel}} is <strong>required</strong>
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-6 col-l-6 col-md-6 col-sm-12 col-xs-12">
                      <mat-form-field appearance="standard">
                        <mat-label>{{this.isDualDisciplinebool ? requestingDisciplineLabel : "Discipline"}}</mat-label>
                        <mat-select [(value)]="selectedRequestingDisciplineId" name="ReceivingContractorDisciplineID"
                                    [(ngModel)]="interfaceModel.ReceivingContractorDisciplineID"
                                    formControlName="requestingDisciplineFormControl" required>
                          <mat-option *ngFor="let disc of requestingDisciplines" [value]="disc.Id">{{ disc.Name }}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="this.form.get('requestingDisciplineFormControl')?.hasError('required')">
                          {{this.isDualDisciplinebool ? requestingDisciplineLabel : "Discipline"}} is <strong>required</strong>
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-xl-6 col-l-6 col-md-6 col-sm-12 col-xs-12" *ngIf="this.isDualDisciplinebool">
                      <mat-form-field appearance="standard">
                        <mat-label>{{providerDisciplineLabel}}</mat-label>
                        <mat-select [(value)]="selectedProviderDisciplineId" name="ResponsibleContractorDisciplineID"
                                    [(ngModel)]="interfaceModel.ResponsibleContractorDisciplineID"
                                    formControlName="providerDisciplineFormControl" required>
                          <mat-option *ngFor="let disc of disciplines" [value]="disc.Id">{{ disc.Name }}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="this.form.get('providerDisciplineFormControl')?.hasError('required')">
                          {{providerDisciplineLabel}} is <strong>required</strong>
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-xl-4 col-l-4 col-md-4 col-sm-12 col-xs-12">
                      <mat-form-field appearance="standard">
                        <mat-label>Is Confidential?</mat-label>
                        <mat-select name="IsConfidential" [(ngModel)]="technicalQuery.IsConfidential" (selectionChange)="onConfidentialitySelected(technicalQuery.IsConfidential!)"
                                    formControlName="isConfidentialFormControl" required>
                          <mat-option *ngFor="let option of confidentialityOptions" [value]="(option==1)?true:false">{{ IsConfidential2LabelMapping[option] }}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="this.form.get('isConfidentialFormControl')?.hasError('required')">
                          Is Confidential is <strong>required</strong>
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-xl-8 col-l-8 col-md-8 col-sm-12 col-xs-12">
                      <mat-form-field appearance="standard" *ngIf="technicalQuery.IsConfidential">
                        <mat-label>Confidential Justification</mat-label>
                        <input matInput #input autocomplete="off" maxlength="1000" placeholder="Confidential Justification" name="ConfidentialJustification"
                               formControlName="confidentialJustificationFormControl" [(ngModel)]="technicalQuery.ConfidentialJustification" [required]="technicalQuery.IsConfidential" appTrimWhitespace>
                        <mat-error *ngIf="this.form.get('confidentialJustificationFormControl')?.hasError('required')">
                          Confidential Justification is <strong>required</strong>
                        </mat-error>
                        <mat-hint align="end">{{technicalQuery.ConfidentialJustification?.length || 0}}/1000</mat-hint>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-4 col-l-4 col-md-4 col-sm-12 col-xs-12">
                      <mat-form-field appearance="standard">
                        <mat-label>Priority</mat-label>
                        <mat-select [(ngModel)]="interfaceModel.Priority" name="Priority" (selectionChange)="onPrioritySelected(selectedPriorityId!, true)"
                                    formControlName="PriorityFormControl" [(value)]="selectedPriorityId" required>
                          <mat-option *ngFor="let pr of priorities" [value]="pr.Id">{{ pr.Name }}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="this.form.get('priorityFormControl')?.hasError('required')">
                          Priority is <strong>required</strong>
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-xl-8 col-l-8 col-md-8 col-sm-12 col-xs-12">
                      <mat-form-field appearance="standard" *ngIf="showJustification">
                        <mat-label>Priority Justification</mat-label>
                        <input matInput #input autocomplete="off" maxlength="1000" placeholder="Priority Justification" name="PriorityJustification"
                               formControlName="priorityJustificationFormControl" [(ngModel)]="technicalQuery.PriorityJustification" [required]="this.showJustification" appTrimWhitespace>
                        <mat-error *ngIf="this.form.get('priorityJustificationFormControl')?.hasError('required')">
                          Priority Justification is <strong>required</strong>
                        </mat-error>
                        <mat-hint align="end">{{technicalQuery.PriorityJustification?.length || 0}}/1000</mat-hint>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-4 col-l-4 col-md-8 col-sm-12 col-xs-12">
                      <mat-form-field appearance="standard">
                        <mat-label>Query Type</mat-label>
                        <mat-select name="QueryType" [(ngModel)]="technicalQuery.QueryTypeId"
                                    formControlName="queryTypeFormControl" required>
                          <mat-option *ngFor="let qt of queryTypes" [value]="qt.Id">{{ qt.Name }}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="this.form.get('queryTypeFormControl')?.hasError('required')">
                          Query Type is <strong>required</strong>
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-xl-4 col-l-4 col-md-8 col-sm-12 col-xs-12">
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-xl-6 col-l-6 col-md-6 col-sm-12 col-xs-12 display-flex">
                      <mat-form-field appearance="standard" class="datePicker width-100">
                        <mat-label>{{expectedResponseDateLabel}}</mat-label>
                        <input matInput [matDatepicker]="picker" [min]="minDate" formControlName="ifiDateFormControl" [(ngModel)]="interfaceModel.FirstIssuePlanned"
                               (dateChange)="onFirstResDateChange('change', $event)" readonly>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker disabled="{{this._isProvider  || this.isPlannedDateDisabled}}"></mat-datepicker>
                        <mat-error *ngIf="this.form.get('ifiDateFormControl')?.hasError('required')">
                          {{expectedResponseDateLabel}} is <strong>required</strong>
                        </mat-error>
                        <mat-error *ngIf="this.form.get('ifiDateFormControl')?.hasError('dateMismatch')">
                          {{expectedResponseDateLabel}} should not be greater than {{closeOutDateLabel}}.
                        </mat-error>
                        <mat-error *ngIf="this.form.get('ifiDateFormControl')?.hasError('minDateNotAllowed')">
                          Please select date greater than or equal to today
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div class="col-xl-6 col-l-6 col-md-6 col-sm-12 col-xs-12 display-flex">
                      <mat-form-field appearance="standard" class="datePicker width-100">
                        <mat-label>{{closeOutDateLabel}}</mat-label>
                        <input matInput [matDatepicker]="topicker" [min]="minCloseOutDate" [(ngModel)]="interfaceModel.ApprovedIssuePlanned" formControlName="iaiDateFormControl"
                               (dateChange)="onApprovedResDateChange('change', $event)" readonly>
                        <mat-datepicker-toggle matSuffix [for]="topicker"></mat-datepicker-toggle>
                        <mat-datepicker #topicker disabled="{{this._isProvider  || this.isPlannedDateDisabled}}"></mat-datepicker>
                        <mat-error *ngIf="this.form.get('iaiDateFormControl')?.hasError('required')">
                          {{closeOutDateLabel}} is <strong>required</strong>
                        </mat-error>
                        <mat-error *ngIf="this.form.get('iaiDateFormControl')?.hasError('dateMismatch')">
                          {{closeOutDateLabel}} should be greater than {{expectedResponseDateLabel}}.
                        </mat-error>
                        <mat-error *ngIf="this.form.get('iaiDateFormControl')?.hasError('minDateNotAllowed')">
                          Please select date greater than or equal to today
                        </mat-error>
                      </mat-form-field>

                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <div [hidden]="this.isRequestApprovalDialogHidden" class="col-xl-12 col-l-12 col-md-12 col-sm-12 col-xs-12">
                    <app-approve-rejectdialog [data]="this.interfaceModel" [hasPermission]="'wf.awaitingforrequestapproval'" [interfaceId]="this.interfaceId"></app-approve-rejectdialog>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-6 col-l-6 col-md-6 col-sm-12 col-xs-12">
                  <mat-form-field appearance="standard">
                    <mat-label>Request Description</mat-label>
                    <textarea matInput formControlName="descriptionFormControl" maxlength="3000" name="Description"
                              [(ngModel)]="interfaceModel.Description" class="heightTextArea" required appTrimWhitespace></textarea>
                    <mat-error *ngIf="this.form.get('descriptionFormControl')?.hasError('required')">
                      Description is <strong>required</strong>
                    </mat-error>
                    <mat-hint align="end">{{interfaceModel.Description?.length || 0}}/3000</mat-hint>
                  </mat-form-field>
                </div>

                <div class="col-xl-6 col-l-6 col-md-6 col-sm-12 col-xs-12">
                  <mat-form-field appearance="standard">
                    <mat-label>Additional Comments</mat-label>
                    <textarea matInput class="heightTextArea" maxlength="1500" formControlName="comments" name="Comments"
                              [(ngModel)]="interfaceModel.Comments" appTrimWhitespace></textarea>
                    <mat-hint align="end">{{interfaceModel.Comments?.length || 0}}/1500</mat-hint>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </mat-tab>

          <mat-tab label="{{proposeDateLabel}}" *ngIf="this.isProposeDateTabVisible || (isResponseGiven && !this._isProvider && this.isProposeDateTabVisible)">
            <div class="h500tabMin scroller">
              <div class="row">
                <div class="col-xl-4 col-l-4 col-md-4 col-sm-12 col-xs-12 display-flex">
                  <mat-form-field appearance="standard" class="datePicker width-100">
                    <mat-label>{{expectedResponseDateLabel}}</mat-label>
                    <input matInput [matDatepicker]="picker" [min]="minDate" name="ifiDate2" formControlName="ifiDate2FormControl" [(ngModel)]="interfaceModel.FirstIssuePlanned" readonly>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker disabled></mat-datepicker>
                  </mat-form-field>
                </div>

                <div class="col-xl-4 col-l-4 col-md-4 col-sm-12 col-xs-12 display-flex">
                  <mat-form-field appearance="standard" class="datePicker width-100">
                    <mat-label>{{closeOutDateLabel}}</mat-label>
                    <input matInput [matDatepicker]="topicker" [min]="minDate" name="iaiDate2" formControlName="iaiDate2FormControl" [(ngModel)]="interfaceModel.ApprovedIssuePlanned" readonly>
                    <mat-datepicker-toggle matSuffix [for]="topicker"></mat-datepicker-toggle>
                    <mat-datepicker #topicker disabled></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
              <form [formGroup]="proposeDateForm" [appMatchDate]="['ifiDateProposeFormControl','iaiDateProposeFormControl']">
                <div class="row">
                  <div class="col-xl-4 col-l-4 col-md-4 col-sm-12 col-xs-12 display-flex">
                    <mat-form-field appearance="standard" class="width-100">
                      <mat-label>Proposed {{expectedResponseDateLabel}}</mat-label>
                      <input matInput [matDatepicker]="proposeResponsePicker" [min]="minDate" name="ifiDatePropose" formControlName="ifiDateProposeFormControl" [(ngModel)]="interfaceModel.ProviderFirstIssuePlanned" readonly required>
                      <mat-datepicker-toggle matSuffix [for]="proposeResponsePicker"></mat-datepicker-toggle>
                      <mat-datepicker #proposeResponsePicker disabled="false"></mat-datepicker>
                      <mat-error *ngIf="this.proposeDateForm.get('ifiDateProposeFormControl')?.hasError('required')">
                        Proposed {{expectedResponseDateLabel}} is <strong>required</strong>
                      </mat-error>
                      <mat-error *ngIf="this.proposeDateForm.get('ifiDateProposeFormControl')?.hasError('dateMismatch')">
                        Proposed {{expectedResponseDateLabel}} should not be greater than {{closeOutDateLabel}}.
                      </mat-error>
                      <mat-error *ngIf="this.proposeDateForm.get('ifiDateProposeFormControl')?.hasError('minDateNotAllowed')">
                        Please select date greater than or equal to today
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div class="col-xl-4 col-l-4 col-md-4 col-sm-12 col-xs-12 display-flex">
                    <mat-form-field appearance="standard" class="width-100">
                      <mat-label>Proposed {{closeOutDateLabel}}</mat-label>
                      <input matInput [matDatepicker]="proposeCloseoutPicker" autocomplete="off" name="iaiDatePropose"
                             formControlName="iaiDateProposeFormControl" [(ngModel)]="interfaceModel.ProviderApprovedIssuePlanned" readonly required/>
                      <mat-datepicker-toggle matSuffix [for]="proposeCloseoutPicker"></mat-datepicker-toggle>
                      <mat-datepicker #proposeCloseoutPicker disabled="false"></mat-datepicker>
                      <mat-error *ngIf="this.proposeDateForm.get('iaiDateProposeFormControl')?.hasError('required')">
                        Proposed {{closeOutDateLabel}} is <strong>required</strong>
                      </mat-error>
                      <mat-error *ngIf="this.proposeDateForm.get('iaiDateProposeFormControl')?.hasError('dateMismatch')">
                        Proposed {{closeOutDateLabel}} should be greater than Proposed {{expectedResponseDateLabel}}.
                      </mat-error>
                      <mat-error *ngIf="this.proposeDateForm.get('iaiDateProposeFormControl')?.hasError('minDateNotAllowed')">
                        Please select date greater than or equal to today
                      </mat-error>
                    </mat-form-field>

                  </div>
                </div>
              </form>
            </div>
          </mat-tab>

          <mat-tab label="Documents ({{documentCount}})">
            <div class="h500tabMin scroller">
              <app-document-edit [maxFileSize]="maxFileSize" [isControlDisable]="this.isControlDisable" (documentCountadded)="documentCountaddedHandler($event)" (documentsadded)="documentsaddedHandler($event)" (uploadDocsadded)="uploadDocsaddedHandler($event)"></app-document-edit>
            </div>
          </mat-tab>

          <mat-tab label="Links ({{referenceLinkCount}})">
            <div class="h500tabMin scroller">
              <app-reference-link-edit [domainlist]="domainlist" [isControlDisable]="this.isControlDisable" (refLinksadded)="refLinksaddedHandler($event)" (refLinkCountadded)="refLinkCountaddedHandler($event)"></app-reference-link-edit>
            </div>
          </mat-tab>

          <mat-tab label="Tags" *ngIf="this.showOtherTab">
            <div class="h500tabMin scroller">
              <app-project-fields-configuration-update [isControlDisable]="this.isTagControlDisable" [isOtherTabControlDisable]="this.isOtherTabControlDisable" (projectFieldsConfigadded)="projectFieldsConfigaddedHandler($event)" [interfaceId]="this.interfaceId" [interfaceType]="this.interfaceType"></app-project-fields-configuration-update>
            </div>
          </mat-tab>

          <mat-tab label="Response" *ngIf="this.isResponseGiven || this.isResponseProvider">
            <div class="h500tabMin scroller">
              <form [formGroup]="responseForm" *ngIf="this.isResponseGiven ? 'true' : this.isAssignedResponseProvider ">
                <mat-accordion>
                  <mat-radio-group>
                    <div class="row">
                      <div class="col-xl-12 col-l-12 col-md-12 col-sm-12 col-xs-12">
                        <mat-expansion-panel [class]="matExpansionPanelAccepted" [expanded]="this.isResponseGiven" [disabled]="this.isResponseGiven || this.isResponseDisabled" hideToggle>
                          <mat-expansion-panel-header (click)="panelHeaderResponse(buttonResponse)" class="matExpansionPanelHeader" *ngIf="!this.isResponseGiven">
                            <mat-panel-title class="matPanelTitle">
                              <mat-radio-button #buttonResponse class="acceptanceRadio" [value]="true"
                                                (change)="panelRadioButtonUnSelect(buttonResponse)" [checked]="isResponseChecked"></mat-radio-button>
                            </mat-panel-title>
                            <mat-panel-description class="mt-2 mb-2">
                              <div>
                                <div>Provide Response</div>
                                <div><span class="hintMessage">(When you select this option you accept received technical query information. and On Provide Response, the response will sent to requestor!)</span> </div>
                              </div>
                            </mat-panel-description>
                          </mat-expansion-panel-header>

                          <mat-form-field appearance="standard">
                            <mat-label>Response</mat-label>
                            <textarea matInput formControlName="response" name="Response"
                                      [(ngModel)]="interfaceModel.Response" maxlength="3000" class="heightTextArea" appTrimWhitespace required></textarea>
                            <mat-error *ngIf="this.responseForm.get('response')?.hasError('required')">
                              Response is <strong>required</strong>
                            </mat-error>
                            <mat-hint align="end">{{interfaceModel.Response?.length || 0}}/3000</mat-hint>
                          </mat-form-field>
                          <mat-form-field appearance="standard">
                            <mat-label>References</mat-label>
                            <textarea matInput formControlName="referenceForResponse" name="ReferenceForResponse"
                                      matTooltip="Max 1500 characters." [(ngModel)]="interfaceModel.ReferenceForResponse" maxlength="1500"
                                      class="heightTextArea" appTrimWhitespace></textarea>
                            <mat-hint align="end">{{interfaceModel.ReferenceForResponse?.length || 0}}/1500</mat-hint>
                          </mat-form-field>
                          <mat-form-field appearance="standard">
                            <mat-label>Completed by {{providerContractorLabel}}</mat-label>
                            <input matInput #input value={{this.completedByUser}} readonly>
                          </mat-form-field>
                          <button mat-stroked-button class="WIRbutton WIRbutton-accept mt-2"
                                  *ngIf="(isResponseAndSignChecked || isResponseChecked || !this.isResponseGiven) && this._isProvider" [disabled]="this.responseForm.invalid || this.isResponseGiven"
                                  (click)='onResponseOrReject()'>
                            <mat-icon class="WIRbutton-icons">assignment_turned_in</mat-icon>Submit
                          </button>
                        </mat-expansion-panel>
                      </div>
                    </div>
                    <div class="row" *ngIf="!this.isResponseGiven">
                      <div class="col-xl-12 col-l-12 col-md-12 col-sm-12 col-xs-12">
                        <mat-expansion-panel [class]="matExpansionPanelRejected" [disabled]="this.isResponseDisabled" hideToggle>
                          <mat-expansion-panel-header (click)="panelHeaderAdditional(buttonAdditional)" class="matExpansionPanelHeader">
                            <mat-panel-title class="matPanelTitle">
                              <mat-radio-button #buttonAdditional class="rejectedRadio" [value]="true" (change)="panelRadioButtonUnSelect(buttonAdditional)"
                                                [checked]="isAdditionalChecked"></mat-radio-button>
                            </mat-panel-title>
                            <mat-panel-description class="mt-2 mb-2">
                              <div>
                                <div>Additional Information Required</div>
                                <div><span class="hintMessage">(When you select this option you are not accepting received request information. and On Reject, Action for providing new information is then transferred to requesting contractor)</span> </div>
                              </div>
                            </mat-panel-description>
                          </mat-expansion-panel-header>
                          <mat-form-field appearance="standard">
                            <mat-label>Comments</mat-label>
                            <textarea formControlName="rejectionComments" [(ngModel)]="rejectionComments" matInput
                                      name="rejectionComments" maxlength="3000" class="heightTextArea" required></textarea>
                            <mat-hint align="end">{{rejectionComments?.length || 0}}/1500</mat-hint>
                            <mat-error *ngIf="this.responseForm.get('rejectionComments')?.hasError('required')">
                              Comments is <strong>required</strong>
                            </mat-error>
                          </mat-form-field>
                          <button mat-stroked-button class="WIRbutton WIRbutton-reject mt-2"
                                  *ngIf="isAdditionalAndSignChecked || isAdditionalChecked" [disabled]="this.responseForm.invalid"
                                  (click)='onResponseOrReject()'>
                            <mat-icon class="WIRbutton-icons">assignment_turned_in</mat-icon>Reject
                          </button>
                        </mat-expansion-panel>
                      </div>
                    </div>
                  </mat-radio-group>
                </mat-accordion>
              </form>
              <div *ngIf="this._isProvider">
                <app-proposeresponse-view [interfaceId]="this.interfaceId"></app-proposeresponse-view>
              </div>
            </div>
          </mat-tab>

          <mat-tab label="Response History" *ngIf="this.isResponseGiven || this.isResponseHistoryVisible">
            <div class="h500tabMin scroller">
              <div class="container py-2 mt-4 mb-4">
                <!-- timeline item 1 -->
                <div class="row" *ngFor="let response of responseHistoryPaging let i = index;">
                  <!-- timeline item 1 left dot -->
                  <div class="col-auto text-center flex-column d-none d-sm-flex">
                    <div class="row h-40">
                      &nbsp;
                    </div>
                    <h5 class="m-2">
                      <div class="float-right text-muted fw-bolder">
                        {{response.CreatedDate | date : 'medium': utcoffsetvalue }}
                      </div>
                    </h5>
                    <div class="row h-50">
                      &nbsp;
                    </div>
                  </div>

                  <div class="col-auto text-center flex-column d-none d-sm-flex">
                    <div class="row h-40">
                      <div class="col border-right">&nbsp;</div>
                      <div class="col">&nbsp;</div>
                    </div>
                    <h5 class="m-2">
                      <span class="badge badge-pill bg-light border pillBorder">&nbsp;</span>
                    </h5>
                    <div class="row h-50">
                      <div class="col border-right">&nbsp;</div>
                      <div class="col">&nbsp;</div>
                    </div>
                  </div>
                  <!-- timeline item 1 event content -->
                  <div class="col py-2">
                    <div class="card right">
                      <div class="card-body">
                        <!-- <div class="float-right text-muted fw-bolder">{{response.CreatedDate | date:'dd/MM/yyyy hh:mm:ss' : utcoffsetvalue}}</div> -->
                        <h4 class="card-title">
                          <span *ngIf="response.Comments == ''">Response by :</span> <span *ngIf="response.Comments != ''">Comments by :</span> {{
response.CreatedUserRoleMapping != null ?
                          response.CreatedUserRoleMapping?.User.FirstName +' '+ response.CreatedUserRoleMapping?.User.LastName +' - ('+ response.Contractor?.Name +')' : ""
                          }}
                        </h4>
                        <div *ngIf="response.Comments == ''">
                          <p class="card-text card-text-content">{{response.Information}}</p>
                        </div>
                        <div *ngIf="response.Comments != ''">
                          <p class="card-text card-text-content">{{response.Comments}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--/row-->
              </div>
            </div>
          </mat-tab>


          <mat-tab label="Acceptance" *ngIf="this.isAcceptanceTabVisible">
            <div class="h500tabMin scroller">
              <form [formGroup]="acceptanceForm">
                <mat-accordion>
                  <mat-radio-group>
                    <div class="row">
                      <div class="col-xl-12 col-l-12 col-md-12 col-sm-12 col-xs-12">
                        <mat-expansion-panel [class]="matExpansionPanelAccepted" hideToggle>
                          <mat-expansion-panel-header (click)="panelHeaderAccepted(buttonR1)" class="matExpansionPanelHeader">
                            <mat-panel-title class="matPanelTitle">
                              <mat-radio-button #buttonR1 class="acceptanceRadio" [value]="true"
                                                (change)="panelRadioButtonUnSelect(buttonR1)" [checked]="isAcceptedChecked"></mat-radio-button>
                            </mat-panel-title>
                            <mat-panel-description class="mt-2 mb-2">
                              <div>
                                <div>Received information accepted</div>
                                <div><span class="hintMessage">(When you select this option you accept received tq information. and after clicking Accept, the tq item will be proposed to close!!)</span> </div>
                              </div>
                            </mat-panel-description>
                          </mat-expansion-panel-header>

                          <mat-form-field appearance="standard" *ngIf="isAcceptedChecked">
                            <mat-label>Comments</mat-label>
                            <textarea formControlName="acceptanceComments" [(ngModel)]="acceptanceComments" matInput
                                      name="acceptanceComments" maxlength="1500" class="heightTextArea" required></textarea>
                            <mat-hint align="end">{{acceptanceComments?.length || 0}}/1500</mat-hint>
                            <mat-error *ngIf="this.acceptanceForm.get('acceptanceComments')?.hasError('required')">
                              Comments is <strong>required</strong>
                            </mat-error>
                          </mat-form-field>
                          <button mat-stroked-button class="WIRbutton WIRbutton-accept mt-2"
                                  *ngIf="isAcceptedAndSignChecked || isAcceptedChecked" [disabled]="this.acceptanceForm.invalid"
                                  (click)='onAcceptAndSign()'>
                            <mat-icon class="WIRbutton-icons">assignment_turned_in</mat-icon>Accept
                          </button>
                        </mat-expansion-panel>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-xl-12 col-l-12 col-md-12 col-sm-12 col-xs-12">
                        <mat-expansion-panel [class]="matExpansionPanelRejected" hideToggle>
                          <mat-expansion-panel-header (click)="panelHeaderRejected(buttonR2)" class="matExpansionPanelHeader">
                            <mat-panel-title class="matPanelTitle">
                              <mat-radio-button #buttonR2 class="rejectedRadio" [value]="true" (change)="panelRadioButtonUnSelect(buttonR2)"
                                                [checked]="isRejectedChecked"></mat-radio-button>
                            </mat-panel-title>
                            <mat-panel-description class="mt-2 mb-2">
                              <div>
                                <div>Received information not accepted</div>
                                <div><span class="hintMessage">(When you select this option, you decline to accept the received tq information. The {{providerContractorLabel}} will then be responsible for providing revised information.)</span> </div>
                              </div>
                            </mat-panel-description>
                          </mat-expansion-panel-header>
                          <mat-form-field appearance="standard" *ngIf="isRejectedChecked">
                            <mat-label>Comments</mat-label>
                            <textarea formControlName="rejectionComments" [(ngModel)]="rejectionComments" matInput
                                      name="rejectionComments" maxlength="3000" class="heightTextArea" required></textarea>
                            <mat-hint align="end">{{rejectionComments?.length || 0}}/1500</mat-hint>
                            <mat-error *ngIf="this.acceptanceForm.get('rejectionComments')?.hasError('required')">
                              Comments is <strong>required</strong>
                            </mat-error>
                          </mat-form-field>
                          <button mat-stroked-button class="WIRbutton WIRbutton-reject mt-2"
                                  *ngIf="isRejectedAndSignChecked || isRejectedChecked" [disabled]="this.acceptanceForm.invalid"
                                  (click)='onAcceptAndSign()'>
                            <mat-icon class="WIRbutton-icons">assignment_turned_in</mat-icon>Reject
                          </button>
                        </mat-expansion-panel>
                      </div>
                    </div>

                  </mat-radio-group>
                </mat-accordion>

              </form>
            </div>
          </mat-tab>
          <mat-tab label="Additional Information History" *ngIf="this.isAdditionalInformationShow">
            <div class="h500tabMin scroller">
              <app-additional-information [interfaceId]="this.interfaceId"></app-additional-information>
            </div>
          </mat-tab>

          <mat-tab label="Assignment" *ngIf="this.isAssignmentShow">
            <div class="h500tabMin scroller">
              <app-assignment [interfaceId]="this.interfaceId"></app-assignment>
            </div>
          </mat-tab>
          <mat-tab label="Propose Response" *ngIf="this.isProposeResponseShow">
            <div class="h500tabMin scroller">
              <app-proposeresponse [interfaceId]="this.interfaceId"></app-proposeresponse>
            </div>
          </mat-tab>

          <mat-tab label="Discussions">
            <div class="h500tabMin scroller">
              <app-discussion (discussionadded)="discussionaddedHandler($event)" [interfacegroupId]="this.interfacegroupId" [interfaceModel]="this.interfaceModel" [moduleId]="this.moduleId" [interfaceId]="this.interfaceId"></app-discussion>
            </div>
          </mat-tab>

        </mat-tab-group>

        <div class="mt-2 mb-2" align="right">
          <button mat-stroked-button class="WIRbutton WIRbutton-primary" [disabled]="this.form.invalid || this.isSaveDisabled" (click)='save()'>
            <mat-icon class="WIRbutton-icons">save</mat-icon>Save
          </button>
          <button mat-stroked-button type="button" class="WIRbutton WIRbutton-secondary" [appConfirmBackButton]="[this.oldInterfaceModel,this.interfaceModel]">
            <mat-icon class="WIRbutton-icons">arrow_back</mat-icon>Back
          </button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
