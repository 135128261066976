import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MinutesOfMeeting } from '../app_data/minutesOfMeeting';
import { response } from '../app_data/OdataResponse';
import { Observable, catchError, map, throwError } from 'rxjs';
import { MinutesOfMeetingDetails } from '../app_data/minutesOfMeetingDetails';
import { CommonFunction } from '../app_data/commonFunction';

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
    "Accept": "*/*",
  }
  )
}

@Injectable({
  providedIn: 'root'
})

export class MinutesofMeetingService {
  sortColumns = [
    { "DisplayName": "Newest First", "Value": "NewestFirst" },
    { "DisplayName": "Oldest First", "Value": "OldestFirst" },
    ];

  _projectTimeZone = sessionStorage.getItem("projectTimeZone")!;
  private apiUrl = environment.apiURl + 'minutesOfMeetings';
  private apiUrlapiURlMinutesOfMeetingDetails = environment.apiURl + 'minutesOfMeetingDetails';
  constructor(private http: HttpClient) { }

  convertUTCDatesToTimezonedate(data: MinutesOfMeeting[]){              
    // convert all dates to timezone datetime        
    data.forEach(x => {
      x.CreatedDate = x.CreatedDate != null ? CommonFunction.ConvertUTCToTimeZoneTime(x.CreatedDate, this._projectTimeZone, false) : null!;
      x.SignedByContractor1Date = x.SignedByContractor1Date != null ? CommonFunction.ConvertUTCToTimeZoneTime(x.SignedByContractor1Date, this._projectTimeZone, false) : null!;
      x.SignedByContractor2Date = x.SignedByContractor2Date != null ? CommonFunction.ConvertUTCToTimeZoneTime(x.SignedByContractor2Date, this._projectTimeZone, false) : null!;

      if (x.MinutesOfMeetingDetails != null) {
        x.MinutesOfMeetingDetails.forEach(momDetailsData => {
          momDetailsData.CreatedDate = momDetailsData.CreatedDate != null ? CommonFunction.ConvertUTCToTimeZoneTime(momDetailsData.CreatedDate, this._projectTimeZone, false) : null!;
        });
      }

      if (x.MinutesOfMeetingAttendees != null) {
        x.MinutesOfMeetingAttendees.forEach(momAttendeesData => {
          momAttendeesData.CreatedDate = momAttendeesData.CreatedDate != null ? CommonFunction.ConvertUTCToTimeZoneTime(momAttendeesData.CreatedDate, this._projectTimeZone, false) : null!;
        });
      }

      if (x.Documents != null) {
        x.Documents.forEach(documentData => {
          documentData.CreatedDate = documentData.CreatedDate != null ? CommonFunction.ConvertUTCToTimeZoneTime(documentData.CreatedDate, this._projectTimeZone, false) : null!;
        });
      }

      if (x.ActionItems != null) {
        x.ActionItems.forEach(actionData => {
          actionData.ClosedDate = actionData.ClosedDate != null ? CommonFunction.ConvertUTCToTimeZoneTime(actionData.ClosedDate, this._projectTimeZone, false) : null!;
          actionData.CreatedDate = actionData.CreatedDate != null ? CommonFunction.ConvertUTCToTimeZoneTime(actionData.CreatedDate, this._projectTimeZone, false) : null!;
        });
      }

    })
    
    return data;
}

convertUTCDatesToTimezonedateForMOMDetails(data: MinutesOfMeetingDetails[]){              
  // convert all dates to timezone datetime        
  data.forEach(x=> {       
    x.CreatedDate = x.CreatedDate != null ? CommonFunction.ConvertUTCToTimeZoneTime(x.CreatedDate, this._projectTimeZone, false) : null!;

  if(x.MinutesOfMeetings != null){
    x.MinutesOfMeetings.CreatedDate = x.MinutesOfMeetings.CreatedDate != null ? CommonFunction.ConvertUTCToTimeZoneTime(x.MinutesOfMeetings.CreatedDate, this._projectTimeZone, false) : null!;
    x.MinutesOfMeetings.SignedByContractor1Date = x.MinutesOfMeetings.SignedByContractor1Date != null ? CommonFunction.ConvertUTCToTimeZoneTime(x.MinutesOfMeetings.SignedByContractor1Date, this._projectTimeZone, false) : null!;
    x.MinutesOfMeetings.SignedByContractor2Date = x.MinutesOfMeetings.SignedByContractor2Date != null ? CommonFunction.ConvertUTCToTimeZoneTime(x.MinutesOfMeetings.SignedByContractor2Date, this._projectTimeZone, false) : null!;

    if (x.MinutesOfMeetings.MinutesOfMeetingDetails != null) {
      x.MinutesOfMeetings.MinutesOfMeetingDetails.forEach(momDetailsData => {
        momDetailsData.CreatedDate = momDetailsData.CreatedDate != null ? CommonFunction.ConvertUTCToTimeZoneTime(momDetailsData.CreatedDate, this._projectTimeZone, false) : null!;
      });
    }

    if (x.MinutesOfMeetings.MinutesOfMeetingAttendees != null) {
      x.MinutesOfMeetings.MinutesOfMeetingAttendees.forEach(momAttendeesData => {
        momAttendeesData.CreatedDate = momAttendeesData.CreatedDate != null ? CommonFunction.ConvertUTCToTimeZoneTime(momAttendeesData.CreatedDate, this._projectTimeZone, false) : null!;
      });
    }

    if (x.MinutesOfMeetings.Documents != null) {
      x.MinutesOfMeetings.Documents.forEach(documentData => {
        documentData.CreatedDate = documentData.CreatedDate != null ? CommonFunction.ConvertUTCToTimeZoneTime(documentData.CreatedDate, this._projectTimeZone, false) : null!;
      });
    }

    if (x.MinutesOfMeetings.ActionItems != null) {
      x.MinutesOfMeetings.ActionItems.forEach(actionData => {
        actionData.ClosedDate = actionData.ClosedDate != null ? CommonFunction.ConvertUTCToTimeZoneTime(actionData.ClosedDate, this._projectTimeZone, false) : null!;
        actionData.CreatedDate = actionData.CreatedDate != null ? CommonFunction.ConvertUTCToTimeZoneTime(actionData.CreatedDate, this._projectTimeZone, false) : null!;
      });
    }
  }
  })
  
  return data;
}



  getAllMinutesOfMeeting(pageIndex: number, pageSize: number,  filter: string): Observable<MinutesOfMeeting[]> {
    return this.http.get<response>(this.apiUrl + "?$expand=Interfacegroup($expand=Contractor1,Contractor2),Module,SignedByContractor1User,SignedByContractor2User,ActionItems($filter=IsDeleted eq false;$expand=InterfaceGroup,ResContractor),MinutesOfMeetingDetails,Documents($filter=IsDeleted eq false;$expand=Contractor,CreatedUserRoleMapping($expand=User,Contractor,Role;$filter=IsDeleted eq false))&" + filter + "&$count=true&$top=" + pageSize + "&$skip=" + pageIndex * pageSize)
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  getAllMoMs(filter: string): Observable<MinutesOfMeeting[]> {
    return this.http.get<response>(this.apiUrl + "?$expand=Interfacegroup($expand=Contractor1,Contractor2),Module,SignedByContractor1User,SignedByContractor2User,ActionItems($filter=IsDeleted eq false;$expand=InterfaceGroup,ResContractor),MinutesOfMeetingDetails,Documents($filter=IsDeleted eq false;$expand=Contractor,CreatedUserRoleMapping($expand=User,Contractor,Role;$filter=IsDeleted eq false))&" + filter)
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  getMinutesofMeeting(projectId: number,interfaceGroupsIds: string): Observable<MinutesOfMeeting[]> {
    return this.http.get<response>(this.apiUrl + "?$expand=Interfacegroup($expand=Contractor1,Contractor2),Module,ActionItems($filter=IsDeleted eq false;$expand=InterfaceGroup,ResContractor),MinutesOfMeetingDetails,Documents($filter=IsDeleted eq false;$expand=Contractor,CreatedUserRoleMapping($expand=User,Contractor,Role;$filter=IsDeleted eq false))&$filter=ProjectId eq  "+ projectId +" and IsDeleted eq false and InterfaceGroupID in(" + interfaceGroupsIds +") &$orderby=Id desc", httpOptions)
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  getMinutesofMeetingbyInterfaceId(interfaceID : number): Observable<MinutesOfMeetingDetails[]>{
    return this.http.get<response>(this.apiUrlapiURlMinutesOfMeetingDetails + "?$expand=MinutesOfMeetings($expand=Interfacegroup($expand=Contractor1,Contractor2),Module,Documents($filter=IsDeleted eq false;$expand=Contractor,CreatedUserRoleMapping($expand=User,Contractor,Role;$filter=IsDeleted eq false))) &$filter=IsDeleted eq false and InterfaceID eq "+interfaceID+" &$orderby=Id desc", httpOptions)
    .pipe(
      map((data: response) => data.value),
      catchError(this.handleError)
    )
  }

  getMinutesofMeetingById(momId: number): Observable<MinutesOfMeeting[]> {
    return this.http.get<response>(this.apiUrl + "?$expand=Documents($filter=IsDeleted eq false;$expand=Contractor,CreatedUserRoleMapping($expand=User,Contractor,Role;$filter=IsDeleted eq false)),InterfaceGroup($expand=Contractor1,Contractor2),ActionItems($filter=IsDeleted eq false;$expand=InterfaceGroup,ResContractor),Module,MinutesOfMeetingDetails($filter=IsDeleted eq false;$expand=Interfaces),MinutesOfMeetingAttendees($filter=IsDeleted eq false;$expand=Contractor1,Contractor2, Contractor1AttendeeUser, Contractor2AttendeeUser),SignedByContractor1User,SignedByContractor2User&$filter=Id eq "+ momId +" and IsDeleted eq false&$orderby=Id desc")
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  getMinutesofMeetingByIdandProjectId(momId: number,projectId:number): Observable<MinutesOfMeeting[]> {
    return this.http.get<response>(this.apiUrl + "?$expand=Documents($filter=IsDeleted eq false;$expand=Contractor,CreatedUserRoleMapping($expand=User,Contractor,Role;$filter=IsDeleted eq false)),InterfaceGroup($expand=Contractor1,Contractor2),ActionItems($filter=IsDeleted eq false;$expand=InterfaceGroup,ResContractor),Module,MinutesOfMeetingDetails($filter=IsDeleted eq false;$expand=Interfaces),MinutesOfMeetingAttendees($filter=IsDeleted eq false;$expand=Contractor1,Contractor2, Contractor1AttendeeUser, Contractor2AttendeeUser),SignedByContractor1User,SignedByContractor2User&$filter=Id eq " + momId + " and ProjectId eq " + projectId + " and IsDeleted eq false&$orderby=Id desc")
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  getSortColumnList() {
    return this.sortColumns;
  }

  getMOMForCount(filter: string): Observable<MinutesOfMeeting[]> {
    return this.http.get<response>(this.apiUrl + "?$select=Id,ModuleId" + filter)
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }
  post(model: MinutesOfMeeting): Observable<MinutesOfMeeting> {
    return this.http.post<MinutesOfMeeting>(this.apiUrl, model, httpOptions);
  }
  
  put(model: MinutesOfMeeting): Observable<MinutesOfMeeting> {
    return this.http.put<MinutesOfMeeting>(this.apiUrl + "/" + model.Id, model, httpOptions);
  }

  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {

      errorMessage = `An error occurred: ${err.error.message}`;
    } else {

      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    return throwError(errorMessage);
  }
}
