<div class="container-fluid">
  <div class="row">
    <mat-accordion>
      <mat-expansion-panel class="" is-disabled="true" is-open="status-open" [expanded]="panelOpenState">
        <mat-expansion-panel-header>
          <mat-panel-title class="filter_font align-items-center font-color-darkgrey">
            <mat-icon>filter_alt</mat-icon> Click here to search
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="margin-btr-5-l-30 selectorClass">
          <div class="row g-2" *ngFor="let filter of filterArray; let i = index;">
            <div class="col-md-4">
              <mat-form-field appearance="standard" class="width-100" [ngClass]="!filterArray[i].HasFilterColumn ? 'mat-form-field-invalid' : 'mat-form-field-valid'">
                <mat-label>Filter On</mat-label>
                <mat-select [(ngModel)]="filterArray[i].FilterOn" (selectionChange)='onFilterChange(i)' [ngClass]="(!filterArray[i].HasFilterColumn || filterArray[i].IsFilterAlreadyExist) ? 'mat-select-invalid' : ''" required>
                  <mat-option *ngFor="let filterColumn of filterColumnList" [value]="filterColumn.Value">{{ filterColumn.DisplayName }}</mat-option>
                </mat-select>
                <mat-error *ngIf="!filterArray[i].IsFilterAlreadyExist && !filterArray[i].HasFilterColumn">
                  Filter Column is <strong>required</strong>
                </mat-error>
                <mat-hint class="mat-error" *ngIf="filterArray[i].IsFilterAlreadyExist">
                  Filter Column is <strong>already added in list.</strong>
                </mat-hint>
              </mat-form-field>
            </div>
            <div class="col-md-7 display-flex align-items-center padding-left-30">
              <div *ngIf="filterArray[i].Type === 'text'" class="width-100">
                <mat-form-field appearance="standard" class="width-100" [ngClass]="!filterArray[i].HasValue ? 'mat-form-field-invalid' : 'mat-form-field-valid'">
                  <mat-label>Value</mat-label>
                  <input matInput autocomplete="off" placeholder="" [disabled]="filterArray[i].IsFilterAlreadyExist" [(ngModel)]="filterArray[i].Value" [value]="filterArray[i].Value" (change)='onFilterValueChange()' required>
                  <mat-error *ngIf="!filterArray[i].HasValue && filterArray[i].Type === 'text'">
                    Value is <strong>required</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <div *ngIf="filterArray[i].Type === 'list'" class="width-100">
                <mat-form-field appearance="standard" class="width-100" [ngClass]="!filterArray[i].HasValue ? 'mat-form-field-invalid' : 'mat-form-field-valid'">
                  <mat-label>Value</mat-label>
                  <mat-select [(ngModel)]="filterArray[i].Value" #dataList multiple (selectionChange)='onFilterValueChange()' [ngClass]="!filterArray[i].HasValue ? 'mat-select-invalid' : ''" required>
                    <mat-option *ngFor="let item of filterArray[i].DataList" [value]="item.Id">{{ item.Name }}</mat-option>
                  </mat-select>
                  <mat-error *ngIf="!filterArray[i].HasValue && filterArray[i].Type === 'list'">
                    Value is <strong>required</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <div *ngIf="filterArray[i].Type === 'date'" class="width-100">
                <mat-form-field appearance="standard" class="dateRange">
                  <mat-label>Date Range</mat-label>
                  <div class="input-group hide-blue-border">
                    <input matInput autocomplete="off" name="dateRange" placeholder="Date Range" required [(ngModel)]="filterArray[i].DateRange"
                           type="text" bsDaterangepicker class="form-control" [bsConfig]="datePickerConfig" #d="bsDaterangepicker" />
                    <button style="padding:0px !important" class="btn-sm calendar calendar-icon-btn " (click)="d.toggle()" type="button">
                      <mat-icon>calendar_month</mat-icon>
                    </button>
                  </div>
                  <mat-error *ngIf="!filterArray[i].HasValue && filterArray[i].Type === 'date'">
                    Date Range is <strong>required</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="col-md-1 display-flex">
              <div *ngIf="filterArray.length > 1" class="padding-right-10 align-self-center cursor-pointer">
                <mat-icon (click)="deleteFilter(i)">clear</mat-icon>
              </div>
              <div *ngIf="((filterArray.length-1) == i)" class="padding-right-0 align-self-center cursor-pointer">
                <mat-icon (click)="addNewFilter()">add</mat-icon>
              </div>
            </div>
          </div>
          <div class="row g-2">
            <div class="col-md-4">
            </div>
            <div class="col-md-8">
              <span class="float-end display-flex">
                <button mat-button class="WIRbutton WIRbutton-secondary" (click)="setDefaultFilters()">
                  <mat-icon class="WIRbutton-icons">restart_alt</mat-icon>Reset
                </button>
                <button mat-button class="WIRbutton WIRbutton-primary" (click)="searchFilters('NewestFirst', true)">
                  <mat-icon class="WIRbutton-icons">search</mat-icon>Search
                </button>
              </span>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <div class="row ">
    <div class="display-flex">
      <div class="col-md-4 align-self-center padding-0">
        <button mat-button class="WIRbutton WIRbutton-primary" [appSetRouteActive]="'Interfaces'" [hasPermission]="'technicalquery.create'" (click)="redirectNewTechnicalQuery()">
          <mat-icon class="WIRbutton-icons">add</mat-icon>New Technical Query
        </button>
      </div>
      <div class="col-md-2 align-self-center text-align-end font-color-lightgrey">
        <!--<mat-label>Sort by : </mat-label>-->
      </div>
      <div class="col-md-3 align-self-center">
        <button mat-stroked-button class="WIRbutton WIRbutton-primary float-end" [hasPermission]="'technicalquery.exportexcel'" (click)="exportExcel()">
          <mat-icon>download</mat-icon>
          Export to Excel
        </button>
        <button mat-stroked-button matTooltip="Print the current list of technical query items" (click)="printTechnicalQueryList()" class="WIRbutton WIRbutton-primary float-end btn-sm">
          <mat-icon >print</mat-icon> Print
        </button>
        <app-pdf-generate [reportName]="reportName" [pageOrientationType]="pageOrientationType" [projectName]="projectName" #PdfGenerateComponent></app-pdf-generate>
                
        <!--<a class="margin-left-right-5 float-right cursor-pointer color-green" (click)="exportExcel()">Export to Excel</a>-->
      </div>

      <div class="col-md-3 padding-0">
        <div class="display-flex">
          <mat-form-field appearance="standard" class="width-100">
            <mat-select (selectionChange)='onSortChange($event.value)' [(value)]="sortColumn">
              <mat-option *ngFor="let sortColumn of sortColumnList" [value]="sortColumn.Value"><span class="{{sortColumn.DisplayName == '' ? 'spn_sort':'spn_sort_hide'}}">Sort by : </span>{{ sortColumn.DisplayName }}</mat-option>
            </mat-select>
          </mat-form-field>
          <span class="padding-right-10 padding-left-10 align-self-center">
            <mat-icon *ngIf="!showGridView" (click)="showRecords()" matTooltip="Grid View" class="WIRbutton-primary vertical-middle">grid_on</mat-icon>
            <mat-icon *ngIf="showGridView" (click)="showRecords()" matTooltip="List View" class="WIRbutton-primary vertical-middle">table_rows</mat-icon>
          </span>
        </div>

      </div>
    </div>
  </div>
  <div class="row">
    <div>
      <mat-paginator #paginatorTop
                     [length]="length"
                     [pageSize]="pageSize"
                     [pageSizeOptions]="pageSizeOptions"
                     showFirstLastButtons
                     (page)="pageEvent=loadInterfaceRegister($event); paginatorBottom.pageIndex = pageIndex"
                     [pageIndex]="pageIndex"
                     aria-label="Select page">
      </mat-paginator>
    </div>
  </div>
  <div class="row mb-1"></div>
  <div class="row" *ngIf="!showGridView">
    <mat-accordion>
      <mat-expansion-panel #panel hideToggle *ngFor="let interface of filteredInterfaces let i = index;" [expanded]="i==0" class="margin-bottom-5 listPanel">
        <mat-expansion-panel-header #panelHeader class="card-border {{panel.expanded ? 'listPanel-large' : 'listPanel'}}">
          <mat-panel-title class=" align-items-center card-div col-md-10 ">
            <div class="col-md-3">
              <!-- width-120-->
              <span [ngClass]="commonFunction.getCardColorOnStatus(interface.Status, interface.IsApprovedIssuePlannedOverdue,interface.IsFirstIssuePlannedOverdue,interface.Response)" class="status-dot"></span>
              <span class="padding-15">{{interface.InterfaceStatusName}}</span>
            </div>
            <div>
              <div class="display-flex font-color-darkgrey">
                <div class="fw-bolder">#{{interface.Number}}  </div>
                <div class=" fst-italic  padding-left-10  padding-right-5 ">
                  <span class="badge" [ngClass]="getRequestorBadgeColor(interface.Status, interface.Response)">{{interface.RecContractor.Code}}</span>
                </div>
                <div>
                  <mat-icon class="font-size-20">arrow_right_alt</mat-icon>
                </div>
                <div class=" fst-italic  font-color-blue">
                  <span class="badge" [ngClass]="getProviderBadgeColor(interface.Status, interface.Response)">{{interface.ResContractor.Code}}</span>
                </div>
                <div *ngIf="interface.Prioritys.Category.toUpperCase()  === this.HighPriority;else normal" class="padding-left-10">
                  <mat-icon class="highpriority material-icons-outlined" matTooltip="Priority High">gpp_maybe</mat-icon>
                </div>
                <ng-template #normal>
                  <ng-container *ngIf="interface.Prioritys.Category.toUpperCase() === this.NormalPriority;else medium">
                    <div class="padding-left-10">
                      <mat-icon class="normalpriority material-icons-outlined" matTooltip="Priority Normal">gpp_maybe</mat-icon>
                    </div>
                  </ng-container>
                </ng-template>
                <ng-template #medium>
                  <ng-container *ngIf="interface.Prioritys.Category.toUpperCase() === this.MediumPriority;else low">
                    <div class="padding-left-10">
                      <mat-icon class="mediumpriority material-icons-outlined" matTooltip="Priority Medium">gpp_maybe</mat-icon>
                    </div>
                  </ng-container>
                </ng-template>
                <ng-template #low>
                  <div class="padding-left-10">
                    <mat-icon class="lowpriority material-icons-outlined" matTooltip="Priority Low">gpp_maybe</mat-icon>
                  </div>
                </ng-template>
                <div *ngIf="interface.Documents.length > 0" class="padding-left-10  padding-right-5">
                  <button class="button-icon-circle align-items-center docIconMargin" mat-mini-fab color="accent" [appSetRouteActive]="'TechnicalQueries'">
                    <mat-icon matTooltip="Documents present" (click)="onDocumentsPresent(interface.Id)">
                      attachment
                    </mat-icon>
                  </button>
                </div>
                <div *ngIf="interface.IsAcceptedAsPreliminary" class="padding-left-10  padding-right-5">
                  <mat-icon class="preliminary material-icons-outlined" matTooltip="Accepted as Preliminary">task</mat-icon>
                </div>
                <div *ngIf="interface.Revisions.length > 0" class="padding-left-10  padding-right-5">
                  <mat-icon class="icon-size-18 vertical-middle" matTooltip="Revision Created" (click)="onRevisionsPresent(interface.Id)">directions_alt</mat-icon>
                </div>
                <div *ngIf="interface.IsStageRejected" class="padding-left-10  padding-right-5">
                  <mat-icon class="rejected material-icons-outlined" matTooltip={{interface.StageApproveRejectComment}}>keyboard_return</mat-icon>
                </div>
                <div *ngIf="interface.MinutesOfMeetingDetails?.length > 0" class="padding-left-5  padding-right-5">
                  <mat-icon class="icon-size-18 material-symbols-outlined" matTooltip="MOM present" (click)="onMinutesOfMeetingPresent(interface.Id)">edit_square</mat-icon>
                </div>
              </div>
              <div>
                {{panel.expanded ? interface.Title : interface.Title | shorten:125}}
              </div>
            </div>
          </mat-panel-title>
          <div class="col-md-2 align-self-center display-flex">
            <span class="padding-left-right-2">
              <button class="button-icon-circle align-items-center action-button" [appSetRouteActive]="'Interfaces'"
                      [disabled]="!this.hasPermission('interface.view')"
                      (click)="onView(interface.Id)">
                <mat-icon class="icon-size-20" matTooltip="View">visibility</mat-icon>
              </button>
            </span>
            <span class="padding-left-right-2">
              <button class="button-icon-circle align-items-center action-button" [appSetRouteActive]="'Interfaces'" (click)="onEdit(interface.Id)"
                      [disabled]="!(interface.Status != this.closeStatus && interface.Status != this.voidedStatusId && this.hasPermission('interface.edit') && (this._userRoleId == this.superAdminRole || this._userRoleId == this.projectAdminRole || this._userRoleId == this.gatekeeperRole || this._userRoleId == this.contractorRole))">
                <mat-icon class="icon-size-20" matTooltip="Edit">edit</mat-icon>
              </button>
            </span>
            <span class="padding-left-right-2">
              <button class="button-icon-circle align-items-center action-button" [appSetRouteActive]="'Interfaces'" (click)="onProposedate(interface.Id)"
                      [disabled]="!(interface.Status == this.awaitingResponseStatusId && this.hasPermission('technicalquery.proposedate') && interface.ResponsibleContractorID == this._contractorID )">
                <mat-icon class="icon-size-20" matTooltip="{{proposeDateLabel}}">edit_calendar</mat-icon>
              </button>
            </span>
            <span class="padding-left-right-2">
              <button class="button-icon-circle align-items-center action-button" (click)="confirmCancelDialog(interface.Id)"
                      [disabled]="!(interface.Status != this.closeStatus && this.hasPermission('interface.delete') && (this._userRoleId == this.superAdminRole || this._userRoleId == this.projectAdminRole))">
                <mat-icon class="icon-size-20" matTooltip="Delete">delete</mat-icon>
              </button>
            </span>
            <span class="padding-left-right-2">
              <button class="button-icon-circle align-items-center action-button" [disabled]="!this.hasPermission('interface.log')" (click)="viewLog(interface.Id)" [appSetRouteActive]="'Interfaces'">
                <mat-icon class="icon-size-20" matTooltip="Log">history</mat-icon>
              </button>
            </span>
          </div>
        </mat-expansion-panel-header>
        <div class="padding-top-bottom-2 card-div ">
          <div class=" g-2 display-flex">
            <!-- <div class="width-250" style="min-width:120px;"></div> -->
            <div class="row col-md-12 margin-top-bottom-10 display-flex">
              <div class="col-md-1">
                <mat-label class="font-color-darkgrey fw-bolder">
                  Description:
                </mat-label>
              </div>
              <div class="col-md-11 padding-left-75 min-20-max-100-height overflow-auto enable-line-break">
                {{interface.Description}}

              </div>
            </div>
          </div>

          <div class=" g-2 display-flex">
            <!-- <div class="width-250" style="min-width:120px;"></div> -->
            <div class="row col-md-12 margin-top-bottom-10">
              <div class="col-md-4 display-flex">
                <mat-label class="font-color-darkgrey fw-bolder">
                  {{expectedResponseDateLabel}}:
                </mat-label>
                <mat-label class="padding-left-20">{{interface.FirstIssuePlanned | date:dateTimeFormat}}</mat-label>
              </div>

              <div class="col-md-4 display-flex">
                <mat-label class="font-color-darkgrey fw-bolder">
                  {{closeOutDateLabel}}:
                </mat-label>
                <mat-label class="padding-left-20">{{interface.ApprovedIssuePlanned | date:dateTimeFormat}}</mat-label>
              </div>

              <div class="col-md-4 display-flex">
                <mat-label class="font-color-darkgrey fw-bolder">
                  Created Date:
                </mat-label>
                <mat-label class="padding-left-20">{{interface.CreatedDate | date:dateTimeFormat : utcoffsetvalue}}</mat-label>
              </div>
            </div>
          </div>

          <div class=" g-2 display-flex" *ngIf="interface.FirstIssueActual != null || interface.ApprovedIssueActual != null">
            <div class="row col-md-12 margin-top-bottom-10">
              <div class="col-md-4 display-flex" *ngIf="interface.FirstIssueActual != null">
                <mat-label class="font-color-darkgrey fw-bolder">
                  {{responseDateActualLabel}}:
                </mat-label>
                <mat-label class="padding-left-20">{{interface.Status == this.awaitingForResponseApprovalStatusId? '' : interface.FirstIssueActual | date:dateTimeFormat : utcoffsetvalue}}</mat-label>
              </div>

              <div class="col-md-4 display-flex" *ngIf="interface.ApprovedIssueActual != null && interface.Status != this.awaitingForCloseOutStatusId">
                <mat-label class="font-color-darkgrey fw-bolder">
                  {{closeOutDateActualLabel}}:
                </mat-label>
                <mat-label class="padding-left-20">{{interface.ApprovedIssueActual | date:dateTimeFormat : utcoffsetvalue}}</mat-label>
              </div>
            </div>
          </div>

          <div class=" g-2 display-flex" *ngIf="interface.ProviderFirstIssuePlanned != null || interface.ProviderApprovedIssuePlanned != null">
            <!-- <div class="width-250" style="min-width:120px;"></div> -->
            <div class="row col-md-12 margin-top-bottom-10">
              <div class="col-md-4 display-flex">
                <mat-label class="font-color-darkgrey fw-bolder">
                  Proposed {{expectedResponseDateLabel}}:
                </mat-label>
                <mat-label class="padding-left-20">{{interface.ProviderFirstIssuePlanned | date:dateTimeFormat}}</mat-label>
              </div>

              <div class="col-md-4 display-flex">
                <mat-label class="font-color-darkgrey fw-bolder">
                  Proposed {{closeOutDateLabel}}:
                </mat-label>
                <mat-label class="padding-left-20">{{interface.ProviderApprovedIssuePlanned | date:dateTimeFormat}}</mat-label>
              </div>
              <div class="col-md-4 display-flex">
              </div>
            </div>
          </div>
          <div class=" g-2 display-flex">
            <!-- <div class="width-250" style="min-width:120px;"></div> -->
            <div class="row col-md-12 margin-top-bottom-10">
              <div class="col-md-4 display-flex">
                <mat-label class="font-color-darkgrey fw-bolder">
                  Query Type:
                </mat-label>
                <mat-label class="padding-left-20">{{interface.TechnicalQuery.QueryType.Name}}</mat-label>
              </div>
              <div class="col-md-4 display-flex">
                <mat-label class="font-color-darkgrey fw-bolder">
                  Confidential:
                </mat-label>
                <mat-label class="padding-left-20">{{interface.TechnicalQuery.IsConfidential ? "Yes" : "No"}}</mat-label>
              </div>
              <div class="col-md-4 display-flex">
                <mat-label class="font-color-darkgrey fw-bolder">
                  Priority:
                </mat-label>
                <mat-label class="padding-left-20">{{interface.Prioritys.Name}}</mat-label>
              </div>
            </div>
          </div>
          <div class=" g-2 display-flex" *ngIf="interface.TechnicalQuery.IsConfidential">
            <!-- <div class="width-250" style="min-width:120px;"></div> -->
            <div class="row col-md-12 margin-top-bottom-10">
              <div class="col-md-8 display-flex">
                <mat-label class="font-color-darkgrey fw-bolder">
                  Confidential Justification:
                </mat-label>
                <mat-label class="padding-left-20">{{interface.TechnicalQuery.ConfidentialJustification}}</mat-label>
              </div>

              <div class="col-md-4 display-flex">
              </div>
            </div>
          </div>
          <div class=" g-2 display-flex" *ngIf="interface.Priority == 5">
            <!-- <div class="width-250" style="min-width:120px;"></div> -->
            <div class="row col-md-12 margin-top-bottom-10">
              <div class="col-md-8 display-flex">
                <mat-label class="font-color-darkgrey fw-bolder">
                  Priority Justification:
                </mat-label>
                <mat-label class="padding-left-20">{{interface.TechnicalQuery.PriorityJustification}}</mat-label>
              </div>
              <div class="col-md-4 display-flex">
              </div>
            </div>
          </div>
          <!--<div class=" g-2 display-flex" *ngIf="interface.RequestApproverId != null">-->
            <!-- <div class="width-250" style="min-width:120px;"></div> -->
            <!--<div class="row col-md-12 margin-top-bottom-10">
              <div class="col-md-8 display-flex">
                <mat-label class="font-color-darkgrey fw-bolder">
                  Request Approver:
                </mat-label>
                <mat-label class="padding-left-20">{{interface.RequestApprover.FirstName + ' '+ interface.RequestApprover.LastName}}</mat-label>
              </div>
              <div class="col-md-4 display-flex">
              </div>
            </div>
          </div>-->
          <div class=" g-2 display-flex">
            <!-- <div class="width-250" style="min-width:120px;"></div> -->
            <div class="row col-md-12 margin-top-bottom-10">
              <div class="col-md-1 display-flex">
                <mat-label class="font-color-darkgrey fw-bolder">
                  Response:
                </mat-label>
              </div>
              <div class="padding-left-75 col-md-11 min-20-max-100-height overflow-auto enable-line-break">
                {{interface.Status == this.awaitingForResponseApprovalStatusId? '' : interface.Response}}

              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <div class="row " *ngIf="showGridView" style="height:40%;">
    <div class="display-flex">
      <section class="grid-container border-radius-4 mat-elevation-z8" tabindex="0">
        <table mat-table [dataSource]="TQDataSource" class="grid-width" (matSortChange)="sortData($event)" matSort #sortTechnicalQuery="matSort">

          <!-- Actions Column -->
          <ng-container matColumnDef="actions" stickyEnd>
            <th mat-header-cell *matHeaderCellDef aria-label="row actions" class="table_header_size mat-header-cell-register column-border">Actions</th>
            <td mat-cell *matCellDef="let element" class="mat-cell-register column-border">
              <!--<mat-icon>more_vert</mat-icon>-->
              <div class="display-flex">
                <span class="padding-left-right-2">
                  <button class="button-icon-circle align-items-center action-button" [appSetRouteActive]="'Interfaces'"
                          [disabled]="!this.hasPermission('technicalquery.view')"
                          (click)="onView(element.Id)">
                    <mat-icon class="icon-size-20" matTooltip="View">visibility</mat-icon>
                  </button>
                </span>
                <span class="padding-left-right-2">
                  <button class="button-icon-circle align-items-center action-button" [appSetRouteActive]="'Interfaces'" (click)="onEdit(element.Id)"
                          [disabled]="!(element.Status != this.closeStatus && element.Status != this.voidedStatusId && this.hasPermission('technicalquery.edit'))">
                    <mat-icon class="icon-size-20" matTooltip="Edit">edit</mat-icon>
                  </button>
                </span>
                <span class="padding-left-right-2">
                  <button class="button-icon-circle align-items-center action-button" [appSetRouteActive]="'Interfaces'" (click)="onProposedate(element.Id)"
                          [disabled]="!(element.Status == this.awaitingResponseStatusId && this.hasPermission('technicalquery.proposedate') && element.ResponsibleContractorID == this._contractorID )">
                    <mat-icon class="icon-size-20" matTooltip="{{proposeDateLabel}}">edit_calendar</mat-icon>
                  </button>
                </span>
                <span class="padding-left-right-2">
                  <button class="button-icon-circle align-items-center action-button" (click)="confirmCancelDialog(element.Id)"
                          [disabled]="!(element.Status != this.closeStatus && this.hasPermission('technicalquery.delete') && (this._userRoleId == this.superAdminRole || this._userRoleId == this.projectAdminRole))">
                    <mat-icon class="icon-size-20" matTooltip="Delete">delete</mat-icon>
                  </button>
                </span>
                <span class="padding-left-right-2">
                  <button class="button-icon-circle align-items-center action-button" [disabled]="!this.hasPermission('technicalquery.log')" (click)="viewLog(element.Id)" [appSetRouteActive]="'Interfaces'">
                    <mat-icon class="icon-size-20" matTooltip="Log">history</mat-icon>
                  </button>
                </span>
              </div>
            </td>
          </ng-container>

          <!-- Interface Number Column -->
          <ng-container matColumnDef="Number" sticky>
            <th mat-header-cell *matHeaderCellDef class="table_header_size mat-header-cell-register column-border" mat-sort-header> Technical Query Number </th>
            <td mat-cell *matCellDef="let element" class="mat-cell-register column-border">
              #{{element.Number}}
              <div class="display-flex">
                <span>
                  <span *ngIf="element.Prioritys.Category.toUpperCase() === this.HighPriority;else normal">
                    <mat-icon class="icon-size-20 vertical-middle highpriority material-icons-outlined" matTooltip="Priority High">gpp_maybe</mat-icon>
                  </span>
                  <ng-template #normal>
                    <ng-container *ngIf="element.Prioritys.Category.toUpperCase() === this.NormalPriority;else medium">
                      <span>
                        <mat-icon class="icon-size-20 vertical-middle normalpriority material-icons-outlined" matTooltip="Priority Normal">gpp_maybe</mat-icon>
                      </span>
                    </ng-container>
                  </ng-template>
                  <ng-template #medium>
                    <ng-container *ngIf="element.Prioritys.Category.toUpperCase() === this.MediumPriority;else low">
                      <span>
                        <mat-icon class="icon-size-20 vertical-middle mediumpriority material-icons-outlined" matTooltip="Priority Medium">gpp_maybe</mat-icon>
                      </span>
                    </ng-container>
                  </ng-template>
                  <ng-template #low>
                    <span>
                      <mat-icon class="icon-size-20 vertical-middle lowpriority material-icons-outlined" matTooltip="Priority Low">gpp_maybe</mat-icon>
                    </span>
                  </ng-template>
                </span>
                <span *ngIf="element.Documents.length > 0" class="">
                  <button class="button-icon-circle align-items-center action-button" mat-mini-fab color="accent" [appSetRouteActive]="'Interfaces'">
                    <mat-icon matTooltip="Documents present" class="icon-size-20 vertical-middle material-icons-outlined" (click)="onDocumentsPresent(element.Id)">
                      attachment
                    </mat-icon>
                  </button>
                </span>
                <span *ngIf="element.IsAcceptedAsPreliminary" class="padding-left-5">
                  <mat-icon class="icon-size-20 vertical-middle preliminary material-icons-outlined" matTooltip="Accepted as Preliminary">task</mat-icon>
                </span>
                <span *ngIf="element.Revisions.length > 0" class="padding-left-5 action-button">
                  <mat-icon class="icon-size-20 vertical-middle" matTooltip="Revision Created" (click)="onRevisionsPresent(element.Id)">directions_alt</mat-icon>
                </span>
                <span *ngIf="element.IsStageRejected" class="padding-left-5">
                  <mat-icon class="icon-size-20 vertical-middle rejected material-icons-outlined" matTooltip={{element.StageApproveRejectComment}}>keyboard_return</mat-icon>
                </span>
                <span *ngIf="element.MinutesOfMeetingDetails?.length > 0" class="padding-left-5 action-button">
                  <mat-icon class="icon-size-20 vertical-middle material-symbols-outlined" matTooltip="MOM present" (click)="onMinutesOfMeetingPresent(element.Id)">edit_square</mat-icon>
                </span>
              </div>
            </td>
          </ng-container>

          <!-- Rejection comment Column -->
          <ng-container matColumnDef="RejectionComment">
            <th mat-header-cell *matHeaderCellDef class="table_header_size mat-header-cell-register column-border"
              mat-sort-header> Rejection Comment </th>
            <td mat-cell *matCellDef="let element" class="mat-cell-register column-border"> {{element.IsStageRejected?
              element.StageApproveRejectComment : ''}} </td>
          </ng-container>

          <!-- Interface Group Column -->
          <ng-container matColumnDef="InterfaceGroup">
            <th mat-header-cell *matHeaderCellDef class="table_header_size mat-header-cell-register column-border" mat-sort-header> Interface Group </th>
            <td mat-cell *matCellDef="let element" class="mat-cell-register column-border"> {{element.InterfaceGroup?.Name}} </td>
          </ng-container>

          <!-- Provider Discipline Column -->
          <ng-container matColumnDef="discipline">
            <th mat-header-cell *matHeaderCellDef class="table_header_size mat-header-cell-register column-border" mat-sort-header> Discipline </th>
            <td mat-cell *matCellDef="let element" class="mat-cell-register column-border">
              <div *ngIf="this.isDualDisciplinebool">                               
                <div *ngIf="(element.ResDiscipline.Id != element.RecDiscipline.Id)">
                  {{element.RecDiscipline?.Name}}
                </div>
                {{element.ResDiscipline?.Name}}
              </div>
             
              <div *ngIf="!this.isDualDisciplinebool">     
                {{element.RecDiscipline?.Name}}
              </div>  
            </td>
          </ng-container>

          <!-- Title Column -->
          <ng-container matColumnDef="Title">
            <th mat-header-cell *matHeaderCellDef class="table_header_size mat-header-cell-register column-border" mat-sort-header> Title </th>
            <td mat-cell *matCellDef="let element" class="mat-cell-register column-border"> {{element.Title | shorten:70}} </td>
          </ng-container>

          <!-- Description Column -->
          <ng-container matColumnDef="Description">
            <th mat-header-cell *matHeaderCellDef class="table_header_size mat-header-cell-register column-border" mat-sort-header> Description </th>
            <td mat-cell *matCellDef="let element" class="mat-cell-register enable-line-break column-border"> {{element.Description | shorten:100}} </td>
          </ng-container>

          <!-- Requestor Contractor Column -->
          <ng-container matColumnDef="requestorcontractor">
            <th mat-header-cell *matHeaderCellDef class="table_header_size mat-header-cell-register column-border" mat-sort-header> {{requestingContractorLabel}} </th>
            <td mat-cell *matCellDef="let element" class="mat-cell-register column-border">
              <span class=" fst-italic">
                <span class="badge" [ngClass]="getRequestorBadgeColor(element.Status, element.Response)">{{element.RecContractor.Code}}</span>
              </span><span *ngIf="element.RecContractor.Code"> - </span> {{element.RecContractor.Name}}
            </td>
          </ng-container>

          <!-- Provider Contractor Column -->
          <ng-container matColumnDef="providercontractor">
            <th mat-header-cell *matHeaderCellDef class="table_header_size mat-header-cell-register column-border" mat-sort-header> {{providerContractorLabel}} </th>
            <td mat-cell *matCellDef="let element" class="mat-cell-register column-border">
              <span class=" fst-italic  font-color-blue">
                <span class="badge" [ngClass]="getProviderBadgeColor(element.Status, element.Response)">{{element.ResContractor.Code}}</span>
              </span> <span *ngIf="element.ResContractor.Code"> - </span> {{element.ResContractor.Name}}

            </td>
          </ng-container>

          <!-- Status Column -->
          <ng-container matColumnDef="InterfaceStatusName">
            <th mat-header-cell *matHeaderCellDef class="table_header_size mat-header-cell-register column-border" mat-sort-header> Status </th>
            <td mat-cell *matCellDef="let element" class="mat-cell-register column-border">
              <div class="display-flex">
                <div class="padding-right-5 align-self-center">
                  <span [ngClass]="commonFunction.getCardColorOnStatus(element.Status, element.IsApprovedIssuePlannedOverdue,element.IsFirstIssuePlannedOverdue,element.Response)" class="status-dot "></span>
                </div>
                <div class="padding-left-5">
                  {{element.InterfaceStatusName}}
                </div>
              </div>
            </td>
          </ng-container>

          <!-- Query Type Column -->
          <ng-container matColumnDef="querytype">
            <th mat-header-cell *matHeaderCellDef class="table_header_size mat-header-cell-register column-border" mat-sort-header> Query Type </th>
            <td mat-cell *matCellDef="let element" class="mat-cell-register column-border"> {{element.TechnicalQuery.QueryType.Name}} </td>
          </ng-container>

          <!-- Confidential Column -->
          <ng-container matColumnDef="isconfidential">
            <th mat-header-cell *matHeaderCellDef class="table_header_size mat-header-cell-register column-border" mat-sort-header> Confidential </th>
            <td mat-cell *matCellDef="let element" class="mat-cell-register column-border"> {{element.TechnicalQuery.IsConfidential ? "Yes" : "No"}} </td>
          </ng-container>

          <!-- Confidential Justification Column -->
          <ng-container matColumnDef="confidentialjustification">
            <th mat-header-cell *matHeaderCellDef class="table_header_size mat-header-cell-register column-border" mat-sort-header> Confidential Justification </th>
            <td mat-cell *matCellDef="let element" class="mat-cell-register column-border"> {{element.TechnicalQuery.ConfidentialJustification | shorten:70}} </td>
          </ng-container>

          <!-- Priority Column -->
          <ng-container matColumnDef="Prioritys">
            <th mat-header-cell *matHeaderCellDef class="table_header_size mat-header-cell-register column-border" mat-sort-header> Priority </th>
            <td mat-cell *matCellDef="let element" class="mat-cell-register column-border"> {{element.Prioritys.Name}} </td>
          </ng-container>

          <!-- Priority Justification Column -->
          <ng-container matColumnDef="priorityjustification">
            <th mat-header-cell *matHeaderCellDef class="table_header_size mat-header-cell-register column-border" mat-sort-header> Priority Justification </th>
            <td mat-cell *matCellDef="let element" class="mat-cell-register  column-border"> {{element.TechnicalQuery.PriorityJustification | shorten:70}} </td>
          </ng-container>


          <!-- Created Date Column -->
          <ng-container matColumnDef="CreatedDate">
            <th mat-header-cell *matHeaderCellDef class="table_header_size mat-header-cell-register column-border" mat-sort-header> Created Date </th>
            <td mat-cell *matCellDef="let element" class="mat-cell-register column-border"> {{element.CreatedDate | date:dateTimeFormat : utcoffsetvalue}} </td>
          </ng-container>

          <!-- Response Date Column -->
          <ng-container matColumnDef="FirstIssuePlanned">
            <th mat-header-cell *matHeaderCellDef class="table_header_size mat-header-cell-register column-border" mat-sort-header> {{expectedResponseDateLabel}} </th>
            <td mat-cell *matCellDef="let element" class="mat-cell-register column-border"> {{element.FirstIssuePlanned | date:dateTimeFormat }} </td>
          </ng-container>

          <!-- Closeout Date Column -->
          <ng-container matColumnDef="ApprovedIssuePlanned">
            <th mat-header-cell *matHeaderCellDef class="table_header_size mat-header-cell-register column-border" mat-sort-header> {{closeOutDateLabel}} </th>
            <td mat-cell *matCellDef="let element" class="mat-cell-register column-border"> {{element.ApprovedIssuePlanned | date:dateTimeFormat}} </td>
          </ng-container>
          <!-- Actual Response Date Column -->
          <ng-container matColumnDef="FirstIssueActual">
            <th mat-header-cell *matHeaderCellDef class="table_header_size mat-header-cell-register column-border" mat-sort-header> {{responseDateActualLabel}} </th>
            <td mat-cell *matCellDef="let element" class="mat-cell-register column-border">
              <span *ngIf="element.FirstIssueActual != null">
                {{element.Status == this.awaitingForResponseApprovalStatusId? '' : element.FirstIssueActual | date:dateTimeFormat : utcoffsetvalue}}
              </span>
            </td>
          </ng-container>

          <!-- Actual Closeout Date Column -->
          <ng-container matColumnDef="ApprovedIssueActual">
            <th mat-header-cell *matHeaderCellDef class="table_header_size mat-header-cell-register column-border" mat-sort-header> {{closeOutDateActualLabel}} </th>
            <td mat-cell *matCellDef="let element" class="mat-cell-register column-border"> <span *ngIf="element.ApprovedIssueActual != null && element.Status != this.awaitingForCloseOutStatusId">{{element.ApprovedIssueActual | date:dateTimeFormat : utcoffsetvalue}} </span></td>
          </ng-container>

          <!-- Proposed Response Date Column -->
          <ng-container matColumnDef="ProviderFirstIssuePlanned">
            <th mat-header-cell *matHeaderCellDef class="table_header_size mat-header-cell-register column-border" mat-sort-header> Proposed {{expectedResponseDateLabel}} </th>
            <td mat-cell *matCellDef="let element" class="mat-cell-register column-border">
              <span *ngIf="element.ProviderFirstIssuePlanned != null ">
                {{element.ProviderFirstIssuePlanned | date:dateTimeFormat}}
              </span>
            </td>
          </ng-container>

          <!-- Proposed Closeout Date Column -->
          <ng-container matColumnDef="ProviderApprovedIssuePlanned">
            <th mat-header-cell *matHeaderCellDef class="table_header_size mat-header-cell-register column-border" mat-sort-header> Proposed {{closeOutDateLabel}} </th>
            <td mat-cell *matCellDef="let element" class="mat-cell-register column-border">
              <span *ngIf="element.ProviderApprovedIssuePlanned != null">
                {{element.ProviderApprovedIssuePlanned | date:dateTimeFormat}}
              </span>
            </td>
          </ng-container>

          <!-- Action Status Column -->
          <ng-container matColumnDef="ActionStatus">
            <th mat-header-cell *matHeaderCellDef class="table_header_size mat-header-cell-register column-border" mat-sort-header> Action Status </th>
            <td mat-cell *matCellDef="let element" class="mat-cell-register enable-line-break column-border">
              <span [ngClass]="getNextAction(element.Status, element.RecContractor.Name, element.ResContractor.Name, element.FirstIssuePlanned | date:dateTimeFormat ,element.ApprovedIssuePlanned | date:dateTimeFormat)"></span>{{nextActionText}}
            </td>
          </ng-container>

          <!-- Response Column -->
          <ng-container matColumnDef="Response">
            <th mat-header-cell *matHeaderCellDef class="table_header_size mat-header-cell-register column-border" mat-sort-header> Response </th>
            <td mat-cell *matCellDef="let element" class="mat-cell-register enable-line-break column-border">{{element.Status == this.awaitingForResponseApprovalStatusId? '' : element.Response | shorten:100}} </td>
          </ng-container>

          <!-- Assigned To User Column -->
          <ng-container matColumnDef="AssignedToUser">
            <th mat-header-cell *matHeaderCellDef class="table_header_size mat-header-cell-register column-border" mat-sort-header> Assigned To User </th>
            <td mat-cell *matCellDef="let element" class="mat-cell-register enable-line-break column-border">{{(element.AssignmentHistory.length > 0 && element.TechnicalQuery!.RouteToUserId != null) ? element.AssignmentHistory[0].AssignedToUser.FirstName + ' ' + element.AssignmentHistory[0].AssignedToUser.LastName +'- ('+ element.AssignmentHistory[0].AssignedToRole.Name + ')': ''}} </td>
          </ng-container>

          <!-- Request Approver To User Column -->
          <!--<ng-container matColumnDef="RequestApprover">
            <th mat-header-cell *matHeaderCellDef class="table_header_size mat-header-cell-register column-border" mat-sort-header> Request Approver </th>
            <td mat-cell *matCellDef="let element" class="mat-cell-register enable-line-break column-border">{{(element.RequestApproverId != null) ? element.RequestApprover?.FirstName + ' ' + element.RequestApprover?.LastName: ''}} </td>
          </ng-container>-->

          <tr mat-header-row *matHeaderRowDef="registerDisplayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: registerDisplayedColumns;" class="mat-row-register"></tr>
        </table>
      </section>
    </div>
  </div>
  <div class="row">
    <div>
      <mat-paginator #paginatorBottom
                     [length]="length"
                     [pageSize]="pageSize"
                     [pageSizeOptions]="pageSizeOptions"
                     showFirstLastButtons
                     (page)="pageEvent=loadInterfaceRegister($event); paginatorTop.pageIndex = pageIndex"
                     [pageIndex]="pageIndex"
                     aria-label="Select page">
      </mat-paginator>
    </div>
  </div>
</div>
