import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { UserListComponent } from './user-list.component';
import { AppLayoutComponent } from '../app-layout/app-layout.component';
import { PermissionGuard } from '../authorization/permission.guard';
import { MsalGuard } from '@azure/msal-angular';


const userRoutes: Routes = [
  {
    path: '',
     //component: AppLayoutComponent,
    children: [     
      {
        path: 'Users', component: UserListComponent,
        canActivate: [MsalGuard,PermissionGuard], data: { perminssion: 'user.view', title: 'Users' }
      }
    ]
  }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(userRoutes)
  ]
})
export class UserRoutingModule { }
