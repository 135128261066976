import { Component, OnInit, ViewChild } from '@angular/core';
import { PageEvent  } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { CommunicationService } from '../../services/communication.service';
import * as myGlobals from '../../app_data/globals';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogService } from '../../services/dialog.service';
import { MinutesofMeetingService } from 'src/app/services/minutesofmeeting.service';
import { MinutesOfMeeting } from 'src/app/app_data/minutesOfMeeting';
import { InterfaceGroupService } from 'src/app/services/interfaceGroup.service';
import { ProjectSettingService } from 'src/app/services/projectsetting.service';
import { MinutesOfMeetingExcel } from '../../app_data/minutesOfMeetingExcel';
import { CommonFunction } from '../../app_data/commonFunction';
import { ProjectService } from '../../services/project.service';
import { RolePermissionService } from '../../services/rolepermission.service';
import { DatePipe, formatDate } from '@angular/common';
import { ModuleProjectMappingsService } from '../../services/moduleprojectmappings.service';
import { ModuleProjectMapping } from '../../app_data/moduleProjectMapping';
import { Title } from '@angular/platform-browser';

let MoMlists: MinutesOfMeeting[] = [];

@Component({
  selector: 'app-minutesofmeeting-list',
  templateUrl: './minutesofmeeting-list.component.html',
  styleUrls: ['./minutesofmeeting-list.component.css']
})
export class MinutesofmeetingListComponent implements OnInit {

  noRecordFoundMsg: string = myGlobals.NoRecordFound;
  sortColumn = 'NewestFirst';
  dataSource = MoMlists;
  errorMessage = '';
  displayedColumns: string[] = ['Attachments', 'Title', 'GroupName', 'Status', 'ModuleName', 'MeetingDate', 'MeetingLocation', 'NextMeetingLocation', 'NextMeetingDate', 'Contractor1', 'Contractor2','SignedByContractor1', 'SignedByContractor2', 'SignedByContractor1Date', 'SignedByContractor2Date', 'Action'];
  minutesOfMeetingdataSource = new MatTableDataSource<MinutesOfMeeting>(MoMlists);
  minutesOfMeetingdataSourceBottom = new MatTableDataSource<MinutesOfMeeting>(MoMlists);
  @ViewChild('sortcontractor') sortminutesOfMeeting !: MatSort;
  interfaceGroupsIdString: string = "";
  MOMDataSorce = new MatTableDataSource<MinutesOfMeeting>(MoMlists);

  interfaceGroupId: number = 0;
  _projectID = parseInt(sessionStorage.getItem("projectID")!);
  _contractorID = parseInt(sessionStorage.getItem("contractorId")!);
  _userIdLogin = parseInt(sessionStorage.getItem("userId")!);
  _userName = sessionStorage.getItem("userFName") + " " + sessionStorage.getItem("userLName");
  _userRoleId = parseInt(sessionStorage.getItem("roleId")!);
  _projectTimeZone = sessionStorage.getItem("projectTimeZone")!;
  utcoffsetvalue: string = sessionStorage.getItem("projectTimeZone")!.substring(0, sessionStorage.getItem("projectTimeZone")!.lastIndexOf(':'))!;
  _rolePermissions = (sessionStorage.getItem("rolePermissions"))?.toString().split(',');
  dateTimeFormat: string = "";
  pageEvent = new PageEvent;
  isEditVisible: boolean = true;
  length: number = 0;
  pageSize: number = 10;
  pageIndex: number = 0;
  pageSizeOptions: number[] = [10, 20, 50];
  filteredMoM: MinutesOfMeeting[] = [];
  sortColumnList: any = [];
  interfaceGroupString: string = "";
  projectName: string = "";
  searchFilter: string = "";
  allMoMs: MinutesOfMeetingExcel[] = [];
  datePipe: DatePipe = new DatePipe('en-US');
  commonFunction!: CommonFunction;
  moduleProjectMappings: ModuleProjectMapping[] = [];
  activeModulesforCurrentuser: string = "";

  @ViewChild('sortMinutesOfMeeting') sortMinutesOfMeeting !: MatSort;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sortMinutesOfMeeting = ms;
    this.MOMDataSorce.sort = this.sortMinutesOfMeeting;
    this.MOMDataSorce.sortingDataAccessor = this.sortingDataAccessor;
  }


  constructor(private minutesofMeetingService: MinutesofMeetingService, private router: Router, 
    private activatedRoute: ActivatedRoute,  private title: Title,
    private communicationService: CommunicationService
    , private interfaceGroupService: InterfaceGroupService, private projectService: ProjectService, private moduleProjectMappingsService:ModuleProjectMappingsService,
    private projectSettingService: ProjectSettingService, private rolePermissionService: RolePermissionService,
    private logger: NGXLogger, private SpinnerService: NgxSpinnerService, private dialog: DialogService) {
    this.commonFunction = new CommonFunction(router, projectService, rolePermissionService, activatedRoute, title);
  }

  ngOnInit(): void {
    this.commonFunction.setPageTitle();
    this.getProjectSettings();
    this.getModules(this._projectID);
    this.getInterfaceGroups(this._projectID, this._contractorID);
    this.sortColumnList = this.minutesofMeetingService.getSortColumnList();
    this.searchFilters('', true);
  }

  loadMOMData(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.searchFilters(this.sortColumn, false);
    return event;
  }

  sortingDataAccessor(item: any, property: any) {
    try {
      if (property === 'ModuleName') {
        return item.Module?.Name;
      }
      else if (property === 'GroupName') {
        return item.InterfaceGroup.Name;
      }
      else if (property === 'Contractor1') {
        return item.InterfaceGroup?.Contractor1?.Name;
      }
      else if (property === 'Contractor2') {
        return item.InterfaceGroup?.Contractor2?.Name;
      }
      else if (property === 'SignedByContractor1') {
        return `${item.SignedByContractor1User?.FirstName} ${item.SignedByContractor1User?.LastName}`;
      } else if (property === 'SignedByContractor2') {
        return `${item.SignedByContractor2User?.FirstName} ${item.SignedByContractor2User?.LastName}`;
      }
      return item[property];
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }


  getProjectSettings() {
    try {
      this.SpinnerService.show();
      this.projectSettingService.getProjectSettings(this._projectID).subscribe({
        next: projectSettings => {
          if (projectSettings.length > 0) {
            this.projectName = projectSettings[0].Project?.Name!;
            this.dateTimeFormat = projectSettings.filter(x => x.ConfigKey == "DateTimeFormat")[0].ConfigValue;
            var index = projectSettings.findIndex(x => x.ConfigKey == "UTCOffset");
            if (index > -1) {
              var offsetValue = projectSettings.filter(x => x.ConfigKey == "UTCOffset")[0].ConfigValue.split("|")[0]!;
              this.utcoffsetvalue = offsetValue.substring(0, offsetValue.lastIndexOf(':'))!;
            }
          }
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      });
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  getInterfaceGroups(mprojectId: number, contractorId: number) {
    try {
      if (this._userRoleId == Number(myGlobals.ProjectAdminRoleId)) {
        this.interfaceGroupService.getInterfaceGroupsforProjectAdmin(mprojectId).subscribe({
          next: groupData => {
            groupData.forEach(grData => {
              if (this.interfaceGroupsIdString == "") {
                this.interfaceGroupsIdString = grData.Id?.toString()!;
              }
              else {
                this.interfaceGroupsIdString += "," + grData.Id?.toString();
              }
            })

            this.getMeeniutsofMeetings(this._projectID, this.interfaceGroupsIdString);
            this.SpinnerService.hide();
          },
          error: err => {
            this.SpinnerService.hide();
            this.errorMessage = err
            this.dialog.confirmDialog({
              title: 'Error',
              message: myGlobals.exceptionmessage,
              cancelCaption: 'Close',
            });
            this.logger.error(err);
          }
        })
      } else {
        this.interfaceGroupService.getInterfaceGroupsByProjectId(mprojectId, contractorId).subscribe({
          next: interfaceGroup => {
            var groupData = interfaceGroup;
            groupData.forEach(grData => {
              if (this.interfaceGroupsIdString == "") {
                this.interfaceGroupsIdString = grData.Id?.toString()!;
              }
              else {
                this.interfaceGroupsIdString += "," + grData.Id?.toString();
              }
            })
            this.getMeeniutsofMeetings(this._projectID, this.interfaceGroupsIdString);
            this.SpinnerService.hide();
          },
          error: err => {
            this.SpinnerService.hide();
            this.errorMessage = err
            this.dialog.confirmDialog({
              title: 'Error',
              message: myGlobals.exceptionmessage,
              cancelCaption: 'Close',
            });
            this.logger.error(err);
          }
        })
      }
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  getMeeniutsofMeetings(projectID: number, interfaceGroupsIds: string) {
    try {
      this.SpinnerService.show();
      this.minutesofMeetingService.getMinutesofMeeting(projectID, interfaceGroupsIds).subscribe(
        {
          next: allminutesOfMeetings => {
            this.minutesOfMeetingdataSource.data = allminutesOfMeetings;
            this.minutesOfMeetingdataSourceBottom.data = allminutesOfMeetings;
            this.SpinnerService.hide();
          },
          error: err => {
            this.SpinnerService.hide();
            this.errorMessage = err
            this.dialog.confirmDialog({
              title: 'Error',
              message: myGlobals.exceptionmessage,
              cancelCaption: 'Close',
            });
            this.logger.error(err);
          }
        }
      )
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  redirecttoadd() {
    this.router.navigateByUrl('/MinutesofMeetingCreate');
  }

  editRecord(Id: number) {
    let EditUrl = "MinutesofMeetingEdit/" + Id;
    this.router.navigateByUrl(EditUrl);
  }

  onDocumentsPresent(Id: number) {
    try {
      this.SpinnerService.show();
      this.communicationService._isDocumentPresent = true;
      let MomViewUrl = "MinutesofMeetingView/" + Id;
      this.router.navigateByUrl(MomViewUrl);
      this.SpinnerService.hide();
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  viewInterface(Id: Number) {
    try {
      this.SpinnerService.show();
      this.communicationService._isInterfacePresent = true;
      let MomViewUrl = "MinutesofMeetingView/" + Id;
      this.router.navigateByUrl(MomViewUrl);
      this.SpinnerService.hide();
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  viewActionItem(Id: Number) {
    try {
      this.SpinnerService.show();
      this.communicationService._isActionItemPresent = true;
      let MomViewUrl = "MinutesofMeetingView/" + Id;
      this.router.navigateByUrl(MomViewUrl);
      this.SpinnerService.hide();
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  viewRecord(Id: number) {
    let ViewUrl = "MinutesofMeetingView/" + Id;
    this.router.navigateByUrl(ViewUrl);
  }


  onSortChange(sortSelection: string): void {
    try {
      this.sortColumn = sortSelection;
      this.searchFilters(sortSelection, true);
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  searchFilters(sortSelection: string, isShow1stPage: boolean) {
    if (isShow1stPage) {
      this.pageIndex = 0;
    }

    var advFilter = "";
    if (this._userRoleId == Number(myGlobals.ProjectAdminRoleId)) {
      advFilter = "&$filter=ProjectId eq " + this._projectID + " and ModuleId in (" + this.activeModulesforCurrentuser +") and IsDeleted eq false";
      switch (sortSelection.toLowerCase()) {
        case 'oldestfirst':
          advFilter += "&$orderby=Id";
          break;
        default:
          advFilter += "&$orderby=Id desc";
          break;
      }
      this.fillMinutesOfMeeting(this.pageIndex, this.pageSize, advFilter);
      this.searchFilter = advFilter;
    }
    else {
      this.interfaceGroupService.getInterfaceGroupsByProjectId(this._projectID, this._contractorID).subscribe({
        next: interfaceGroup => {
          var groupData = interfaceGroup;
          groupData.forEach(grData => {
            if (this.interfaceGroupString == "") {
              this.interfaceGroupString = grData.Id?.toString()!;
            }
            else {
              this.interfaceGroupString += "," + grData.Id?.toString();
            }
          })
          advFilter = "&$filter=ProjectId eq  " + this._projectID + " and IsDeleted eq false and ModuleId in ("+this.activeModulesforCurrentuser+") and InterfaceGroupID in (" + this.interfaceGroupString + ")";

          switch (sortSelection.toLowerCase()) {
            case 'oldestfirst':
              advFilter += "&$orderby=Id";
              break;
            default:
              advFilter += "&$orderby=Id desc";
              break;
          }
          this.fillMinutesOfMeeting(this.pageIndex, this.pageSize, advFilter);
          this.searchFilter = advFilter;
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
     
    }
  }

  getModules(projectId: number) {
    try {
      this.SpinnerService.show();
      this.moduleProjectMappingsService.getModulesByProjectId(projectId).subscribe({
        next: moduleProjectMappings => {
          moduleProjectMappings.forEach(x => {
            var a = x.Module?.ModulePermissionMappings?.filter(v => v.IsProjectWise)
            var permissionindex = this._rolePermissions!.some(s => s.includes(a![0].Permission?.Name!));
            if (!permissionindex) {
              x.IsDeleted = true;
            }
          });
          this.moduleProjectMappings = moduleProjectMappings.filter(x => !x.IsDeleted);
          let defaultModule = moduleProjectMappings.filter(x => !x.IsDeleted);
          defaultModule.forEach(x => {
            if (this.activeModulesforCurrentuser == "") {
              this.activeModulesforCurrentuser = x.ModuleId.toString();
            } else {
              this.activeModulesforCurrentuser +=","+ x.ModuleId.toString();
            }
          })
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  fillMinutesOfMeeting(pageIndex: number, pageSize: number, filter: string): void {
    try {
      this.SpinnerService.show();
      this.minutesofMeetingService.getAllMinutesOfMeeting(pageIndex, pageSize,filter).subscribe({
        next: MoMs => {
          this.SpinnerService.hide();
          this.MOMDataSorce.data = MoMs.filter(x => !x.IsDeleted);
          this.filteredMoM = MoMs.filter(x => !x.IsDeleted);
          this.length = MoMs.filter(x => !x.IsDeleted).length;
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err;
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }

      });
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  exportExcel() {
    try {
      this.SpinnerService.show();
      this.allMoMs.length = 0;
      this.minutesofMeetingService.getAllMoMs(this.searchFilter).subscribe({
        next: moms => {
          //Bind interfaces for Excel
          moms.forEach((momRow) => {
            let momRec = new MinutesOfMeetingExcel();
            
            momRec.Title = momRow.Title!;
            momRec.InterfaceGroup = momRow.InterfaceGroup?.Name!;
            momRec.Module = momRow.Module?.Name!;
            momRec.MeetingDescription = momRow.MeetingDescription;
            momRec.MeetingLocation = momRow.MeetingLocation;
            momRec.MeetingDate = momRow.MeetingDate != null ? this.datePipe.transform(momRow.MeetingDate, this.dateTimeFormat)! : "";
            momRec.NextMeetingDate = momRow.NextMeetingDate != null ? this.datePipe.transform(momRow.NextMeetingDate, this.dateTimeFormat)! : "";
            momRec.NextMeetingLocation = momRow.NextMeetingLocation;
            momRec.Contractor1 = momRow.InterfaceGroup?.Contractor1.Name;
            momRec.Contractor2 = momRow.InterfaceGroup?.Contractor2.Name;
            momRec.SignedByContractor1User = momRow.IsSignedByContractor1 ? momRow.SignedByContractor1User?.FirstName + " " + momRow.SignedByContractor1User?.LastName : "";
            momRec.SignedByContractor2User = momRow.IsSignedByContractor2 ? momRow.SignedByContractor2User?.FirstName + " " + momRow.SignedByContractor2User?.LastName : "";
            momRec.SignedByContractor1Date = momRow.SignedByContractor1Date != null ? this.datePipe.transform(momRow.SignedByContractor1Date, this.dateTimeFormat)! : "";
            momRec.SignedByContractor2Date = momRow.SignedByContractor2Date != null ? this.datePipe.transform(momRow.SignedByContractor2Date, this.dateTimeFormat)! : "";
            momRec.Status = momRow.Status;
            momRec.CreatedOn = formatDate(momRow.CreatedDate!, "medium", "en-US", this.utcoffsetvalue);
            momRec.CreatedBy = momRow.CreatedUserRoleMapping?.User.FirstName + " " + momRow.CreatedUserRoleMapping?.User.LastName;

            this.allMoMs.push(momRec);
          })

          let Heading = [['Title', 'Interface Group', 'Module', 'Meeting Description', 'Meeting Location', 'Meeting Date', 'Next Meeting Date', 'Next Meeting Location',
            'Contractor 1', 'Contractor 2', 'Signed By Contractor 1 User', 'Signed By Contractor 1 Date', 'Signed By Contractor 2 User', 'Signed By Contractor 2 Date',
            'Status', 'Created On', 'Created By']];

          this.commonFunction.exportToExcel(this.allMoMs, Heading, this.projectName, "MinutesofMeetings");
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  hasPermission(permissionName: string) {
    return this._rolePermissions!.some(s => s.includes(permissionName));
  }
}
