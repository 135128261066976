import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { response } from '../app_data/OdataResponse';
import { UserRoleProject } from '../app_data/userroleproject';

const httpOptions = {
    headers: new HttpHeaders({
  
      "Content-Type": "application/json",
      "Accept": "*/*",
    }
    )
  }

  @Injectable({
    providedIn: 'root'
  })

  
export class UserRoleProjectService {
    private apiUrl = environment.apiURl + 'userroleprojects';
    private apiUrlProjects = environment.apiURl + 'projects';
  
    constructor(private http: HttpClient) {
    }
  
    getUserRoleProjects(userid: number): Observable<UserRoleProject[]> {
      return this.http.get<response>(this.apiUrl + "?$expand=Role,Project,User,Contractor&$filter=UserId eq " + userid, httpOptions)
        .pipe(
          map((data: response) => data.value),
          catchError(this.handleError)
        );
    }

    getUserRoleByContractorandroleId(userid: number, contractorId: number, projectId: number,roleId:number): Observable<UserRoleProject[]> {
      return this.http.get<response>(this.apiUrl + "?$filter=ProjectId eq "+ projectId +" and RoleId eq "+ roleId +" and ContractorId eq "+ contractorId +" and IsDeleted eq false and UserId eq " + userid, httpOptions)
        .pipe(
          map((data: response) => data.value),
          catchError(this.handleError)
        );
    }

    getUserRoleByContractorIdandRoleIds(contractorId: number, roleId: string): Observable<UserRoleProject[]> {
      return this.http.get<response>(this.apiUrl + "?$expand=Role,Project,User,Contractor&$filter=ContractorId eq " + contractorId + " and RoleId in (" + roleId+") and IsDeleted eq false ", httpOptions)
        .pipe(
          map((data: response) => data.value),
          catchError(this.handleError)
        );
    }

    getUserRoleByContractorId(contractorId: number): Observable<UserRoleProject[]> {
      return this.http.get<response>(this.apiUrl + "?$expand=Role,Project,User,Contractor&$filter=ContractorId eq " + contractorId + " and IsDeleted eq false ", httpOptions)
        .pipe(
          map((data: response) => data.value),
          catchError(this.handleError)
        );
    }

    getAllUsersByProject(projectId: number): Observable<UserRoleProject[]> {
      return this.http.get<response>(this.apiUrl + "?$expand=Role,Project,Contractor,User($filter=isdeleted eq false;$expand=UserContractorMappings($expand=Contractor_User),UserRoleProjects)&$filter=ProjectId  eq " + projectId + " and IsDeleted eq false", httpOptions)
        .pipe(
          map((data: response) => data.value),
          catchError(this.handleError)
        );
    }

    getProjectsByUserId(userId: number): Observable<UserRoleProject[]> {
      return this.http.get<UserRoleProject[]>(this.apiUrl + "?$apply=filter(UserId eq "+ userId +" and IsDeleted eq false )/groupby((ProjectId))", httpOptions)
        .pipe(
          map((data: UserRoleProject[]) => data),
          catchError(this.handleError)
        );
    }

    put(model: UserRoleProject): Observable<UserRoleProject> {
      return this.http.put<UserRoleProject>(this.apiUrl + "/" + model.Id, model, httpOptions);
    }
    
    private handleError(err: HttpErrorResponse) {
      let errorMessage = '';
      if (err.error instanceof ErrorEvent) {
  
        errorMessage = `An error occurred: ${err.error.message}`;
      } else {
  
        errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
      }
      return throwError(errorMessage);
    }
  }
