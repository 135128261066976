import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup} from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { InterfaceGroup } from 'src/app/app_data/interfaceGroup';
import { DialogService } from 'src/app/services/dialog.service';
import { InterfaceGroupService } from 'src/app/services/interfaceGroup.service';
import * as myGlobals from '../../app_data/globals';
import { NGXLogger } from 'ngx-logger';
import { ViewChild } from '@angular/core';
import { User } from 'src/app/app_data/user';
import { InterfaceService } from 'src/app/services/interface.service';
import { Interface } from 'src/app/app_data/interface';
import { MatSelect } from '@angular/material/select';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { RiskCategories } from "../../app_data/RiskCategories";
import { RiskCategoriesService } from 'src/app/services/riskcategories.service';
import { Risk } from '../../app_data/risk';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RiskService } from '../../services/risk.service';
import { FilesService } from '../../services/files.service';
import { File } from '../../app_data/file';
import { SnackbarComponent } from '../../shared/components/snackbar/snackbar.component';
import { Document } from '../../app_data/document';
import { ProjectSettingService } from '../../services/projectsetting.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommunicationService } from '../../services/communication.service';
import { DocumentService } from '../../services/document.service';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { ModuleProjectMappingsService } from 'src/app/services/moduleprojectmappings.service';
import { ModuleProjectMapping } from 'src/app/app_data/moduleProjectMapping';
import { CommonFunction } from '../../app_data/commonFunction';
import { ProjectService } from '../../services/project.service';
import { RolePermissionService } from '../../services/rolepermission.service';
import { Title } from '@angular/platform-browser';
let UploadDocuments: File[] = [];
let MY_FORMATS = {
  parse: {
    dateInput: "LL"
  },
  display: {
    dateInput: "LL",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY"
  }
};

@Component({
  templateUrl: './risk-edit.component.html',
  styleUrls: ['./risk-edit.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    { provide: MAT_DATE_LOCALE, useValue: 'en-US' },

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class RiskEditComponent implements OnInit {
  pageTitle = 'Edit Risk';
  selectedRiskCategoryId: number = 0;
  selectedConsequenceLevel: number = 0;
  selectedProbabilityLevel: number = 0;
  interfaceNumber!: string;
  riskCategories: RiskCategories[] = [];
  probabilityLevel: any;
  consequenceLevel: any;
  form!: FormGroup;
  riskCategoriesModel !: RiskCategories;
  errorMessage = '';
  selectedInterface!: any;
  contractorIds: number[] = [];
  interfaceGroups: InterfaceGroup[] = [];
  _projectID = parseInt(sessionStorage.getItem("projectID")!);
  _contractorID = parseInt(sessionStorage.getItem("contractorId")!);
  _userRoleId = parseInt(sessionStorage.getItem("roleId")!);
  _userIdLogin = parseInt(sessionStorage.getItem("userId")!);
  selectedInterfaceGroup: number = 0;
  selectedModuleId: number = 0;
  selectedModule: string = "";
  documentCount: string = "";
  allSelected = false;
  dateTimeFormat: string = "";
  isDualDisciplinebool: boolean = false;
  expectedResponseDateLabel: string = myGlobals.ExpectedResponseDateLabel;
  closeOutDateLabel: string = myGlobals.CloseOutDateLabel;
  responseDateActualLabel: string = myGlobals.ResponseDateActualLabel;
  closeOutDateActualLabel: string = myGlobals.CloseOutDateActualLabel;
  datePickerConfig: Partial<BsDatepickerConfig> = {};
  riskModel !: Risk;
  isEdit = false;
  tabModuleTypeName: string = "Interface";
  interfaceListData: Interface[] = [];
  contractor1Id = 0;
  contractor2Id = 0;
  IsAppeovedIssuePlannedOverdue: boolean = false;
  isAddRiskOnInterface: boolean = false;
  interfaceTitle!: string;
  interfaceDescription !: string;
  interfaceGroupName: string = "";
  interfaceStatus: number = 0;
  contractor1Name: string = "Contractor1";
  contractor2Name: string = "Contractor2";
  riskTitle: string = "";
  riskDiscription: string = "";
  allRequestingUsers: User[] = [];
  allResponsibleUsers: User[] = [];
  filteredInterface !: Interface;
  statusShortName: string = "";
  utcoffsetvalue: string = "";
  isInterfaceAddedToRisk: boolean = false;
  maxFileSize: string = "";
  approvedIssueActual!: Date;
  firstIssueActual !: Date;
  interfaceCreatedDate !: Date;
  isAddInterfaceOnRisk: boolean = false;
  approvedIssuePlanned !: Date;
  firstIssuePlanned !: Date;
  dpColorTheme: string = "theme-dark-blue";
  isInterfacePresent: boolean = false;
  isDisabledInterfaceGroup: boolean = false;
  isDisabledModule: boolean = false;
  riskId !: number;
  validRecord: boolean = false;
  riskStatusList: any;
  isProjectAdminRole: boolean = false;
  @ViewChild('InterfacesList') select!: MatSelect;
  moduleProjectMappings: ModuleProjectMapping[] = [];
  commonFunction!: CommonFunction;

  constructor(private riskCategoriesService: RiskCategoriesService, private logger: NGXLogger, private SpinnerService: NgxSpinnerService, private dialog: DialogService,
    private interfaceGroupService: InterfaceGroupService, private moduleProjectMappingsService: ModuleProjectMappingsService,
    private interfaceService: InterfaceService,
    private activatedRoute: ActivatedRoute,  private title: Title,
    private projectService: ProjectService, private rolePermissionService: RolePermissionService,
    private riskService: RiskService, private filesService: FilesService, private snackBar: MatSnackBar, private projectSettingService: ProjectSettingService,
    private route: ActivatedRoute, private router: Router, private communicationService: CommunicationService, private documentService: DocumentService) {
    this.riskCategoriesModel = new RiskCategories();
    this.riskModel = new Risk();
    this.commonFunction = new CommonFunction(router, projectService, rolePermissionService,activatedRoute, title);

  }
  ngOnInit(): void {
    this.commonFunction.setPageTitle();
    this.riskId = this.route.snapshot.params['id']
    if (this._userRoleId == Number(myGlobals.ProjectAdminRoleId)) {
      this.isProjectAdminRole = true;
    } else {
      this.isProjectAdminRole = false;
    }
    this.checkIfRiskIdValid(this.riskId);
    this.isAddRiskOnInterface = this.riskService.isAddRiskToInterface;
    this.riskService.isAddRiskToInterface = false;
    this.fillRiskCategoriesDDL(this._projectID);
    this.fillInterfaceGroupDDL(this._projectID, this._contractorID);
    this.getProjectSettings();
    this.getModules(this._projectID);
    this.fillProbabilityLevels();
    this.fillConsequenceLevels();

    this.form = new FormGroup({
      categoriesFormControl: new FormControl(),
      interfaceGroupFormControl: new FormControl(),
      interfacesFormControl: new FormControl,
      titleFormControl: new FormControl(),
      descriptionFormControl: new FormControl(),
      causeFormControl: new FormControl(),
      probabilityLevelFormControl: new FormControl(),
      consequenceLevelFormControl: new FormControl(),
      goalFormControl: new FormControl(),
      mitigationFormControl: new FormControl(),
      deadlineDateFormControl: new FormControl(),
      moduleFormControl: new FormControl(),
      responsibleFormControl: new FormControl(),
      discilpineFormControl: new FormControl()

    });
  }


  fillInterfaceGroupDDL(mprojectId: number, contractorId: number): void {
    try {
      this.SpinnerService.show();
      this.interfaceGroups = [];
      if (this._userRoleId == Number(myGlobals.ProjectAdminRoleId)) {
        this.interfaceGroupService.getInterfaceGroupsforProjectAdmin(mprojectId).subscribe({
          next: interfaceGroup => {
            this.interfaceGroups = interfaceGroup;
            this.SpinnerService.hide();
          },
          error: err => {
            this.SpinnerService.hide();
            this.errorMessage = err
            this.dialog.confirmDialog({
              title: 'Error',
              message: myGlobals.exceptionmessage,
              cancelCaption: 'Close',
            });
            this.logger.error(err);
          }
        })
      } else {
        this.interfaceGroupService.getInterfaceGroupsByProjectId(mprojectId, contractorId).subscribe({
          next: interfaceGroup => {
            this.interfaceGroups = interfaceGroup;
            this.SpinnerService.hide();
          },
          error: err => {
            this.SpinnerService.hide();
            this.errorMessage = err
            this.dialog.confirmDialog({
              title: 'Error',
              message: myGlobals.exceptionmessage,
              cancelCaption: 'Close',
            });
            this.logger.error(err);
          }
        })
      }
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }


  generateRisk() {
    try {
      var interfaceGroupData = this.interfaceGroups.filter(x => x.Id == this.selectedInterfaceGroup);
      if (interfaceGroupData.length > 0) {
        this.contractor1Id = interfaceGroupData[0].Contractor1ID;
        this.contractor2Id = interfaceGroupData[0].Contractor2ID;
        this.interfaceGroupName = interfaceGroupData[0].Name;

        this.contractor1Name = interfaceGroupData[0].Contractor1.Name;
        this.contractor2Name = interfaceGroupData[0].Contractor2.Name;
        this.selectedInterface = [];
        this.allSelected = false;
      }
    }
    catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  getRiskDataById(riskModel: Risk) {
    try {
      this.SpinnerService.show();
      
          if (riskModel.Interfaces) {
            this.isAddInterfaceOnRisk = true;
          }
          this.selectedModuleId = riskModel.ModuleId!;
          this.selectedModule = riskModel.Module?.Name == "Interface" ? "" : riskModel.Module?.Name!;
          this.tabModuleTypeName = riskModel.Module?.Name!;
          this.selectedInterfaceGroup = riskModel.InterfaceGroupID!;
          this.selectedRiskCategoryId = riskModel.CategoryId!;
          this.selectedConsequenceLevel = riskModel.ConsequenceId!;
          this.selectedProbabilityLevel = riskModel.ProbabilityId!;
          this.selectedInterfaceGroup = riskModel.InterfaceGroupID!;
          this.selectedInterface = riskModel.InterfaceId!;
          this.communicationService.setFilePathName(riskModel.FilePathName);
          this.communicationService.setDocumentsdata(riskModel.Documents?.filter(x => !x.IsDeleted));
          this.documentCount = riskModel.Documents != null && riskModel.Documents.length > 0 ? riskModel.Documents.length.toString() : "0"; 
          this.isDisabledInterfaceGroup = true;
          this.isDisabledModule = true;
          this.SpinnerService.hide();
    }
    catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  trackByIndex(index: number, obj: any): any {
    return index;
  }

  fillRiskCategoriesDDL(projectId: number) {
    try {
      this.SpinnerService.show();
      this.riskCategoriesService.getRiskCategories().subscribe({
        next: categories => {
          this.riskCategories = categories;
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }
  fillInterfacesOnInterfaceGrp() {
    this.interfaceService.getAllInterfacesByInterfaceGroupIdMOM(this._projectID, this.selectedInterfaceGroup, this.selectedModule).subscribe({
      next: data => {

        if (data.length > 0) {
          this.interfaceListData = data;
        } else {
          this.interfaceListData = [];
        }
        this.SpinnerService.hide();
      },
      error: e => {
        this.SpinnerService.hide();
        this.dialog.confirmDialog({
          title: 'Error',
          message: myGlobals.exceptionmessage,
          cancelCaption: 'Close',
        });
        this.logger.error(e);
      }
    });
  }


  fillProbabilityLevels() {
    try {
      this.SpinnerService.show();
      this.probabilityLevel = this.riskService.probabilityLevels;
      this.SpinnerService.hide();
     
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }


  fillConsequenceLevels() {
    try {
      this.SpinnerService.show();
      this.consequenceLevel = this.riskService.consequenceLevels;
      this.SpinnerService.hide();
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }



  getModules(projectId: number) {
    try {
      this.SpinnerService.show();
      this.moduleProjectMappingsService.getModulesByProjectId(projectId).subscribe({
        next: moduleProjectMappings => {
          this.moduleProjectMappings = moduleProjectMappings;
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  documentCountaddedHandler(docCount: string) {
    this.documentCount = docCount;
  }

  documentsaddedHandler(documents: Document[] = []) {
    this.riskModel.Documents = documents;
  }

  uploadDocsaddedHandler(files: File[] = []) {
    UploadDocuments = files;
  }

  updateDocumentUploadStatus(documentData: Document) {
    try {
      if (documentData != null) {
        documentData.Status = 2; //Document status set tp completed        
        this.documentService.put(documentData).subscribe({
          next: data => {
            this.SpinnerService.hide();
          },
          error: err => {
            this.SpinnerService.hide();
            this.errorMessage = err
            this.dialog.confirmDialog({
              title: 'Error',
              message: myGlobals.exceptionmessage,
              cancelCaption: 'Close',
            });
            this.logger.error(err);
          }
        });
      }
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }


  uploadDocuments(uploadDataList: File[], riskData: Risk, documentData: Document[]) {
    try {
      this.SpinnerService.show();
      if (uploadDataList != null) {
        uploadDataList.forEach(uploadData => {
          var docUploadData = documentData.filter(x => x.FileNameSaved == uploadData.FileName && x.Status == Number(myGlobals.DocumentUploadStatusId));
          if (docUploadData != null && docUploadData.length > 0) {
            docUploadData[0].MomId = riskData.Id;
            uploadData.FileName = "Proj_" + riskData.ProjectId + "/InterfaceGroup_" + riskData.InterfaceGroupID + "/Risk_" + riskData.Id + "/" + uploadData.FileName;
            uploadData.DestinationStorageDetail!.FilePath = "Proj_" + riskData.ProjectId + "/InterfaceGroup_" + riskData.InterfaceGroupID + "/Risk_" + riskData.Id + "/";
            this.filesService.UploadFiles(uploadData).subscribe({
              next: data => {
                this.updateDocumentUploadStatus(docUploadData[0]);
                this.SpinnerService.hide();
              },
              error: err => {
                this.SpinnerService.hide();
                this.errorMessage = err
                this.dialog.confirmDialog({
                  title: 'Error',
                  message: myGlobals.exceptionmessage,
                  cancelCaption: 'Close',
                });
                this.logger.error(err);
              }
            })
          }
        });
      }
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  save() {
    try {
      this.SpinnerService.show();
      var interfaceGroupData = this.interfaceGroups.filter(x => x.Id == this.selectedInterfaceGroup);
      if (interfaceGroupData.length > 0) {
        this.contractor1Id = interfaceGroupData[0].Contractor1ID;
        this.contractor2Id = interfaceGroupData[0].Contractor2ID;
        this.interfaceGroupName = interfaceGroupData[0].Name;

        this.contractor1Name = interfaceGroupData[0].Contractor1.Name;
        this.contractor2Name = interfaceGroupData[0].Contractor2.Name;
        this.selectedInterface = [];
      }

      if (this.riskModel.Documents?.length > 0) {
        this.riskModel.Documents.forEach(docs => {
          if (docs.RiskId == null) {
            docs.MomId = 0;
            docs.InterfaceID = null!;
            docs.DocumentTypeID = Number(myGlobals.GeneralDocumentTypeId);
          }
        })
      }
      this.riskService.put(this.riskModel).subscribe({
        next: data => {
          this.SpinnerService.hide();
          if (UploadDocuments != null) {
            this.uploadDocuments(UploadDocuments, this.riskModel, this.riskModel.Documents);
          }
          this.redirectToBackPage();

        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }

  }

  redirectToBackPage() {
    this.router.navigateByUrl('/Risk');
  }

  setStatusName(status: number) {
    this.riskStatusList = this.riskService.getRiskStatus().filter(x => x.Id == status);
    this.riskModel.StatusName = this.riskStatusList[0].Name;
  }

  closeRisk(): void {
    try {
      this.SpinnerService.show();
      this.riskModel.Status = 2;
      this.setStatusName(this.riskModel.Status);
      this.save();
      this.SpinnerService.hide();
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Risk Closed successfully!",
        duration: myGlobals.snackBarDuration,
        verticalPosition: myGlobals.snackBarVerticalPosition,
        horizontalPosition: myGlobals.snackBarHorizontalPosition
      });
    }
    catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }
  getProjectSettings() {
    try {
      this.SpinnerService.show();
      this.projectSettingService.getProjectSettings(this._projectID).subscribe({
        next: projectSettings => {
          if (projectSettings.length > 0) {
            this.dateTimeFormat = projectSettings.filter(x => x.ConfigKey == "DateTimeFormat")[0].ConfigValue;
            MY_FORMATS.parse.dateInput = this.dateTimeFormat.replace("dd", "DD");
            MY_FORMATS.display.dateInput = this.dateTimeFormat.replace("dd", "DD");
            MY_FORMATS.display.dateA11yLabel = this.dateTimeFormat.replace("dd", "DD");
            var index = projectSettings.findIndex(x => x.ConfigKey == "UTCOffset");
            if (index > -1) {
              var offsetValue = projectSettings.filter(x => x.ConfigKey == "UTCOffset")[0].ConfigValue.split("|")[0]!;
              this.utcoffsetvalue = offsetValue.substring(0, offsetValue.lastIndexOf(':'))!;
            }

            var index = projectSettings.findIndex(x => x.ConfigKey == "IsCustomWIRDefination");
            if (index > -1) {
              var settingvalue = projectSettings.filter(x => x.ConfigKey == "IsCustomWIRDefination")[0].ConfigValue;
              if (settingvalue == "true") {

                var index = projectSettings.findIndex(x => x.ConfigKey == "ExpectedResponseDateName");
                if (index > -1) {
                  this.expectedResponseDateLabel = projectSettings.filter(x => x.ConfigKey == "ExpectedResponseDateName")[0].ConfigValue;
                }
                var index = projectSettings.findIndex(x => x.ConfigKey == "CloseOutDateName");
                if (index > -1) {
                  this.closeOutDateLabel = projectSettings.filter(x => x.ConfigKey == "CloseOutDateName")[0].ConfigValue;
                }

                var index = projectSettings.findIndex(x => x.ConfigKey == "ResponseDateActualName");
                if (index > -1) {
                  this.responseDateActualLabel = projectSettings.filter(x => x.ConfigKey == "ResponseDateActualName")[0].ConfigValue;
                }
                var index = projectSettings.findIndex(x => x.ConfigKey == "CloseOutDateActualName");
                if (index > -1) {
                  this.closeOutDateActualLabel = projectSettings.filter(x => x.ConfigKey == "CloseOutDateActualName")[0].ConfigValue;
                }

              }
            }

            var index = projectSettings.findIndex(x => x.ConfigKey == "IsDualDiscipline");
            if (index > -1) {
              var IsDualDisciplinebool = projectSettings.filter(x => x.ConfigKey == "IsDualDiscipline")[0].ConfigValue;
              if (IsDualDisciplinebool == "true") {
                this.isDualDisciplinebool = true;
              }
            }

            var index = projectSettings.findIndex(x => x.ConfigKey == "IsEnableDocumentUploadFileSize");
            if (index > -1) {
              var DefMaxFileSizebool = projectSettings.filter(x => x.ConfigKey == "IsEnableDocumentUploadFileSize")[0].ConfigValue;
              if (DefMaxFileSizebool == "true") {
                var index = projectSettings.findIndex(x => x.ConfigKey == "DocumentUploadFileSize");
                if (index > -1) {
                  this.maxFileSize = projectSettings.filter(x => x.ConfigKey == "DocumentUploadFileSize")[0].ConfigValue;
                }
              }
            }

            this.datePickerConfig = Object.assign({}, {
              containerClass: this.dpColorTheme,
              showWeekNumbers: false,
              dateInputFormat: this.dateTimeFormat.toUpperCase(),
              rangeInputFormat: this.dateTimeFormat.toUpperCase()
            });
            this.SpinnerService.hide();
          }
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      });
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  checkIfRiskIdValid(riskId: number) {
    try {
      this.SpinnerService.show();
      this.riskService.getRiskByIdandPrjectId(riskId, this._projectID).subscribe({
        next: data => {
          this.validRecord = data.length > 0 ? true : false;
          if (this.riskId > 0 && this.validRecord) {
            this.isEdit = true;
            this.riskModel = data[0];
            this.getRiskDataById(this.riskModel);
          }
          else {
            this.isEdit = false;
            this.router.navigateByUrl("/AccessDenied");
          }
        }
      });
    }
    catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  onView(interfaceId: number) {
    try {
      this.SpinnerService.show();
      this.communicationService._isView = true;
      this.communicationService._interfaceId = interfaceId;
      let InterfaceViewUrl = this.riskModel.Module?.Name == "Interface" ? "InterfaceView/" + interfaceId : "TechnicalQueryView/" + interfaceId;
      this.router.navigateByUrl(InterfaceViewUrl);
      this.SpinnerService.hide();
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }



}
