import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterList } from '../../app_data/filterlist';
import { CommunicationService } from '../../services/communication.service';
import * as myGlobals from '../../app_data/globals';
import { NgxSpinnerService } from 'ngx-spinner';
import { NGXLogger } from 'ngx-logger';
import { ActionItemService } from '../../services/actionItem.service';
import { ActionItem } from '../../app_data/actionItem';
import { DialogService } from '../../services/dialog.service';
import { PageEvent } from '@angular/material/paginator';
import { SortColumnService } from '../../services/sortcolumn.service';
import * as XLSX from 'xlsx';
import { ActionItemExcel } from '../../app_data/actionItemExcel';
import { DatePipe } from '@angular/common';
import { SnackbarComponent } from '../../shared/components/snackbar/snackbar.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { InterfaceGroupService } from '../../services/interfaceGroup.service';
import { CommonFunction } from 'src/app/app_data/commonFunction';
import { ProjectService } from 'src/app/services/project.service';
import { RolePermissionService } from 'src/app/services/rolepermission.service';
import { Title } from '@angular/platform-browser';

let ActionItemlists: ActionItem[] = [];

@Component({
  selector: 'app-actionlist-list',
  templateUrl: './actionlist-list.component.html',
  styleUrls: ['./actionlist-list.component.css']
})
export class ActionlistComponent implements OnInit {
  pageSize: number = 5;
  length: number = 0;
  pageIndex: number = 0;
  pageEvent = new PageEvent;
  filteredactionitems: ActionItem[] = [];
  closeStatus: string = "Closed";
  sortColumnList: any;
  sortColumn = 'NewestFirst';
  errorMessage: string = "";
  pageSizeOptions: number[] = [10, 15, 20];
  panelOpenState: boolean = false;
  searchFilter: string = "";
  allActionItemExcel: ActionItemExcel[] = [];
  interfaceGroupString: string = "";
  datePipe: DatePipe = new DatePipe('en-US');
  displayedColumns: string[] = ['MOM?.Title','Title', 'Description', 'StatusName', 'GroupName', 'ResContractor', 'ActionDate', 'Responsible', 'Action'];
  actionListDataSource = new MatTableDataSource<ActionItem>(ActionItemlists);
  commonFunction !: CommonFunction;
  _projectID = parseInt(sessionStorage.getItem("projectID")!);
  _contractorID = parseInt(sessionStorage.getItem("contractorId")!);
  _userIdLogin = parseInt(sessionStorage.getItem("userId")!);
  _userRoleId = parseInt(sessionStorage.getItem("roleId")!);
  _projectTimeZone = sessionStorage.getItem("projectTimeZone")!;
  utcoffsetvalue: string = sessionStorage.getItem("projectTimeZone")!.substring(0, sessionStorage.getItem("projectTimeZone")!.lastIndexOf(':'))!;


  constructor(private router: Router, private communicationService: CommunicationService,
    private SpinnerService: NgxSpinnerService,private actionItemService :ActionItemService,
    private activatedRoute: ActivatedRoute,  private title: Title,
    private logger: NGXLogger, private dialog: DialogService, private sortColumnService: SortColumnService,
    private projectService: ProjectService,private rolePermissionService: RolePermissionService,
    private snackBar: MatSnackBar, private interfaceGroupService:InterfaceGroupService
     ) { 
      this.commonFunction = new CommonFunction(router, projectService, rolePermissionService, activatedRoute, title);
     }

  ngOnInit(): void {
    this.commonFunction.setPageTitle();
    this.sortColumnList = this.actionItemService.getSortColumnList();
    this.searchFilters('', true);
  }

  loadActionListRegister(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.searchFilters(this.sortColumn, false);
    return event;
  }

  resetFilters() {
    this.communicationService.resetFilter(true);
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    try {
      if (setPageSizeOptionsInput) {
        this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
      }
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  searchFilters(sortSelection: string, isShow1stPage: boolean) {
    if (isShow1stPage) {
      this.pageIndex = 0;
    }
    var query = "";
    if (this._userRoleId == Number(myGlobals.ProjectAdminRoleId)) {
      query = "&$filter=ProjectId eq " + this._projectID + " and IsDeleted eq false ";
      switch (sortSelection.toLowerCase()) {
        case 'oldestfirst':
          query += "&$orderby=Id";
          break;
        default:
          query += "&$orderby=Id desc";
          break;
      }
      this.fillActionItems(this.pageIndex, this.pageSize, query);
      this.setActionItemsCount(query);
      this.searchFilter = query;
    } else {
      this.interfaceGroupService.getInterfaceGroupsByProjectId(this._projectID, this._contractorID).subscribe({
        next: interfaceGroup => {
          var groupData = interfaceGroup;
          groupData.forEach(grData => {
            if (this.interfaceGroupString == "") {
              this.interfaceGroupString = grData.Id?.toString()!;
            }
            else {
              this.interfaceGroupString += "," + grData.Id?.toString();
            }
          })
          query = "&$filter=ProjectId eq  " + this._projectID + " and IsDeleted eq false and InterfaceGroupID in (" + this.interfaceGroupString + ")";

          switch (sortSelection.toLowerCase()) {
            case 'oldestfirst':
              query += "&$orderby=Id";
              break;
            default:
              query += "&$orderby=Id desc";
              break;
          }
          this.fillActionItems(this.pageIndex, this.pageSize, query);
          this.setActionItemsCount(query);
          this.searchFilter = query;
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      });
    }
    
  }

  setActionItemsCount(filter: string): void {
    try {
      this.length = 0;
      this.actionItemService.getActionItems(filter).subscribe({
        next: actionitems => {

          this.length = actionitems.length;
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  redirectNewActionItem() {
    this.router.navigateByUrl('/ActionListCreate');
  }

  fetchSortColumns(moduleName: string) {
    try {
      this.SpinnerService.show();
      this.sortColumnService.getSortColumnsByModule(moduleName)
        .toPromise()
        .then(data => {
          this.searchFilters('',false);
          this.SpinnerService.hide();
        }
        );
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
    }
  }

  onSortChange(sortSelection: string): void {
    try {
      this.sortColumn = sortSelection;
      this.searchFilters(sortSelection,false);
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  filterdataHandler(filterdata: FilterList[] = []) {
    var datalength = filterdata.filter(x=>x.FilterColumnType.toLowerCase() =="list").length;
    var i = 0;
    var query = "";
    if (this._userRoleId == Number(myGlobals.ProjectAdminRoleId)) {
      query = "&$filter=ProjectId eq " + this._projectID + " and IsDeleted eq false ";
    } else {
      query = "&$filter=ProjectId eq " + this._projectID + " and IsDeleted eq false ";
    }

    
    this.fillActionItems(this.pageIndex, this.pageSize, query);
    this.searchFilter = query;
  }

  fillActionItems(pageIndex: number, pageSize: number, filter: string) {
    try {
      this.SpinnerService.show();
      this.actionItemService.getActionItemswithAdditionalFilters(pageIndex, pageSize, filter).subscribe({
        next: actionitems => {
          this.SpinnerService.hide();
          actionitems.forEach(x => {
            if (x.Status == 1) {
              x.StatusName = "Open";
            }
            else {
              x.StatusName ="Closed"
            }
          })
          this.actionListDataSource.data = actionitems;
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  onEdit(Id:number) {
    try {
      this.SpinnerService.show();
      this.communicationService._interfaceId = Id;
      let ActionEditUrl = "ActionListEdit/" + Id;
      this.router.navigateByUrl(ActionEditUrl);
      this.SpinnerService.hide();
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  onView(Id: number) {
    try {
      this.SpinnerService.show();
      this.communicationService._interfaceId = Id;
      let ActionViewUrl = "ActionListView/" + Id;
      this.router.navigateByUrl(ActionViewUrl);
      this.SpinnerService.hide();
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  viewRecord(MomId: number,Id:number) {
    let ViewUrl = "MinutesofMeetingView/" + MomId;
    this.communicationService._actionItemId = Id;
    this.router.navigateByUrl(ViewUrl);
  }

  editRecord(MomId: number, Id: number) {
    let EditUrl = "MinutesofMeetingEdit/" + MomId;
    this.communicationService._actionItemId = Id;
    this.router.navigateByUrl(EditUrl);
  }

  exportExcel() {
    try {
      this.SpinnerService.show();
      this.allActionItemExcel.length = 0;
      this.actionItemService.getActionItems(this.searchFilter).subscribe({
        next: actionitems => {
          
          //Bind interfaces for Excel
          actionitems.forEach((itemRow) => {
            let actionitemRec = new ActionItemExcel();
            actionitemRec.Number = itemRow.Id!.toString();
            actionitemRec.MOMTitle = itemRow.MOMId == null ? "" :itemRow.MOM?.Title.toString()!;
            actionitemRec.InterfaceGroup = itemRow.InterfaceGroup.Name;
            actionitemRec.Title = itemRow.Title;
            actionitemRec.Description = itemRow.Description;
            actionitemRec.Responsible = itemRow.Responsible;
            actionitemRec.ActionDate = itemRow.ActionDate.toString();
            actionitemRec.IsClosed = itemRow.IsClosed ? "True" : "False";
            actionitemRec.ClosedDate = itemRow.IsClosed ? itemRow.ClosedDate!.toString() : "";
            actionitemRec.Status = itemRow.StatusName!;
            actionitemRec.IsDeleted = itemRow.IsDeleted ? "True" : "False";
            actionitemRec.CreatedDate = itemRow.CreatedDate?.toString();
            actionitemRec.CreatedBy = itemRow.CreatedUserRoleMapping?.User.FirstName + " " + itemRow.CreatedUserRoleMapping?.User.LastName;
            
            this.allActionItemExcel.push(actionitemRec);
          })

          const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.allActionItemExcel);
          let Heading = [['Number', 'Minutes of Meeting Title', 'Interface Group', 'Title', 'Description', 'Responsible', 'ActionDate', 'IsClosed',
            'ClosedDate', 'Status', 'IsDeleted', 'CreatedDate']];

          XLSX.utils.sheet_add_aoa(ws, Heading);

          //Generate workbook and add the worksheet
          const wb: XLSX.WorkBook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, 'Action Item');

          //Save to file
          let todayDate = this.datePipe.transform(new Date(), 'dd_MM_yyyy_hh_mm_ss');
          XLSX.writeFile(wb, 'ActionItem_' + todayDate + '.xlsx');
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  printPDF() {
    var HTML_Width = 0;
    var HTML_Height = 0;
  }

  confirmCancelDialog(Id: number) {
    try {
      this.dialog
        .confirmDialog({
          title: 'Confirm Action',
          message: 'Do you want to delete this action item?',
          confirmCaption: 'Confirm',
          cancelCaption: 'Cancel',
        })
        .subscribe((confirmed) => {
          if (confirmed) {
            this.delete(Id);
          }
        });
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  delete(Id:number) {
    try {
      let actionitemData = this.filteredactionitems.filter(x => x.Id == Id);
      if (actionitemData.length > 0) {
        actionitemData[0].IsDeleted = true;
        actionitemData[0].ModifiedBy = this._userIdLogin;
        this.actionItemService.put(actionitemData[0]).subscribe(
          {
            next: interfaces => {
              this.SpinnerService.hide();
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: "Action item deleted successfully!",
                duration: myGlobals.snackBarDuration,
                verticalPosition: myGlobals.snackBarVerticalPosition,
                horizontalPosition: myGlobals.snackBarHorizontalPosition
              });
            },
            error: err => {
              this.SpinnerService.hide();
              this.errorMessage = err
              this.dialog.confirmDialog({
                title: 'Error',
                message: myGlobals.exceptionmessage,
                cancelCaption: 'Close',
              });
              this.logger.error(err);
            }
          }
        );
      }
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }
}
