import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AppLayoutComponent } from '../app-layout/app-layout.component';
import { SubscriptionsComponent } from './subscriptions.component';
import { PermissionGuard } from '../authorization/permission.guard';
import { MsalGuard } from '@azure/msal-angular';

const subscriptionRoutes: Routes = [
  {
    path: '',
    //component: AppLayoutComponent,
    children: [
      { path: 'Subscriptions', component: SubscriptionsComponent,
        canActivate: [MsalGuard,PermissionGuard], data: { perminssion: 'subscriptions.new', title: 'Notifications' }
    }
    ]
  }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(subscriptionRoutes)
  ]
})
export class SubscriptionRoutingModule { }
