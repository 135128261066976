import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Contractor } from '../../app_data/contractor';
import { Discipline } from '../../app_data/discipline';
import { InterfaceGroup } from '../../app_data/interfaceGroup';
import { CommunicationService } from '../../services/communication.service';
import { ContractorService } from '../../services/contractor.service';
import { SystemLogService } from '../../services/systemLog.service';
import { NGXLogger } from 'ngx-logger';
import * as myGlobals from '../../app_data/globals';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogService } from '../../services/dialog.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '../../shared/components/snackbar/snackbar.component';
import { CommonFunction } from 'src/app/app_data/commonFunction';
import { Title } from '@angular/platform-browser';
import { ProjectService } from 'src/app/services/project.service';
import { RolePermissionService } from 'src/app/services/rolepermission.service';

@Component({
  selector: 'app-contractor',
  templateUrl: './contractor-create.component.html',
  styleUrls: ['./contractor-create.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ContractorCreateComponent implements OnInit {
  form !: FormGroup;
  contractor !: Contractor;
  discipline !: Discipline;
  pageTitle: string = "New Contractor";
  errorMessage: string = "";
  contractorID!: number;
  allContractors: Contractor[] = [];
  dupContractor: boolean = false;
  interfaceGroupTooltip : string = "";
  commonFunction!:CommonFunction;
  _projectID = parseInt(sessionStorage.getItem("projectID")!);
  _contractorID = parseInt(sessionStorage.getItem("contractorId")!);
  _userIdLogin = parseInt(sessionStorage.getItem("userId")!);
  _userRoleId = parseInt(sessionStorage.getItem("roleId")!);
  _userName = sessionStorage.getItem("userFName") + " " + sessionStorage.getItem("userLName");
  _userRoleMappingId = parseInt(sessionStorage.getItem("userRoleMappingId")!);

  constructor(private contractorService: ContractorService, private router: Router, private communicationService: CommunicationService,
    private systemLogService: SystemLogService,
    private activatedRoute: ActivatedRoute,  private title: Title,
    private projectService: ProjectService,private rolePermissionService: RolePermissionService,
    private SpinnerService: NgxSpinnerService,
    private logger: NGXLogger, private dialog: DialogService,
    private snackBar: MatSnackBar) {
    this.contractor = new Contractor(); this.discipline = new Discipline();
    this.commonFunction = new CommonFunction(router, projectService, rolePermissionService, activatedRoute, title);
  }

  ngOnInit(): void {
    this.commonFunction.setPageTitle();
    this.form = new FormGroup({
      Name: new FormControl('', [Validators.required]),
      Code: new FormControl('', [Validators.required]),
      Address: new FormControl(''),
      City: new FormControl(''),
      Country: new FormControl(''),
      ContactPerson: new FormControl(''),
      EmailId: new FormControl('', [Validators.pattern("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$")]),
      PhoneNumber: new FormControl('', [Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
      IsDeleted: new FormControl(false),
    });
  }

  onKeypressEvent(event: any) {
    this.communicationService._contractorname = event.target.value;
  }

  disciplineaddedHandler(discipline: Discipline[] = []) {
    this.contractor.Disciplines = discipline;
  }

  interfaceGroupAddedHandler(interfaceGroups: InterfaceGroup[] = []) {
    this.contractor.Contractor1InterfaceGroups = interfaceGroups;
  }

  save() {
    try {
      this.SpinnerService.show();
      if (this.form.valid) {

        this.contractorService.getContractorsByProjectId(this._projectID).subscribe({
          next: providerContractor => {
            this.allContractors = providerContractor.filter(x => !x.IsDeleted);
            let pos = this.allContractors.findIndex(x => (x.Code.toLocaleLowerCase() === this.contractor.Code.toLocaleLowerCase() && x.Name.toLocaleLowerCase() === this.contractor.Name.toLocaleLowerCase()) || x.Code.toLocaleLowerCase() === this.contractor.Code.toLocaleLowerCase());

            if (pos != -1) {
              this.SpinnerService.hide();
              this.dialog.confirmDialog({
                title: 'Warning',
                message: 'Contractor Name or Code already exists.',
                cancelCaption: 'Close',
              });
            }
            else {
              this.contractor.IsCompany = false;
              this.contractor.IsDeleted = false;
              this.contractor.CreatedBy = this._userRoleMappingId;
              this.contractor.ProjectId = this._projectID;

              this.contractor.Disciplines = this.contractor.Disciplines.filter(x => (x.Id != 0 || !x.IsDeleted));
              this.contractor.Contractor1InterfaceGroups = this.contractor.Contractor1InterfaceGroups.filter(x => (x.Id != 0 || !x.IsDeleted));

              this.contractor.Contractor1InterfaceGroups.forEach((docRow) => {
                if (docRow.Id == 0) {
                  docRow.Contractor1 = null;
                  docRow.Contractor2 = null;
                }
              });

              this.contractorService.post(this.contractor).subscribe(
                {
                  next: allcontractors => {
                    this.systemLogService.writeLog(this._projectID, this._userIdLogin, "New Contractor", "Contractor Detail", this.contractor.Name + "|" + this.contractor.Code + "|" + this.contractor.ContactPerson
                      + "|" + this.contractor.EmailId + "|" + this.contractor.PhoneNumber + "|" + this.contractor.Address + "|" + this.contractor.City + "|" + this.contractor.Country, this._contractorID, this._userRoleId);
                    this.contractor.Disciplines.forEach((docRow) => {
                      //Write a log
                      this.systemLogService.writeLog(this._projectID, this._userIdLogin, "New Contractor", "Disciplines", docRow.Name + "|" + docRow.Code + "|" + docRow.DisciplineLead, this._contractorID, this._userRoleId);
                    });
                    this.contractor.Contractor1InterfaceGroups.forEach((docRow) => {
                      //Write a log
                      this.systemLogService.writeLog(this._projectID, this._userIdLogin, "New Contractor", "Interfacegroups", docRow.Name + "|" + docRow.Code + "|" + docRow.Contractor1 + "|" + docRow.Contractor2, this._contractorID, this._userRoleId);
                    });
                    
                    this.SpinnerService.hide();
                    this.snackBar.openFromComponent(SnackbarComponent, {
                      data: "Contractor created successfully!",
                      duration: myGlobals.snackBarDuration,
                      verticalPosition: myGlobals.snackBarVerticalPosition,
                      horizontalPosition: myGlobals.snackBarHorizontalPosition
                    });
                    this.redirecttoList();
                  },
                  error: err => {
                    this.SpinnerService.hide();
                    this.errorMessage = err
                    this.dialog.confirmDialog({
                      title: 'Error',
                      message: myGlobals.exceptionmessage,
                      cancelCaption: 'Close',
                    });
                    this.logger.error(err);
                  }
                }
              );
            }
            this.SpinnerService.hide();
          },
          error: err => {
            this.SpinnerService.hide();
            this.errorMessage = err
            this.dialog.confirmDialog({
              title: 'Error',
              message: myGlobals.exceptionmessage,
              cancelCaption: 'Close',
            });
            this.logger.error(err);
          }
        })

      }
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  redirecttoList() {
    this.router.navigateByUrl('/Contractors');
  }

  onCancel() {
    this.redirecttoList();
  }

}
