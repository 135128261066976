import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NGXLogger } from 'ngx-logger';
import { Contractor } from '../../../app_data/contractor';
import { InterfaceGroup } from '../../../app_data/interfaceGroup';
import { ContractorService } from '../../../services/contractor.service';
import { DialogService } from '../../../services/dialog.service';
import * as myGlobals from '../../../app_data/globals';
import { InterfaceGroupService } from '../../../services/interfaceGroup.service';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { ReportService } from '../../../services/report.service';
import { Summary } from '../../../app_data/summary';
import { ReportFilter } from '../../../app_data/reportFilter';
import { ReportSearchFilter } from '../../../app_data/reportSearchFilter';
import { InterfaceSummary } from '../../../app_data/interfaceSummary';
import { DateAdapter, MatOption, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe, formatDate } from '@angular/common';
import { PdfGenerateComponent } from '../pdf-generate/pdf-generate.component';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { ProjectService } from '../../../services/project.service';
import { CommonFunction } from '../../../app_data/commonFunction';
import { ModuleProjectMappingsService } from 'src/app/services/moduleprojectmappings.service';
import { ModuleProjectMapping } from 'src/app/app_data/moduleProjectMapping';

let MY_FORMATS = {
  parse: {
    dateInput: "LL"
  },
  display: {
    dateInput: "LL",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY"
  }
};

@Component({
  selector: 'app-standard-report',
  templateUrl: './standard-report.component.html',
  styleUrls: ['./standard-report.component.css'],
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    { provide: MAT_DATE_LOCALE, useValue: 'en-US' },

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class StandardReportComponent implements OnInit {
  docDefination: any;
  stdForm !: FormGroup;
  errorMessage!: string;
  selectedContractor!: number;
  selectedModule!: string;
  selectedContractorValue!: string;
  selectedInterfaceGroup!: number[];
  selectedGrpValue!: string;
  icContractors: Contractor[] = [];
  interfaceGroups: InterfaceGroup[] = [];
  reportfilters: ReportSearchFilter[] = [];
  reportFilter!: ReportFilter;
  contractorCount!: number;
  interfaceGroupCount!: number;
  todayDate!: string;
  dsSummarys: Summary[] = [];
  interfaceSummarys: InterfaceSummary[] = [];
  totalIdentified: number = 0;
  totalOpen: number = 0;
  totalOverdue: number = 0;
  totalClosed: number = 0;
  totalFinal: number = 0;
  reportName: string = "Standard Report";
  @ViewChild('InterfaceGroupIDInput') select!: MatSelect;
  @ViewChild('ContractorIDInput') Conselect!: MatSelect;
  allSelected = false;
  allSelectedContractor = false;
  @ViewChild('myIdentifier') myIdentifier!: ElementRef;
  @ViewChild('PdfGenerateComponent') pdfGenerateComponentRef!: PdfGenerateComponent;
  utcoffsetvalue: string = sessionStorage.getItem("projectTimeZone")!.substring(0, sessionStorage.getItem("projectTimeZone")!.lastIndexOf(':'))!;
  responseOverdueInterfacesRequestorAction: InterfaceSummary[] = [];
  responseOverdueInterfacesProviderAction: InterfaceSummary[] = [];
  closeoutOverdueInterfacesRequestorAction: InterfaceSummary[] = [];
  closeoutOverdueInterfacesProviderAction: InterfaceSummary[] = [];
  dateTimeFormat: string = '';
  providerContractorLabel: string = myGlobals.ProviderContractorLabel;
  requestingContractorLabel: string = myGlobals.RequestingContractorLabel;
  providerDisciplineLabel: string = myGlobals.ProviderDisciplineLabel;
  requestingDisciplineLabel: string = myGlobals.RequestingDisciplineLabel;
  expectedResponseDateLabel: string = myGlobals.ExpectedResponseDateLabel;
  closeOutDateLabel: string = myGlobals.CloseOutDateLabel;
  projectName: string = sessionStorage.getItem("projectName")!;
  _projectTimeZone = sessionStorage.getItem("projectTimeZone")!;
  projectStartDate!: Date;
  pipe = new DatePipe('en-US');
  isContractorDropdownShow: boolean = false;
  maxDate: Date = CommonFunction.ConvertUTCToTimeZoneTime(CommonFunction.ConvertLocalTimeToUTC(new Date()), this._projectTimeZone, false);
  moduleProjectMappings: ModuleProjectMapping[] = [];
  pageOrientationType: string = "landscape";
  _projectID = parseInt(sessionStorage.getItem("projectID")!);
  _contractorID = parseInt(sessionStorage.getItem("contractorId")!);
  _userIdLogin = parseInt(sessionStorage.getItem("userId")!);
  _userRoleId = parseInt(sessionStorage.getItem("roleId")!);
  _rolePermissions = sessionStorage.getItem("rolePermissions")!;
  isGenerateReportButtonEnable: boolean = true;
  isDualDisciplinebool: boolean = false;

  constructor(private contractorService: ContractorService,
    private interfaceGroupService: InterfaceGroupService,
    private dialog: DialogService, private projectService: ProjectService,
    private reportService: ReportService, private SpinnerService: NgxSpinnerService,
    private moduleProjectMappingsService: ModuleProjectMappingsService,
    private logger: NGXLogger) {
    this.reportFilter = new ReportFilter();
    this.reportFilter.FromDate = "";
    this.reportFilter.ToDate = "";
  }

  ngOnInit(): void {
    this.stdForm = new FormGroup({
      ContractorID: new FormControl(''),
      InterfaceGroupID: new FormControl('', [Validators.required]),
      Description: new FormControl(''),
      ModuleName: new FormControl('', [Validators.required]),
      FromDate: new FormControl(""),
      ToDate: new FormControl("")
    });
    this.getProjectSettings();
    if (this._userRoleId == Number(myGlobals.ProjectAdminRoleId)) {
      this.isContractorDropdownShow = true;
      this.getContractors(this._projectID);
    }
    else {
      this.getInterfaceGroupsByContractor([this._contractorID], this._projectID);
    }
    this.getModules(this._projectID);
  }

  toggleAllSelection() {
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }

    this.toggleDisableModuleDropdown();
  }

  optionClick() {
    let newStatus = true;
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
    this.toggleDisableModuleDropdown();
  }

  toggleDisableModuleDropdown(){
    this.stdForm.controls['ModuleName'].reset();
    
    if(this.selectedInterfaceGroup.length <= 0){
      this.stdForm.controls['ModuleName'].disable();
    }else{
      this.stdForm.controls['ModuleName'].enable();
    }
    this.isGenerateReportButtonEnable = true;
  }

  toggleAllSelectionContractor() {
    if (this.allSelectedContractor) {
      this.Conselect.options.forEach((item: MatOption) => item.select());
    } else {
      this.Conselect.options.forEach((item: MatOption) => item.deselect());
    }
  }

  contractoroptionClick() {
    let newconStatus = true;
    this.Conselect.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newconStatus = false;
      }
    });
    this.allSelectedContractor = newconStatus;
  }

  getProjectSettings() {
    try {
      this.SpinnerService.show();
      this.projectService.getProject(this._projectID).subscribe({
        next: project => {
          if (project[0].Settings.length > 0) {
            this.dateTimeFormat = project[0].Settings.filter(x => x.ConfigKey == "DateTimeFormat")[0].ConfigValue;
            this.projectStartDate = project[0].CreatedDate!;
           
            MY_FORMATS.parse.dateInput = this.dateTimeFormat.replace("dd", "DD");
            MY_FORMATS.display.dateInput = this.dateTimeFormat.replace("dd", "DD");
            MY_FORMATS.display.dateA11yLabel = this.dateTimeFormat.replace("dd", "DD");
            this.stdForm.controls['FromDate'].setValue(this.projectStartDate);
           
            var index = project[0].Settings.findIndex(x => x.ConfigKey == "UTCOffset");
            if (index > -1) {
              var offsetValue = project[0].Settings.filter(x => x.ConfigKey == "UTCOffset")[0].ConfigValue.split("|")[0]!;
              this.utcoffsetvalue = offsetValue.substring(0, offsetValue.lastIndexOf(':'))!;
            }

            var index = project[0].Settings.findIndex(x => x.ConfigKey == "ProviderContractorName");
            if (index > -1) {
              this.providerContractorLabel = project[0].Settings.filter(x => x.ConfigKey == "ProviderContractorName")[0].ConfigValue;
            }
            var index = project[0].Settings.findIndex(x => x.ConfigKey == "RequestingContractorName");
            if (index > -1) {
              this.requestingContractorLabel = project[0].Settings.filter(x => x.ConfigKey == "RequestingContractorName")[0].ConfigValue;
            }
            var index = project[0].Settings.findIndex(x => x.ConfigKey == "ProviderDisciplineName");
            if (index > -1) {
              this.providerDisciplineLabel = project[0].Settings.filter(x => x.ConfigKey == "ProviderDisciplineName")[0].ConfigValue;
            }
            var index = project[0].Settings.findIndex(x => x.ConfigKey == "RequestingDisciplineName");
            if (index > -1) {
              this.requestingDisciplineLabel = project[0].Settings.filter(x => x.ConfigKey == "RequestingDisciplineName")[0].ConfigValue;
            }
            var index = project[0].Settings.findIndex(x => x.ConfigKey == "ExpectedResponseDateName");
            if (index > -1) {
              this.expectedResponseDateLabel = project[0].Settings.filter(x => x.ConfigKey == "ExpectedResponseDateName")[0].ConfigValue;
            }
            var index = project[0].Settings.findIndex(x => x.ConfigKey == "CloseOutDateName");
            if (index > -1) {
              this.closeOutDateLabel = project[0].Settings.filter(x => x.ConfigKey == "CloseOutDateName")[0].ConfigValue;
            }

            var index = project[0].Settings.findIndex(x => x.ConfigKey == "IsDualDiscipline");
            if (index > -1) {
              var IsDualDisciplinebool = project[0].Settings.filter(x => x.ConfigKey == "IsDualDiscipline")[0].ConfigValue;
              if (IsDualDisciplinebool == "true") {
                this.isDualDisciplinebool = true;
              }
            }
          }

          this.todayDate = this.pipe.transform(new Date(), 'yyyy-MM-dd', this.utcoffsetvalue)!;
          this.stdForm.controls['ToDate'].setValue(this.todayDate);

          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      });

    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  getContractors(projectId: number) {
    try {
      this.contractorService.getContractorsByProjectId(projectId).subscribe({
        next: contractors => {
          this.icContractors = contractors.sort((a, b) => a.Id! > b.Id! ? 1 : -1);
        },
        error: err => {
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  getModules(projectId: number) {
    try {
      this.SpinnerService.show();
      this.moduleProjectMappingsService.getModulesByProjectId(projectId).subscribe({
        next: modulesProjectMappingData => {
          modulesProjectMappingData.forEach(x => {
            var a = x.Module?.ModulePermissionMappings?.filter(v => v.IsProjectWise);
            var permissionindex = this._rolePermissions.indexOf(a![0].Permission?.Name!);
            if (permissionindex == -1) {
              x.IsDeleted = true;
            }
          });
          this.moduleProjectMappings = modulesProjectMappingData.filter(x => !x.IsDeleted);
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  onChangevalue(ev: MatSelectChange) {
    this.getInterfaceGroupsByContractor(ev.source.value, this._projectID)
  }

  onDateChange(type: string, event: any) {
    this.stdForm.controls['ModuleName'].setValue('');
  }

  getInterfaceGroupsByContractor(contractorIds: number[], projectId: number) {
    try {
      this.SpinnerService.show();
      //Get Interface Groups on project and selected contractors.
      if (contractorIds.length > 0) {
        this.interfaceGroupService.getInterfaceGroupsbyProjectAndContractors(projectId, contractorIds).subscribe({
          next: interfaceGroups => {
            this.SpinnerService.hide();
            this.interfaceGroups = interfaceGroups.sort((a, b) => a.Id! > b.Id! ? 1 : -1);
            this.selectedInterfaceGroup = interfaceGroups.length > 0 ? [interfaceGroups[0].Id!] : [0];
            this.selectedGrpValue = interfaceGroups.length > 0 ? interfaceGroups[0].Name : '';
            this.stdForm.controls['ModuleName'].enable();
          },
          error: err => {
            this.SpinnerService.hide();
            this.errorMessage = err
            this.dialog.confirmDialog({
              title: 'Error',
              message: myGlobals.exceptionmessage,
              cancelCaption: 'Close',
            });
            this.logger.error(err);
          }
        })
      } else {
        this.interfaceGroupService.getInterfaceGroupsforProjectAdmin(projectId).subscribe({
          next: interfaceGroups => {
            this.SpinnerService.hide();
            this.interfaceGroups = interfaceGroups.sort((a, b) => a.Id! > b.Id! ? 1 : -1);
            this.selectedInterfaceGroup = interfaceGroups.length > 0 ? [interfaceGroups[0].Id!] : [0];
            this.selectedGrpValue = interfaceGroups.length > 0 ? interfaceGroups[0].Name : '';
            this.stdForm.controls['ModuleName'].enable();
          },
          error: err => {
            this.SpinnerService.hide();
            this.errorMessage = err
            this.dialog.confirmDialog({
              title: 'Error',
              message: myGlobals.exceptionmessage,
              cancelCaption: 'Close',
            });
            this.logger.error(err);
          }
        })
      }

    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  generateStandardReport(reportfilter: ReportFilter) {
    try {
      this.SpinnerService.show();
      var toDateString = formatDate(new Date(), this.dateTimeFormat, "en-US")
      if (reportfilter.FromDate == "") {
        reportfilter.FromDate = String(this.projectStartDate);
      }
      if (reportfilter.ToDate == "") {
        reportfilter.ToDate = String(toDateString);
      }
      this.totalIdentified = 0; this.totalOpen = 0; this.totalOverdue = 0; this.totalClosed = 0; this.totalFinal = 0;
      this.reportfilters.push({ KeyName: "InterfaceGroupId", Value: String(reportfilter.InterfaceGroupID) }, { KeyName: "ProjectId", Value: String(this._projectID) },
        { KeyName: "InterfaceType", Value: String(reportfilter.ModuleName) }, { KeyName: "FromDate", Value: formatDate(reportfilter.FromDate!, this.dateTimeFormat, "en-US") },
        { KeyName: "ToDate", Value: formatDate(reportfilter.ToDate!, this.dateTimeFormat, "en-US") })
      this.reportService.post(this.reportfilters, "FetchStandardReport").subscribe({
        next: data => {
          this.SpinnerService.hide();
          this.contractorCount = data.ProjectSummaryCount[0].ContractorCount;
          this.interfaceGroupCount = data.ProjectSummaryCount[0].InterfaceGroupCount;
          this.projectName = data.ProjectSummaryCount[0].ProjectName;
          this.dsSummarys = data.Summary;

          var interfaceGroupIds = String(reportfilter.InterfaceGroupID)?.split(',');
          var body: any = [];
          var columns = [{ text: "Interface Group", fillColor: '#dcdcdc' }, { text: this.requestingContractorLabel, fillColor: '#dcdcdc' }
            , { text: this.providerContractorLabel, fillColor: '#dcdcdc' }, { text: "Identified", fillColor: '#dcdcdc' }, { text: "Open", fillColor: '#dcdcdc' }, { text: "Response Overdue", fillColor: '#dcdcdc' }, { text: "Closeout Overdue", fillColor: '#dcdcdc' }
            , { text: "Closed", fillColor: '#dcdcdc' }, { text: "Total", fillColor: '#dcdcdc' }];
          body.push(columns);
          
          for (var key in this.dsSummarys) {
            if (this.dsSummarys.hasOwnProperty(key)) {
              var header = this.dsSummarys[key];
              var row = new Array();
              row.push(header.InterfaceGroup);
              row.push(header.Contractor1);
              row.push(header.Contractor2);
              row.push(header.Identified);
              row.push(header.Open);
              row.push(header.ResponseOverdue);
              row.push(header.CloseoutOverdue);
              row.push(header.Closed);
              row.push(header.Total);
              body.push(row);
            }
          }


          this.docDefination = [
            { text: "Report generated for dates from " + formatDate(reportfilter.FromDate!, this.dateTimeFormat, "en-US") + " to " + formatDate(reportfilter.ToDate!, this.dateTimeFormat, "en-US"), style: "sectionheader", margin: [0, 0, 0, 5] },
            { text: "Overview of project " + this.projectName, style: "sectionheader", margin: [0, 0, 0, 5] },

            {
              style: "tableExample",
              margin: [0, 0, 0, 10],
              table: {
                body: [
                  ["Total number of contractors", this.contractorCount],
                  ["Total number of interface groups", this.interfaceGroupCount]
                ]
              }
            },
            { text: "Status overview of project ", style: "sectionheader", margin: [0, 0, 0, 5] },
            {
              style: "tableExample",
              margin: [0, 0, 0, 10],
              table: {
                headerRows: 1,
                body: body
              }
            }
          ];
          //reset form
          this.stdForm.reset();
          this.stdForm.controls['FromDate'].setValue(this.projectStartDate);
          this.stdForm.controls['ToDate'].setValue(this.todayDate);
          this.allSelectedContractor = false;

          interfaceGroupIds.forEach(igId => {
            var interfaceGroupName = '';
            var interfaceSummaryByGroup = data.InterfaceSummary.filter(x => x.InterfaceGroupID == Number(igId));
            if (interfaceSummaryByGroup.length > 0) {
              interfaceGroupName = interfaceSummaryByGroup[0].InterfaceGroupName;
            }

            this.interfaceSummarys = data.InterfaceSummary.filter(x => !(x.IsFirstResponsePlannedOverdue || x.IsCloseoutPlannedOverdue) && x.InterfaceGroupID == Number(igId));

            this.responseOverdueInterfacesRequestorAction = data.InterfaceSummary.filter(x => x.IsFirstResponsePlannedOverdue && x.InterfaceGroupID == Number(igId) && x.IsRequestorActionIsPending);
            this.responseOverdueInterfacesProviderAction = data.InterfaceSummary.filter(x => x.IsFirstResponsePlannedOverdue && x.InterfaceGroupID == Number(igId) && x.IsProviderActionIsPending);

            this.closeoutOverdueInterfacesRequestorAction = data.InterfaceSummary.filter(x => !x.IsFirstResponsePlannedOverdue && x.IsCloseoutPlannedOverdue && x.InterfaceGroupID == Number(igId) && x.IsRequestorActionIsPending);
            this.closeoutOverdueInterfacesProviderAction = data.InterfaceSummary.filter(x => !x.IsFirstResponsePlannedOverdue && x.IsCloseoutPlannedOverdue && x.InterfaceGroupID == Number(igId) && x.IsProviderActionIsPending);

            data.Summary.forEach(x => {
              this.totalIdentified += x.Identified;
              this.totalOpen += x.Open;
              this.totalOverdue += x.Overdue;
              this.totalClosed += x.Closed;
              this.totalFinal += x.Total;
            });

            var groupwiseBody: any = [];
            var columns = [{ text: "Interface Group", fillColor: '#dcdcdc' }, { text: this.requestingContractorLabel, fillColor: '#dcdcdc' }
              , { text: this.providerContractorLabel, fillColor: '#dcdcdc' }, { text: "Identified", fillColor: '#dcdcdc' }, { text: "Open", fillColor: '#dcdcdc' }, { text: "Response Overdue", fillColor: '#dcdcdc' }, { text: "Closeout Overdue", fillColor: '#dcdcdc' }
              , { text: "Closed", fillColor: '#dcdcdc' }, { text: "Total", fillColor: '#dcdcdc' }];
            groupwiseBody.push(columns);

            for (var key in this.dsSummarys) {
              if (this.dsSummarys.hasOwnProperty(key)) {
                var header = this.dsSummarys[key];
                if (interfaceGroupName == header.InterfaceGroup) {
                  var row = new Array();
                  row.push(header.InterfaceGroup);
                  row.push(header.Contractor1);
                  row.push(header.Contractor2);
                  row.push(header.Identified);
                  row.push(header.Open);
                  row.push(header.ResponseOverdue);
                  row.push(header.CloseoutOverdue);
                  row.push(header.Closed);
                  row.push(header.Total);
                  groupwiseBody.push(row);
                }
              }
            }

            var summarybody = [];
            var summaryColumns = [{ text: "Number", fillColor: '#dcdcdc'}, 

              { text: this.isDualDisciplinebool ? this.requestingDisciplineLabel : 'Discipline' , fillColor: '#dcdcdc', colSpan : this.isDualDisciplinebool? 0:2},
              { text: this.isDualDisciplinebool ? this.providerDisciplineLabel: '', fillColor: '#dcdcdc', colSpan : this.isDualDisciplinebool? 0:2},

            { text: "Title", fillColor: '#dcdcdc'}, 
            { text: this.requestingContractorLabel, fillColor: '#dcdcdc'}, 
            { text: this.providerContractorLabel, fillColor: '#dcdcdc'},
            { text: this.expectedResponseDateLabel, fillColor: '#dcdcdc'},
            { text: this.closeOutDateLabel, fillColor: '#dcdcdc'}];
            summarybody.push(summaryColumns);

            if (this.interfaceSummarys.length > 0) {
              for (var key in this.interfaceSummarys) {
                if (this.interfaceSummarys.hasOwnProperty(key)) {
                  var summaryheader = this.interfaceSummarys[key];
                  var row = new Array();
                  row.push(summaryheader.Number);
                  row.push({text: summaryheader.ReceivingDiscipline, colSpan : this.isDualDisciplinebool? 0:2});
                  row.push({text: this.isDualDisciplinebool ? summaryheader.ResponsibleDiscipline : "", colSpan : this.isDualDisciplinebool? 0:2});                   
                  row.push(summaryheader.Title);
                  row.push({ text: summaryheader.ReceivingContractor, color: summaryheader.Status == myGlobals.ClosedStatusId ? myGlobals.green : '', bold: summaryheader.Status == myGlobals.ClosedStatusId ? true : false});
                  row.push({ text: summaryheader.ResponsibleContractor, color: summaryheader.Status == myGlobals.ClosedStatusId ? myGlobals.green : '', bold: summaryheader.Status == myGlobals.ClosedStatusId ?  true:false});
                  row.push(formatDate(summaryheader.FirstResponsePlanned, this.dateTimeFormat, "en-US"));
                  row.push(formatDate(summaryheader.CloseoutPlanned, this.dateTimeFormat, "en-US"));
                  summarybody.push(row);
                }
                if (this.interfaceSummarys.hasOwnProperty(key)) {
                  var summaryheader = this.interfaceSummarys[key];
                  var row = new Array();
                  row.push({ text: "Description", fillColor: '#dcdcdc', style: '' });
                  row.push({ text: summaryheader.Description, colSpan: 7 });
                  summarybody.push(row);
                }
                if (this.interfaceSummarys.hasOwnProperty(key)) {
                  var summaryheader = this.interfaceSummarys[key];
                  var row = new Array();
                  row.push({ text: "Comments", fillColor: '#dcdcdc' });
                  row.push({ text: summaryheader.Comments, colSpan: 7 });
                  summarybody.push(row);
                }
                if (this.interfaceSummarys.hasOwnProperty(key)) {
                  var summaryheader = this.interfaceSummarys[key];
                  var row = new Array();
                  row.push({ text: "", border: [false, true, false, false], colSpan: 8 });
                  summarybody.push(row);
                }
              }
            }
            else {
              var row = new Array();
              row.push({ text: "No Data", colSpan: 8, alignment: 'center' });
              summarybody.push(row);
            }


            //Response Overdue Requestor Action Pending
            var responseObverdueRequestorActionbody = [];
            var responseObverdueRequestorActionColumns = [{ text: "Number", fillColor: '#dcdcdc' }, 

            { text: this.isDualDisciplinebool ? this.requestingDisciplineLabel : 'Discipline' , fillColor: '#dcdcdc', colSpan : this.isDualDisciplinebool? 0:2},
            { text: this.isDualDisciplinebool ? this.providerDisciplineLabel: '', fillColor: '#dcdcdc', colSpan : this.isDualDisciplinebool? 0:2},
              
            { text: "Title", fillColor: '#dcdcdc' }, 
            { text: this.requestingContractorLabel, fillColor: '#dcdcdc' }, 
            { text: this.providerContractorLabel, fillColor: '#dcdcdc' },
            { text: this.expectedResponseDateLabel, fillColor: '#dcdcdc' },
            { text: this.closeOutDateLabel, fillColor: '#dcdcdc' }];
            responseObverdueRequestorActionbody.push(responseObverdueRequestorActionColumns);

            if (this.responseOverdueInterfacesRequestorAction.length > 0) {
              for (var key in this.responseOverdueInterfacesRequestorAction) {
                if (this.responseOverdueInterfacesRequestorAction.hasOwnProperty(key)) {
                  var summaryheader = this.responseOverdueInterfacesRequestorAction[key];
                  var row = new Array();
                  row.push(summaryheader.Number);

                  row.push({text: summaryheader.ReceivingDiscipline, colSpan : this.isDualDisciplinebool? 0:2});
                  row.push({text: this.isDualDisciplinebool ? summaryheader.ResponsibleDiscipline : "", colSpan : this.isDualDisciplinebool? 0:2});                    
                  row.push(summaryheader.Title);
                  row.push({ text: summaryheader.ReceivingContractor, color: myGlobals.red, bold: summaryheader.Status == myGlobals.ClosedStatusId ? true : false});
                  row.push({ text: summaryheader.ResponsibleContractor, color: myGlobals.yellow, bold: summaryheader.Status == myGlobals.ClosedStatusId ? true : false});
                  row.push(formatDate(summaryheader.FirstResponsePlanned, this.dateTimeFormat, "en-US"));
                  row.push(formatDate(summaryheader.CloseoutPlanned, this.dateTimeFormat, "en-US"));
                  responseObverdueRequestorActionbody.push(row);
                }
                if (this.responseOverdueInterfacesRequestorAction.hasOwnProperty(key)) {
                  var summaryheader = this.responseOverdueInterfacesRequestorAction[key];
                  var row = new Array();
                  row.push({ text: "Description", fillColor: '#dcdcdc', style: '' });
                  row.push({ text: summaryheader.Description, colSpan: 7 });
                  responseObverdueRequestorActionbody.push(row);
                }
                if (this.responseOverdueInterfacesRequestorAction.hasOwnProperty(key)) {
                  var summaryheader = this.responseOverdueInterfacesRequestorAction[key];
                  var row = new Array();
                  row.push({ text: "Comments", fillColor: '#dcdcdc' });
                  row.push({ text: summaryheader.Comments, colSpan: 7 });
                  responseObverdueRequestorActionbody.push(row);
                }
                if (this.responseOverdueInterfacesRequestorAction.hasOwnProperty(key)) {
                  var summaryheader = this.responseOverdueInterfacesRequestorAction[key];
                  var row = new Array();
                  row.push({ text: "", border: [false, true, false, false], colSpan: 8 });
                  responseObverdueRequestorActionbody.push(row);
                }
              }
            }
            else {
              var row = new Array();
              row.push({ text: "No Data", colSpan: 8, alignment: 'center' });
              responseObverdueRequestorActionbody.push(row);
            }

            //Response Overdue Provider Action Pending
            var responseObverdueProviderActionbody = [];
            var responseObverdueProviderActionColumns = [{ text: "Number", fillColor: '#dcdcdc' }, 
              { text: this.isDualDisciplinebool ? this.requestingDisciplineLabel : 'Discipline' , fillColor: '#dcdcdc', colSpan : this.isDualDisciplinebool? 0:2},
              { text: this.isDualDisciplinebool ? this.providerDisciplineLabel: '', fillColor: '#dcdcdc', colSpan : this.isDualDisciplinebool? 0:2},
            { text: "Title", fillColor: '#dcdcdc' }, 
            { text: this.requestingContractorLabel, fillColor: '#dcdcdc' }, 
            { text: this.providerContractorLabel, fillColor: '#dcdcdc' },
            { text: this.expectedResponseDateLabel, fillColor: '#dcdcdc' },
            { text: this.closeOutDateLabel, fillColor: '#dcdcdc' }];
            responseObverdueProviderActionbody.push(responseObverdueProviderActionColumns);

            if (this.responseOverdueInterfacesProviderAction.length > 0) {
              for (var key in this.responseOverdueInterfacesProviderAction) {
                if (this.responseOverdueInterfacesProviderAction.hasOwnProperty(key)) {
                  var summaryheader = this.responseOverdueInterfacesProviderAction[key];
                  var row = new Array();
                  row.push(summaryheader.Number);
                  row.push({text: summaryheader.ReceivingDiscipline, colSpan : this.isDualDisciplinebool? 0:2});
                  row.push({text: this.isDualDisciplinebool ? summaryheader.ResponsibleDiscipline : "", colSpan : this.isDualDisciplinebool? 0:2});                                 
                
                  row.push(summaryheader.Title);
                  row.push({ text: summaryheader.ReceivingContractor, color: myGlobals.yellow, bold: summaryheader.Status == myGlobals.ClosedStatusId ? true : false });
                  row.push({ text: summaryheader.ResponsibleContractor, color: myGlobals.red, bold: summaryheader.Status == myGlobals.ClosedStatusId ? true : false });
                  row.push(formatDate(summaryheader.FirstResponsePlanned, this.dateTimeFormat, "en-US"));
                  row.push(formatDate(summaryheader.CloseoutPlanned, this.dateTimeFormat, "en-US"));
                  responseObverdueProviderActionbody.push(row);
                }
                if (this.responseOverdueInterfacesProviderAction.hasOwnProperty(key)) {
                  var summaryheader = this.responseOverdueInterfacesProviderAction[key];
                  var row = new Array();
                  row.push({ text: "Description", fillColor: '#dcdcdc', style: '' });
                  row.push({ text: summaryheader.Description, colSpan: 7 });
                  responseObverdueProviderActionbody.push(row);
                }
                if (this.responseOverdueInterfacesProviderAction.hasOwnProperty(key)) {
                  var summaryheader = this.responseOverdueInterfacesProviderAction[key];
                  var row = new Array();
                  row.push({ text: "Comments", fillColor: '#dcdcdc' });
                  row.push({ text: summaryheader.Comments, colSpan: 7 });
                  responseObverdueProviderActionbody.push(row);
                }
                if (this.responseOverdueInterfacesProviderAction.hasOwnProperty(key)) {
                  var summaryheader = this.responseOverdueInterfacesProviderAction[key];
                  var row = new Array();
                  row.push({ text: "", border: [false, true, false, false], colSpan: 8 });
                  responseObverdueProviderActionbody.push(row);
                }
              }
            }
            else {
              var row = new Array();
              row.push({ text: "No Data", colSpan: 8, alignment: 'center' });
              responseObverdueProviderActionbody.push(row);
            }


            //Closeout Overdue Requestor Action Pending
            var closeoutOverdueRequestorActionbody = [];
            var closeoutOverdueRequestorActionColumns = [{ text: "Number", fillColor: '#dcdcdc' }, 
              { text: this.isDualDisciplinebool ? this.requestingDisciplineLabel : 'Discipline' , fillColor: '#dcdcdc', colSpan : this.isDualDisciplinebool? 0:2},
              { text: this.isDualDisciplinebool ? this.providerDisciplineLabel: '', fillColor: '#dcdcdc', colSpan : this.isDualDisciplinebool? 0:2},
              
            { text: "Title", fillColor: '#dcdcdc' }, { text: this.requestingContractorLabel, fillColor: '#dcdcdc' }, { text: this.providerContractorLabel, fillColor: '#dcdcdc' },
            { text: this.expectedResponseDateLabel, fillColor: '#dcdcdc' },
            { text: this.closeOutDateLabel, fillColor: '#dcdcdc' }];
            closeoutOverdueRequestorActionbody.push(closeoutOverdueRequestorActionColumns);

            if (this.closeoutOverdueInterfacesRequestorAction.length > 0) {
              for (var key in this.closeoutOverdueInterfacesRequestorAction) {
                if (this.closeoutOverdueInterfacesRequestorAction.hasOwnProperty(key)) {
                  var summaryheader = this.closeoutOverdueInterfacesRequestorAction[key];
                  var row = new Array();
                  row.push(summaryheader.Number);
                  row.push({text: summaryheader.ReceivingDiscipline, colSpan : this.isDualDisciplinebool? 0:2});
                  row.push({text: this.isDualDisciplinebool ? summaryheader.ResponsibleDiscipline : "", colSpan : this.isDualDisciplinebool? 0:2});     
                  row.push(summaryheader.Title);
                  row.push({ text: summaryheader.ReceivingContractor, color: myGlobals.red, bold: summaryheader.Status == myGlobals.ClosedStatusId ? true : false });
                  row.push({ text: summaryheader.ResponsibleContractor, color: myGlobals.yellow, bold: summaryheader.Status == myGlobals.ClosedStatusId ? true : false});
                  row.push(formatDate(summaryheader.FirstResponsePlanned, this.dateTimeFormat, "en-US"));
                  row.push(formatDate(summaryheader.CloseoutPlanned, this.dateTimeFormat, "en-US"));
                  closeoutOverdueRequestorActionbody.push(row);
                }
                if (this.closeoutOverdueInterfacesRequestorAction.hasOwnProperty(key)) {
                  var summaryheader = this.closeoutOverdueInterfacesRequestorAction[key];
                  var row = new Array();
                  row.push({ text: "Description", fillColor: '#dcdcdc', style: '' });
                  row.push({ text: summaryheader.Description, colSpan: 7 });
                  closeoutOverdueRequestorActionbody.push(row);
                }
                if (this.closeoutOverdueInterfacesRequestorAction.hasOwnProperty(key)) {
                  var summaryheader = this.closeoutOverdueInterfacesRequestorAction[key];
                  var row = new Array();
                  row.push({ text: "Comments", fillColor: '#dcdcdc' });
                  row.push({ text: summaryheader.Comments, colSpan: 7 });
                  closeoutOverdueRequestorActionbody.push(row);
                }
                if (this.closeoutOverdueInterfacesRequestorAction.hasOwnProperty(key)) {
                  var summaryheader = this.closeoutOverdueInterfacesRequestorAction[key];
                  var row = new Array();
                  row.push({ text: "", border: [false, true, false, false], colSpan: 8 });
                  closeoutOverdueRequestorActionbody.push(row);
                }
              }
            }
            else {
              var row = new Array();
              row.push({ text: "No Data", colSpan: 8, alignment: 'center' });
              closeoutOverdueRequestorActionbody.push(row);
            }


            //Closeout Overdue Provider Action Pending
            var closeoutOverdueProviderActionbody = [];
            var closeoutOverdueProviderActionColumns = [{ text: "Number", fillColor: '#dcdcdc' }, 
              { text: this.isDualDisciplinebool ? this.requestingDisciplineLabel : 'Discipline' , fillColor: '#dcdcdc', colSpan : this.isDualDisciplinebool? 0:2},
              { text: this.isDualDisciplinebool ? this.providerDisciplineLabel: '', fillColor: '#dcdcdc', colSpan : this.isDualDisciplinebool? 0:2},            
            { text: "Title", fillColor: '#dcdcdc' }, { text: this.requestingContractorLabel, fillColor: '#dcdcdc' }, { text: this.providerContractorLabel, fillColor: '#dcdcdc' },
            { text: this.expectedResponseDateLabel, fillColor: '#dcdcdc' },
            { text: this.closeOutDateLabel, fillColor: '#dcdcdc' }];
            closeoutOverdueProviderActionbody.push(closeoutOverdueProviderActionColumns);

            if (this.closeoutOverdueInterfacesProviderAction.length > 0) {
              for (var key in this.closeoutOverdueInterfacesProviderAction) {
                if (this.closeoutOverdueInterfacesProviderAction.hasOwnProperty(key)) {
                  var summaryheader = this.closeoutOverdueInterfacesProviderAction[key];
                  var row = new Array();
                  row.push(summaryheader.Number);
                  row.push({text: summaryheader.ReceivingDiscipline, colSpan : this.isDualDisciplinebool? 0:2});
                  row.push({text: this.isDualDisciplinebool ? summaryheader.ResponsibleDiscipline : "", colSpan : this.isDualDisciplinebool? 0:2});     
                  row.push(summaryheader.Title);
                  row.push({ text: summaryheader.ReceivingContractor, color: myGlobals.yellow, bold: summaryheader.Status == myGlobals.ClosedStatusId ? true : false });
                  row.push({ text: summaryheader.ResponsibleContractor, color: myGlobals.red, bold: summaryheader.Status == myGlobals.ClosedStatusId ? true : false});
                  row.push(formatDate(summaryheader.FirstResponsePlanned, this.dateTimeFormat, "en-US"));
                  row.push(formatDate(summaryheader.CloseoutPlanned, this.dateTimeFormat, "en-US"));
                  closeoutOverdueProviderActionbody.push(row);
                }
                if (this.closeoutOverdueInterfacesProviderAction.hasOwnProperty(key)) {
                  var summaryheader = this.closeoutOverdueInterfacesProviderAction[key];
                  var row = new Array();
                  row.push({ text: "Description", fillColor: '#dcdcdc', style: '' });
                  row.push({ text: summaryheader.Description, colSpan: 7 });
                  closeoutOverdueProviderActionbody.push(row);
                }
                if (this.closeoutOverdueInterfacesProviderAction.hasOwnProperty(key)) {
                  var summaryheader = this.closeoutOverdueInterfacesProviderAction[key];
                  var row = new Array();
                  row.push({ text: "Comments", fillColor: '#dcdcdc' });
                  row.push({ text: summaryheader.Comments, colSpan: 7 });
                  closeoutOverdueProviderActionbody.push(row);
                }
                if (this.closeoutOverdueInterfacesProviderAction.hasOwnProperty(key)) {
                  var summaryheader = this.closeoutOverdueInterfacesProviderAction[key];
                  var row = new Array();
                  row.push({ text: "", border: [false, true, false, false], colSpan: 8 });
                  closeoutOverdueProviderActionbody.push(row);
                }
              }
            }
            else {
              var row = new Array();
              row.push({ text: "No Data", colSpan: 8, alignment: 'center' });
              closeoutOverdueProviderActionbody.push(row);
            }


            this.docDefination.push(
              { text: "Status overview of interface group : " + interfaceGroupName, style: "sectionheader", margin: [0, 0, 0, 5] },
              {
                style: "tableExample",
                margin: [0, 0, 0, 10],
                table: {
                  headerRows: 1,
                  body: groupwiseBody
                }
              },
              { text: "Interface items of interface group : " + interfaceGroupName, style: "sectionheader", margin: [0, 0, 0, 5] },
              {
                style: "tableExample",
                margin: [0, 0, 0, 10],
                table: {
                  headerRows: 1,
                  widths: ['10%', '10%', '10%', '30%', '10%', '10%', '10%', '10%'],
                  body: summarybody
                }
              },
              { text: "Response Overdue of interface group : " + interfaceGroupName, style: "sectionheader", margin: [0, 0, 0, 5] },             
              { text: "Provider Action : ", style: "sectionheader", margin: [0, 0, 0, 5] },
              {
                style: "tableExample",
                margin: [0, 0, 0, 10],
                table: {
                  headerRows: 1,
                  widths: ['10%', '10%', '10%', '30%', '10%', '10%', '10%', '10%'],
                  body: responseObverdueProviderActionbody
                }
              },
              { text: "Closeout Overdue of interface group : " + interfaceGroupName, style: "sectionheader", margin: [0, 0, 0, 5] },
              { text: "Requestor Action : ", style: "sectionheader", margin: [0, 0, 0, 5] },
              {
                style: "tableExample",
                margin: [0, 0, 0, 10],
                table: {
                  headerRows: 1,
                  widths: ['10%', '10%', '10%', '30%', '10%', '10%', '10%', '10%'],
                  body: closeoutOverdueRequestorActionbody
                }
              },
            );
          })
          this.pdfGenerateComponentRef.docDefination = this.docDefination;

          this.pdfGenerateComponentRef.generatePdf(this.reportName, this.projectName);

        }
      });
      
      this.reportfilters = [];
      this.stdForm.controls['InterfaceGroupID'].clearValidators();
      this.stdForm.controls['InterfaceGroupID'].updateValueAndValidity();
      this.stdForm.controls['ModuleName'].clearValidators();
      this.stdForm.controls['ModuleName'].updateValueAndValidity();
      this.isGenerateReportButtonEnable = true;
    } catch (e) {
      this.reportfilters = [];
      this.isGenerateReportButtonEnable = true;
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }


  onModuleChange() {
    try {
      this.isGenerateReportButtonEnable = false;
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }
}
