import { Document } from "./document";
import { InterfaceResponseDetails } from "./interfaceResponseDetails";
import { ReferenceLink } from "./referenceLink";
import { Discussion } from "./discussion";
import { InterfaceProjectFieldsMapping } from "./InterfaceProjectFieldsMapping";
import { InterfaceGroup } from "./interfaceGroup";
import { TechnicalQuery } from "./technicalQuery";
import { MinutesOfMeetingDetails } from "./minutesOfMeetingDetails";
import { Stage } from "./stage";
import { AdditionalInformationHistory } from "./additionalInformationHistory";
import { User } from "./user";
import { AssignmentHistory } from "./assignmentHistory";
import { ProposedResponseHistory } from "./proposedResponseHistory";
import { Role } from "./role";
import { Project } from "./project";
import { Module } from "./module";
import { UserRoleProject } from "./userroleproject";


export class Interface {
  Id: number = 0;
  Number?: string = "";
  Title?: string = "";
  Description: string = "";
  FirstIssuePlannedstr?: string;
  FirstIssuePlanned?: Date;
  FirstIssueActual?: Date;
  ApprovedIssuePlannedstr?: string;
  ApprovedIssuePlanned?: Date;
  ApprovedIssueActual?: Date;
  Comments: string = "";
  ResponsibleContractorDisciplineID?: number;
  ReceivingContractorDisciplineID?: number;
  InterfaceGroupID?: number;
  ResponsibleContractorID: number = 0;
  ReceivingContractorID: number = 0;
  Status: number = 0;
  Response: string = "";
  ReferenceForResponse: string = "";
  NotificationComments: string = "";
  CompletedDate?: Date;
  CompletedBy?: number;
  Priority: number = 0;
  InterfaceType: string = "";
  IsApprovedIssuePlannedOverdue: boolean = false;
  IsFirstIssuePlannedOverdue: boolean = false;
  IsOverdue: boolean = false;
  AcceptedBy?: number;
  AcceptedDate?: Date;
  RevisionFromId?: number;
  ProjectId: number = 0;
  ProviderFirstIssuePlanned?: Date;
  ProviderApprovedIssuePlanned?: Date;
  ApprovedOpenDate?: Date;
  IsAcceptedAsPreliminary: boolean = false;
  ApprovedClosedDate?: Date;
  StageId: number = 0;
  StatusShortName: string = "";
  FilePathName: string = "";
  InterfaceStatusName: String = '';
  IsStageRejected: boolean = false;
  StageApproveRejectComment?: string = "";
  StageApproveRejectDate?: Date;
  StageApproveRejectBy: Number = 0;
  NumberGenerationPattern?: string = "";
  RequestApproverId?: number;
  CreatedByRoleId?: number;
  ModuleId?: number;
  IsDeleted: boolean = false;
  CreatedDate?: Date;
  CreatedBy?: number;
  ModifiedDate?: Date;
  ModifiedBy?: number;
  ResDiscipline?:any;
  RecDiscipline?:any;
  InterfaceGroup?:InterfaceGroup;
  ResContractor?: any;
  RecContractor?:any; 
  InterfaceStatus?:any;
  Prioritys?:any;
  Documents : Document[]=[];
  ReferenceLinks: ReferenceLink[] = [];
  CreatedUserRoleMapping?: UserRoleProject;
  CompletedByUser?: any;
  AcceptedByUser?: any;
  InterfaceResponses: InterfaceResponseDetails[] = [];
  RevisionFrom: any;
  Revisions: Interface[] = [];
  Project?: Project;
  Stage?: Stage;
  Discussions: Discussion[] = [];
  TechnicalQuery?: TechnicalQuery;
  InterfaceProjectFieldsMappings: InterfaceProjectFieldsMapping[] = [];
  MinutesOfMeetingDetails: MinutesOfMeetingDetails[] = [];
  RequestApprover?: User;
  AdditionalInformationHistory: AdditionalInformationHistory[] = [];
  AssignmentHistory: AssignmentHistory[] = [];
  ProposedResponseHistory: ProposedResponseHistory[] = [];
  CreatedByRole?: Role;
  Module?: Module;
}
