import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { response } from '../app_data/OdataResponse';
import { ModuleProjectMapping } from '../app_data/moduleProjectMapping';

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
    "Accept": "*/*",
  })
}

@Injectable({
  providedIn: 'root'
})
export class ModuleProjectMappingsService {

  private apiUrl = environment.apiURl + 'ModuleProjectMappings';

  constructor(private http: HttpClient) { }

  getModulesByProjectId(projectId: number): Observable<ModuleProjectMapping[]> {
    return this.http.get<response>(this.apiUrl + "?$expand=Module($expand=ModulePermissionMappings($filter= IsProjectWise eq true;$expand=Permission))&$filter=ProjectId eq " + projectId + " and IsDeleted eq false ")
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  getModulesByProjectIdWithPromise(projectId: number): Promise<any> {
    return this.http.get<response>(this.apiUrl + "?$expand=Module($expand=ModulePermissionMappings($filter= IsProjectWise eq true;$expand=Permission))&$filter=ProjectId eq " + projectId + " and IsDeleted eq false ").toPromise();
  }
  
  private handleError(err: HttpErrorResponse) {

    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {

      errorMessage = `An error occurred: ${err.error.message}`;
    } else {

      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    return throwError(errorMessage);
  }
}
