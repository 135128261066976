import { Input } from '@angular/core';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { NGXLogger } from 'ngx-logger';
import { SystemLog } from '../../app_data/systemLog';
import { ProjectSettingService } from '../../services/projectsetting.service';
import { SystemLogService } from '../../services/systemLog.service';
import * as myGlobals from '../../app_data/globals';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogService } from '../../services/dialog.service';
import { InterfaceService } from '../../services/interface.service';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { CommonFunction } from 'src/app/app_data/commonFunction';
import { ProjectService } from 'src/app/services/project.service';
import { Title } from '@angular/platform-browser';
import { RolePermissionService } from 'src/app/services/rolepermission.service';

let SystemLog_DATA: SystemLog[] = [];
let MY_FORMATS = {
  parse: {
    dateInput: "LL"
  },
  display: {
    dateInput: "LL",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY"
  }
};

@Component({
  selector: 'app-interface-log',
  templateUrl: './interface-log.component.html',
  styleUrls: ['./interface-log.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    { provide: MAT_DATE_LOCALE, useValue: 'en-US' },

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})


export class InterfaceLogComponent implements OnInit {
  datePickerConfig: Partial<BsDatepickerConfig> = {};
  pageTitle = 'Logs';
  _interfaceId!: number;
  errorMessage = '';
  _projectID = parseInt(sessionStorage.getItem("projectID")!);
  _userRoleId = parseInt(sessionStorage.getItem("roleId")!);
  _projectTimeZone = sessionStorage.getItem("projectTimeZone")!;
  utcoffsetvalue: string = sessionStorage.getItem("projectTimeZone")!.substring(0, sessionStorage.getItem("projectTimeZone")!.lastIndexOf(':'))!;
  displayedColumns: string[] = ['CreateDate', 'GeneratedUser_FirstName','SystemFunction', 'Description'];
  interfaceLogDataSource = new MatTableDataSource<SystemLog>(SystemLog_DATA);
  fromDate = '';
  @Input() calledOnActionButtonClick = true;
  interfaceGroup = '';
  interfaceNumber = '';
  dateTimeFormat: string = "";
  dateRange = '';
  dpColorTheme: string = "theme-dark-blue";
  validRecord: boolean = false;

  rangeFormGroup = new FormGroup({
    fromDate: new FormControl(),
    toDate: new FormControl(),
    dateRange: new FormControl(),
  });


  @ViewChild('sortlog') sortlog !: MatSort;
  @ViewChild('paginatorLog') paginatorLog !: MatPaginator;
  commonFunction!: CommonFunction;
  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sortlog = ms;
    this.interfaceLogDataSource.paginator = this.paginatorLog;
    this.interfaceLogDataSource.sort = this.sortlog;
  }

  constructor(private systemLogService: SystemLogService, private projectSettingService: ProjectSettingService, private router: Router,
    private route: ActivatedRoute, private logger: NGXLogger, private dialog: DialogService, private interfaceService:InterfaceService,
    private activatedRoute: ActivatedRoute,  private title: Title,
    private projectService: ProjectService,private rolePermissionService: RolePermissionService,
    private SpinnerService: NgxSpinnerService  ) {
    this.getProjectSettings();
    this.commonFunction = new CommonFunction(router, projectService, rolePermissionService, activatedRoute, title);
  }

  ngOnInit(): void {
    this.commonFunction.setPageTitle();
    this._interfaceId = this.route.snapshot.params['id']
    this.checkIfInterfaceIdValid(this._interfaceId);

  }

  checkIfInterfaceIdValid(Id: number) {
    try {
      this.SpinnerService.show();
      this.interfaceService.getInterfaceByIdandProjectId(Id, this._projectID).subscribe({
        next: data => {
          this.validRecord = data.length > 0 ? true : false;
          if (this._interfaceId > 0)
            if (this.validRecord) {
              this.getSystemLog(this._interfaceId, "");
            }
            else {
              this.router.navigateByUrl("/AccessDenied");
            }
          else {
            this.getSystemLogByProjectId("");
          }
        }
      });
    }
    catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  getProjectSettings() {
    try {
      this.SpinnerService.show();
      this.projectSettingService.getProjectSettings(this._projectID).subscribe({
        next: projectSettings => {
          if (projectSettings.length > 0) {
            this.dateTimeFormat = projectSettings.filter(x => x.ConfigKey == "DateTimeFormat")[0].ConfigValue;
            MY_FORMATS.parse.dateInput = this.dateTimeFormat.replace("dd", "DD");
            MY_FORMATS.display.dateInput = this.dateTimeFormat.replace("dd", "DD");
            MY_FORMATS.display.dateA11yLabel = this.dateTimeFormat.replace("dd", "DD");
            var index = projectSettings.findIndex(x => x.ConfigKey == "UTCOffset");
            if (index > -1) {
              var offsetValue = projectSettings.filter(x => x.ConfigKey == "UTCOffset")[0].ConfigValue.split("|")[0]!;
              this.utcoffsetvalue = offsetValue.substring(0, offsetValue.lastIndexOf(':'))!;
            }
            this.datePickerConfig = Object.assign({}, {
              containerClass: this.dpColorTheme,
              showWeekNumbers: false,
              dateInputFormat: this.dateTimeFormat.toUpperCase(),
              rangeInputFormat: this.dateTimeFormat.toUpperCase()
            });
            this.SpinnerService.hide();
          }
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      });
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  getSystemLogByProjectId(filter: string) {
    try {
      this.SpinnerService.show();
      this.systemLogService.getSystemLogByProjectId(this._projectID, filter).subscribe(
        {
          next: logs => {
            this.interfaceLogDataSource.data = logs;
            this.SpinnerService.hide();
            
          },
          error: err => {
            this.SpinnerService.hide();
            this.errorMessage = err
            this.dialog.confirmDialog({
              title: 'Error',
              message: myGlobals.exceptionmessage,
              cancelCaption: 'Close',
            });
            this.logger.error(err);
          }
        }
      )
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  getSystemLog(interfaceId: number, filter: string) {
    try {
      this.SpinnerService.show();
      this.systemLogService.getSystemLog(this._projectID, interfaceId, filter).subscribe(
        {
          next: logs => {
            this.interfaceLogDataSource.data = logs;
            if (this.interfaceLogDataSource.data.length > 0) {
              this.interfaceGroup = this.interfaceLogDataSource.data[0].LogInterfaceId!.InterfaceGroup!.Code;
              this.interfaceNumber = this.interfaceLogDataSource.data[0].LogInterfaceId!.Number!;
            }
            this.SpinnerService.hide();
          },
          error: err => {
            this.SpinnerService.hide();
            this.errorMessage = err
            this.dialog.confirmDialog({
              title: 'Error',
              message: myGlobals.exceptionmessage,
              cancelCaption: 'Close',
            });
            this.logger.error(err);
          }
        }
      )
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  applyDateFilter() {
    try {
      const dateRange = this.rangeFormGroup.controls['dateRange'].value === undefined ? '' : this.rangeFormGroup.controls['dateRange'].value;

      if (dateRange.length > 0) {
        var startDate = dateRange[0];
        var endDate = dateRange[1];

        if (startDate !== null && startDate !== undefined && endDate !== null && endDate !== undefined) {

          var startDateFormat = this.formatDate(startDate, 'startdate');
          var endDateFormat = this.formatDate(endDate, 'enddate');

          let advFilter = " and CreateDate gt " + startDateFormat + " and CreateDate lt " + endDateFormat;

          this.getSystemLog(this._interfaceId, advFilter);
        }
        else {
          this.getSystemLog(this._interfaceId, "");

        }
      }
      else
        this.getSystemLog(this._interfaceId, "");
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  clearDateFilter() {
    this.rangeFormGroup.reset();
  }


  formatDate(date: any, rangeType: string) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + (d.getDate()),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-') + ((rangeType.toLowerCase() === 'startdate') ? 'T00:00:00.00Z' : 'T23:59:59.99Z');

  }


  onBackButtonClick() {
    this.redirectToBackPage();
  }


  redirectToBackPage() {
    this.router.navigateByUrl('/Interfaces');
  }
}
