import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NGXLogger } from 'ngx-logger';
import { Contractor } from '../../../app_data/contractor';
import { InterfaceGroup } from '../../../app_data/interfaceGroup';
import { ReportFilter } from '../../../app_data/reportFilter';
import { ReportSearchFilter } from '../../../app_data/reportSearchFilter';
import { Summary } from '../../../app_data/summary';
import { ContractorService } from '../../../services/contractor.service';
import { DialogService } from '../../../services/dialog.service';
import { InterfaceGroupService } from '../../../services/interfaceGroup.service';
import { ReportService } from '../../../services/report.service';
import { ApexAnnotations, ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexFill, ApexGrid, ApexLegend, ApexNonAxisChartSeries, ApexPlotOptions, ApexResponsive, ApexStroke, ApexTitleSubtitle, ApexXAxis } from 'ng-apexcharts';
import * as myGlobals from '../../../app_data/globals';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { DateAdapter, MatOption, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { PdfGenerateComponent } from '../pdf-generate/pdf-generate.component';
import { ProjectService } from '../../../services/project.service';
import { DatePipe, formatDate } from '@angular/common';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { CommonFunction } from '../../../app_data/commonFunction';
import { ModuleProjectMappingsService } from 'src/app/services/moduleprojectmappings.service';
import { ModuleProjectMapping } from 'src/app/app_data/moduleProjectMapping';

let MY_FORMATS = {
  parse: {
    dateInput: "LL"
  },
  display: {
    dateInput: "LL",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY"
  }
};
export type ChartOptions = {
  series: ApexNonAxisChartSeries | ApexAxisChartSeries;
  chart: ApexChart;
  plotOptions: ApexPlotOptions;
  labels: any;
  fill: ApexFill;
  title: ApexTitleSubtitle;
  legend: ApexLegend;
  dataLabels: ApexDataLabels;
  responsive: ApexResponsive;
  annotations: ApexAnnotations;
  colors: string[];
  xaxis: ApexXAxis;
  grid: ApexGrid;
  stroke: ApexStroke;
};
@Component({
  selector: 'app-summary-report',
  templateUrl: './summary-report.component.html',
  styleUrls: ['./summary-report.component.css'],
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    { provide: MAT_DATE_LOCALE, useValue: 'en-US' },

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class SummaryReportComponent implements OnInit {
  docDefination: any;
  summaryForm !: FormGroup;
  errorMessage!: string;
  selectedContractor!: number;
  selectedContractorValue!: string;
  selectedInterfaceGroup!: number[];
  selectedGrpValue!: string;
  selectedModule!: string;
  contractors: Contractor[] = [];
  interfaceGroups: InterfaceGroup[] = [];
  reportfilters: ReportSearchFilter[] = [];
  reportFilter!: ReportFilter;
  contractorCount!: number;
  interfaceGroupCount!: number;
  todayDate!:string;
  utcoffsetvalue: string = sessionStorage.getItem("projectTimeZone")!.substring(0, sessionStorage.getItem("projectTimeZone")!.lastIndexOf(':'))!;
  dsSummarys: Summary[] = [];
  totalIdentified: number = 0;
  moduleProjectMappings: ModuleProjectMapping[]=[];
  totalOpen: number = 0;
  responseOverdue: number = 0;
  closeoutOverdue: number = 0;
  totalClosed: number = 0;
  totalFinal: number = 0;
  columnWidth: string = "";
  reportName: string = "Summary Report";
  @ViewChild('InterfaceGroupIDInput') select!: MatSelect;
  @ViewChild('ContractorIDInput') Conselect!: MatSelect;
  allSelected = false;
  allSelectedContractor = false;
  public chartOptions!: Partial<ChartOptions>;
  dateTimeFormat: string = '';
  providerContractorLabel: string = myGlobals.ProviderContractorLabel;
  requestingContractorLabel: string = myGlobals.RequestingContractorLabel;
  providerDisciplineLabel: string = myGlobals.ProviderDisciplineLabel;
  requestingDisciplineLabel: string = myGlobals.RequestingDisciplineLabel;
  expectedResponseDateLabel: string = myGlobals.ExpectedResponseDateLabel;
  closeOutDateLabel: string = myGlobals.CloseOutDateLabel;
  projectName: string = sessionStorage.getItem("projectName")!;
  _projectTimeZone = sessionStorage.getItem("projectTimeZone")!;
  projectStartDate!: Date;
  pipe = new DatePipe('en-US');
  isContractorDropdownShow: boolean = false;
  isGenerateReportButtonEnable: boolean = true;
  maxDate: Date = CommonFunction.ConvertUTCToTimeZoneTime(CommonFunction.ConvertLocalTimeToUTC(new Date()), this._projectTimeZone, false);

  _projectID = parseInt(sessionStorage.getItem("projectID")!);
  _contractorID = parseInt(sessionStorage.getItem("contractorId")!);
  _userIdLogin = parseInt(sessionStorage.getItem("userId")!);
  _userRoleId = parseInt(sessionStorage.getItem("roleId")!);
  _rolePermissions = sessionStorage.getItem("rolePermissions")!;

  @ViewChild('PdfGenerateComponent') pdfGenerateComponentRef!: PdfGenerateComponent;

  constructor(private contractorService: ContractorService,
    private interfaceGroupService: InterfaceGroupService,
    private dialog: DialogService, private projectService: ProjectService,
    private moduleProjectMappingService: ModuleProjectMappingsService,
    private reportService: ReportService, private SpinnerService: NgxSpinnerService,
    private logger: NGXLogger) {
    this.reportFilter = new ReportFilter();
    this.reportFilter.FromDate = "";
    this.reportFilter.ToDate = "";
    this.chartOptions = {
      series: [
        {
          name: "Identified",
          data: [44]
        },
        {
          name: "Open",
          data: [13]
        },
        {
          name: "Response Overdue",
          data: [11]
        },
        {
          name: "Closeout Overdue",
          data: [12]
        },
        {
          name: "Closed",
          data: [21]
        }
      ],
      chart: {
        type: "bar",
        height: 450,
        stacked: true,
        toolbar: {
          show: true
        },
        zoom: {
          enabled: true
        }
      },
      plotOptions: {
        bar: {
          horizontal: false
        }
      },
      xaxis: {
        type: "category",
        categories: [
          "Group 1"
        ]
      },
      legend: {
        position: 'top',
      },
      fill: {
        opacity: 1
      }
    };
  }

  ngOnInit(): void {
    this.summaryForm = new FormGroup({
      ContractorID: new FormControl(''),
      InterfaceGroupID: new FormControl('', [Validators.required]),
      Description: new FormControl(''),
      ModuleName: new FormControl('', [Validators.required]),
      FromDate: new FormControl(""),
      ToDate: new FormControl("")
    });
    this.getProjectSettings();
    if (this._userRoleId == Number(myGlobals.ProjectAdminRoleId)) {
      this.isContractorDropdownShow = true;
      this.getContractors(this._projectID);
    }
    else {
      this.getInterfaceGroupsByContractor([this._contractorID], this._projectID);
    }
    this.getModules(this._projectID);
  }

  toggleAllSelection() {
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }

    this.toggleDisableModuleDropdown();
  }

  optionClick() {
    let newStatus = true;
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
    this.toggleDisableModuleDropdown();
  }


  toggleDisableModuleDropdown(){
    this.summaryForm.controls['ModuleName'].reset();
    
    if(this.selectedInterfaceGroup.length <= 0){
      this.summaryForm.controls['ModuleName'].disable();
    }else{
      this.summaryForm.controls['ModuleName'].enable();
    }
    
    this.isGenerateReportButtonEnable = true;
  }

  toggleAllSelectionContractor() {
    if (this.allSelectedContractor) {
      this.Conselect.options.forEach((item: MatOption) => item.select());
    } else {
      this.Conselect.options.forEach((item: MatOption) => item.deselect());
    }
  }

  contractoroptionClick() {
    let newconStatus = true;
    this.Conselect.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newconStatus = false;
      }
    });
    this.allSelectedContractor = newconStatus;
  }

  getProjectSettings() {
    try {
      this.SpinnerService.show();
      this.projectService.getProject(this._projectID).subscribe({
        next: project => {
          if (project[0].Settings.length > 0) {
            this.dateTimeFormat = project[0].Settings.filter(x => x.ConfigKey == "DateTimeFormat")[0].ConfigValue;
            this.projectStartDate = project[0].CreatedDate!;
            
            MY_FORMATS.parse.dateInput = this.dateTimeFormat.replace("dd", "DD");
            MY_FORMATS.display.dateInput = this.dateTimeFormat.replace("dd", "DD");
            MY_FORMATS.display.dateA11yLabel = this.dateTimeFormat.replace("dd", "DD");
            this.summaryForm.controls['FromDate'].setValue(this.projectStartDate);
            
            var index = project[0].Settings.findIndex(x => x.ConfigKey == "UTCOffset");
            if (index > -1) {
              var offsetValue = project[0].Settings.filter(x => x.ConfigKey == "UTCOffset")[0].ConfigValue.split("|")[0]!;
              this.utcoffsetvalue = offsetValue.substring(0, offsetValue.lastIndexOf(':'))!;
            }

            var index = project[0].Settings.findIndex(x => x.ConfigKey == "ProviderContractorName");
            if (index > -1) {
              this.providerContractorLabel = project[0].Settings.filter(x => x.ConfigKey == "ProviderContractorName")[0].ConfigValue;
            }
            var index = project[0].Settings.findIndex(x => x.ConfigKey == "RequestingContractorName");
            if (index > -1) {
              this.requestingContractorLabel = project[0].Settings.filter(x => x.ConfigKey == "RequestingContractorName")[0].ConfigValue;
            }
            var index = project[0].Settings.findIndex(x => x.ConfigKey == "ProviderDisciplineName");
            if (index > -1) {
              this.providerDisciplineLabel = project[0].Settings.filter(x => x.ConfigKey == "ProviderDisciplineName")[0].ConfigValue;
            }
            var index = project[0].Settings.findIndex(x => x.ConfigKey == "RequestingDisciplineName");
            if (index > -1) {
              this.requestingDisciplineLabel = project[0].Settings.filter(x => x.ConfigKey == "RequestingDisciplineName")[0].ConfigValue;
            }
            var index = project[0].Settings.findIndex(x => x.ConfigKey == "ExpectedResponseDateName");
            if (index > -1) {
              this.expectedResponseDateLabel = project[0].Settings.filter(x => x.ConfigKey == "ExpectedResponseDateName")[0].ConfigValue;
            }
            var index = project[0].Settings.findIndex(x => x.ConfigKey == "CloseOutDateName");
            if (index > -1) {
              this.closeOutDateLabel = project[0].Settings.filter(x => x.ConfigKey == "CloseOutDateName")[0].ConfigValue;
            }

          }
          this.todayDate = this.pipe.transform(new Date(), 'yyyy-MM-dd', this.utcoffsetvalue)!;
          this.summaryForm.controls['ToDate'].setValue(this.todayDate);
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      });

    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  getContractors(projectId: number) {
    try {
      this.contractorService.getContractorsByProjectId(projectId).subscribe({
        next: contractors => {
          this.contractors = contractors.sort((a, b) => a.Id! > b.Id! ? 1 : -1);
        },
        error: err => {
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  getModules(projectId: number) {
    try {
      this.SpinnerService.show();
      this.moduleProjectMappingService.getModulesByProjectId(projectId).subscribe({
        next: moduleProjectMappings => {
          moduleProjectMappings.forEach(x => {
            var a = x.Module?.ModulePermissionMappings?.filter(v => v.IsProjectWise)
            var permissionindex = this._rolePermissions.indexOf(a![0].Permission?.Name!);
            if (permissionindex == -1) {
              x.IsDeleted = true;
            }
          });
          this.moduleProjectMappings = moduleProjectMappings.filter(x => !x.IsDeleted);
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  onChangevalue(ev: MatSelectChange) {
    this.getInterfaceGroupsByContractor(ev.source.value, this._projectID)
  }

  getInterfaceGroupsByContractor(contractorIds: number[], projectId: number) {
    try {
      this.SpinnerService.show();

      //Get Interface Groups on project and selected contractors.
      if (contractorIds.length > 0) {
        this.interfaceGroupService.getInterfaceGroupsbyProjectAndContractors(projectId, contractorIds).subscribe({
          next: interfaceGroups => {
            this.SpinnerService.hide();
            this.interfaceGroups = interfaceGroups.sort((a, b) => a.Id! > b.Id! ? 1 : -1);
            this.selectedInterfaceGroup = interfaceGroups.length > 0 ? [interfaceGroups[0].Id!] : [0];
            this.selectedGrpValue = interfaceGroups.length > 0 ? interfaceGroups[0].Name : '';
            this.summaryForm.controls['ModuleName'].enable();
          },
          error: err => {
            this.SpinnerService.hide();
            this.errorMessage = err
            this.dialog.confirmDialog({
              title: 'Error',
              message: myGlobals.exceptionmessage,
              cancelCaption: 'Close',
            });
            this.logger.error(err);
          }
        })
      } else {
        this.interfaceGroupService.getInterfaceGroupsforProjectAdmin(projectId).subscribe({
          next: interfaceGroups => {
            this.SpinnerService.hide();
            this.interfaceGroups = interfaceGroups.sort((a, b) => a.Id! > b.Id! ? 1 : -1);
            this.selectedInterfaceGroup = interfaceGroups.length > 0 ? [interfaceGroups[0].Id!] : [0];
            this.selectedGrpValue = interfaceGroups.length > 0 ? interfaceGroups[0].Name : '';
            this.summaryForm.controls['ModuleName'].enable();
          },
          error: err => {
            this.SpinnerService.hide();
            this.errorMessage = err
            this.dialog.confirmDialog({
              title: 'Error',
              message: myGlobals.exceptionmessage,
              cancelCaption: 'Close',
            });
            this.logger.error(err);
          }
        })
      }

    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  onDateChange(type: string, event: any) {
    this.summaryForm.controls['ModuleName'].setValue('');
  }

  async onModuleChangeGeneratePDF(reportfilter: ReportFilter) {
    try {
      this.SpinnerService.show();
      var toDateString = formatDate(new Date(), this.dateTimeFormat, "en-US")
       //check selected interfacegroups is multiple or single
      if (this.selectedInterfaceGroup.length > 1) {
        this.columnWidth = "30"; //set when multiple interface groups 
      } else {
        this.columnWidth = "15"; //set when single interface group 
      }

      if (reportfilter.FromDate == "") {
        reportfilter.FromDate = String(this.projectStartDate);
      }
      if (reportfilter.ToDate == "") {
        reportfilter.ToDate = String(toDateString);
      }
      this.totalIdentified = 0; this.totalOpen = 0; this.responseOverdue = 0; this.closeoutOverdue = 0; this.totalClosed = 0; this.totalFinal = 0;
      this.reportfilters.push({ KeyName: "InterfaceGroupId", Value: String(reportfilter.InterfaceGroupID) }, { KeyName: "ProjectId", Value: String(this._projectID) },
        { KeyName: "InterfaceType", Value: String(reportfilter.ModuleName) }, { KeyName: "FromDate", Value: formatDate(reportfilter.FromDate!, this.dateTimeFormat, "en-US") },
        { KeyName: "ToDate", Value: formatDate(reportfilter.ToDate!, this.dateTimeFormat, "en-US") })
      let data = await this.reportService.postWithPromise(this.reportfilters, "FetchSummaryReport");
      if(data != null){
        this.contractorCount = data!.ProjectSummaryCount[0].ContractorCount;
            this.interfaceGroupCount = data!.ProjectSummaryCount[0].InterfaceGroupCount;
            this.projectName = data!.ProjectSummaryCount[0].ProjectName;
            this.dsSummarys = data!.Summary;

            data!.Summary.forEach((x:any) => {
              this.totalIdentified += x.Identified;
              this.totalOpen += x.Open;              
              this.responseOverdue += x.ResponseOverdue;
              this.closeoutOverdue += x.CloseoutOverdue;
              this.totalClosed += x.Closed;
              this.totalFinal += x.Total;
            });
            var cate: string[] = [];
            var iden: number[] = [];
            var open: number[] = [];
            var rsod: number[] = [];
            var clod: number[] = [];
            var clos: number[] = [];

            this.dsSummarys.forEach(x => {
              cate.push(x.InterfaceGroup)
              iden.push(x.Identified)
              open.push(x.Open)
              rsod.push(x.ResponseOverdue)
              clod.push(x.CloseoutOverdue)
              clos.push(x.Closed)
            });
            
            this.chartOptions = {
              series: [
                {
                  name: "Identified",
                  data: iden,
                  color: myGlobals.identifiedcolor
                },
                {
                  name: "Open",
                  data: open,
                  color: myGlobals.opencolor
                },
                {
                  name: "Response Overdue",
                  data: rsod,
                  color: myGlobals.responseoverduecolor
                },
                {
                  name: "Closeout Overdue",
                  data: clod,
                  color: myGlobals.overduecolor
                },
                {
                  name: "Closed",
                  data: clos,
                  color: myGlobals.closedcolor
                }
              ],
              chart: {
                type: "bar",
                height: 450,
                stacked: true,
                toolbar: {
                  show: true
                },
                zoom: {
                  enabled: true
                }
              },
              plotOptions: {
                bar: {
                  horizontal: false,
                  columnWidth: this.columnWidth,
                  rangeBarOverlap: false
                }
              },
              xaxis: {
                type: "category",
                categories: cate,
                labels: {
                  style: {
                    fontSize: '0.5rem'
                  }
                }
              },
              legend: {
                position: 'top',
              },
              fill: {
                opacity: 1
              }
            };
           
      }

      this.reportfilters = [];
      setTimeout(() => { 
        this.SpinnerService.hide();
        this.isGenerateReportButtonEnable = false;
      }, 2000);

     
    } catch (e) {
      this.reportfilters = [];
      this.isGenerateReportButtonEnable = true;
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  async ShowPDf() {
    try {
      this.SpinnerService.show();
      var body = [];
    var columns = [{ text: "Interface Group", fillColor: '#dcdcdc' }, { text: this.requestingContractorLabel, fillColor: '#dcdcdc' }
      , { text: this.providerContractorLabel, fillColor: '#dcdcdc' }, { text: "Identified", fillColor: '#dcdcdc' }, { text: "Open", fillColor: '#dcdcdc' }, { text: "Response Overdue", fillColor: '#dcdcdc' }, { text: "Closeout Overdue", fillColor: '#dcdcdc' }
      , { text: "Closed", fillColor: '#dcdcdc' }, { text: "Total", fillColor: '#dcdcdc' }];
    body.push(columns);
    for (var key in this.dsSummarys) {
      if (this.dsSummarys.hasOwnProperty(key)) {
        var header = this.dsSummarys[key];
        var row = new Array();
        row.push(header.InterfaceGroup);
        row.push(header.Contractor1);
        row.push(header.Contractor2);
        row.push(header.Identified);
        row.push(header.Open);
        row.push(header.ResponseOverdue);
        row.push(header.CloseoutOverdue);
        row.push(header.Closed);
        row.push(header.Total);
        body.push(row);
      }
    }
    
    this.docDefination = [
      { text: "Report generated for dates from " + formatDate(this.reportFilter.FromDate!, this.dateTimeFormat, "en-US") + " to " + formatDate(this.reportFilter.ToDate!, this.dateTimeFormat, "en-US"), style: "sectionheader", margin: [0, 0, 0, 5] },
      { text: "Overview of project " + this.projectName, style: "sectionheader", margin: [0, 0, 0, 5] },

      {
        style: "tableExample",
        margin: [0, 0, 0, 10],
        table: {
          body: [
            ["Total number of contractors", this.contractorCount],
            ["Total number of interface groups", this.interfaceGroupCount]
          ]
        }
      },
      { text: "Status overview of selected interface groups", style: "sectionheader", margin: [0, 0, 0, 5] },
      {
        style: "tableExample",
        margin: [0, 0, 0, 10],
        table: {
          headerRows: 1,
          body: body
        }
      },
      { text: "Chart representation of statuswise selected interface groups", style: "sectionheader", margin: [0, 0, 0, 5] },
      {
        image: await this.getBase64Image(),
        width: 500,
      },
    ];
    this.summaryForm.reset();
    this.summaryForm.controls['InterfaceGroupID'].clearValidators();
    this.summaryForm.controls['InterfaceGroupID'].updateValueAndValidity();
    this.summaryForm.controls['FromDate'].setValue(this.projectStartDate);
    this.summaryForm.controls['ToDate'].setValue(this.todayDate);
    this.summaryForm.controls['ModuleName'].disable();
    this.allSelectedContractor = false;
    this.allSelected = false;    
    this.pdfGenerateComponentRef.docDefination = this.docDefination;
    this.pdfGenerateComponentRef.generatePdf(this.reportName, this.projectName);
    this.isGenerateReportButtonEnable = true;
    this.SpinnerService.hide();    

    } catch (e) {
      this.isGenerateReportButtonEnable = true;
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }    
  }

  async getBase64Image() {
    return new Promise((resolve, reject) => {
      const img = new Image();
      const svgElement: SVGGraphicsElement =
        document.querySelector('.apexcharts-svg')!;
      const imageBlobURL =
        'data:image/svg+xml;charset=utf-8,' +
        encodeURIComponent(svgElement.outerHTML);
      img.onload = () => {
        var canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx!.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL('image/png');
        resolve(dataURL);
      };
      img.onerror = (error) => {
        reject(error);
      };
      img.src = imageBlobURL;
    });
  }
}
