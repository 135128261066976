export const environment = {
  environrmentMode:"Prod",
  appVersion: require('../../package.json').version,
  production: true,
  scopes: ["https://webinterfaceregister.onmicrosoft.com/wirapi/Read"],
  apiURl: 'https://api.webinterfaceregister.com/odata/',
  apiDomainURL: 'https://api.webinterfaceregister.com/Notify',
  reportAPIURL: 'https://reports.webinterfaceregister.com/api/ReportDatas/',
  storageAPIURL: 'https://storage.webinterfaceregister.com/api/Files/',
  imageURl: "/assets/images/",
  iconURl: "/assets/icons/",
  documentURl: "/assets/",
  storageAccessKey: "v/fKTIiA37Lejg8DFHWuvWvBI4F0ABmC2/QciwD77dhvuaPaTSeQAWVCluvD7jCZN98YUUgkouhc+AStjTTnIg==",
  storageContainerName: "files",
  storageAccountName: "wirv2prodstorage",
  baseURL: "https://webinterfaceregister.com/#/",
  //#region  Authenication Azure B2C config
  b2cPolicies : {
    names: {
        signUpSignIn: "B2C_1_signup_signin_wir",
        editProfile: "",
        paaswordReset:""
    },
    authorities: {
        signUpSignIn: {
        authority: "https://webinterfaceregister.b2clogin.com/webinterfaceregister.onmicrosoft.com/B2C_1_signup_signin_wir",
        },
        editProfile: {
            authority: ""
        },
        passwordReset:{
            authority: ""
        }
        
    },
    authorityDomain: "webinterfaceregister.b2clogin.com"
},
  b2cClientId: '36ebd3c4-a4a3-4ca6-b2a0-21c046f7adaa',
  redirectUri: 'https://webinterfaceregister.com',
  postLogoutRedirectUri: 'https://webinterfaceregister.com',
//#endregion  
};
