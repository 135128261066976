import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Contractor } from '../../app_data/contractor';
import { User } from '../../app_data/user';
import { UserContractorMapping } from '../../app_data/userContractorMapping';
import { CommunicationService } from '../../services/communication.service';
import { ContractorService } from '../../services/contractor.service';
import { NGXLogger } from 'ngx-logger';
import * as myGlobals from '../../app_data/globals';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogService } from '../../services/dialog.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Contact } from 'src/app/app_data/contact';
import { ContactService } from 'src/app/services/contact.service';
import { SnackbarComponent } from '../../shared/components/snackbar/snackbar.component';
import { ContactContractorMapping } from '../../app_data/contactContractorMapping';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { Title } from '@angular/platform-browser';
import { ProjectService } from 'src/app/services/project.service';
import { RolePermissionService } from 'src/app/services/rolepermission.service';
import { CommonFunction } from 'src/app/app_data/commonFunction';

let ContactLists: ContactContractorMapping[] = [];

@Component({
  selector: 'app-contact-edit',
  templateUrl: './contact-edit.component.html',
  styleUrls: ['./contact-edit.component.css'],
  encapsulation: ViewEncapsulation.None
})


export class ContactEditComponent implements OnInit {
  form !: FormGroup;
  contractorMappingForm !: FormGroup;
  contractors: Contractor[] = [];
  errorMessage = '';
  contact !: Contact;
  userContractorMapping !: UserContractorMapping;
  allUsers: User[] = [];
  allContacts:Contact[]=[];
  isEdit = false;
  selectedContractorId: number = 0;
  displayedColumns: string[] = ['Contractor', 'Action'];
  contractorMappingDataSource = new MatTableDataSource<ContactContractorMapping>(ContactLists);
  noRecordFoundMsg: string = myGlobals.NoRecordFound;
  _projectID = parseInt(sessionStorage.getItem("projectID")!);
  _contractorID = parseInt(sessionStorage.getItem("contractorId")!);
  _userIdLogin = parseInt(sessionStorage.getItem("userId")!);
  _userName = sessionStorage.getItem("userFName") + " " + sessionStorage.getItem("userLName");
  _userRoleMappingId = parseInt(sessionStorage.getItem("userRoleMappingId")!);

  commonFunction!: CommonFunction;
  
  @ViewChild('sortContact') sortContact !: MatSort;
  @ViewChild('paginatorContact') paginatorContact !: MatPaginator;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sortContact = ms;
    this.contractorMappingDataSource.paginator = this.paginatorContact;
    this.contractorMappingDataSource.sort = this.sortContact;
  }

  constructor(private contractorService: ContractorService,
    private router: Router,
    private activatedRoute: ActivatedRoute,  private title: Title,
    private projectService: ProjectService,private rolePermissionService: RolePermissionService,
    private communicationService: CommunicationService,
    private logger: NGXLogger,
    private SpinnerService: NgxSpinnerService,
    private route: ActivatedRoute, private dialog: DialogService,
    private snackBar: MatSnackBar,
    private contactService: ContactService) {
    this.contact =  new Contact();
    this.commonFunction = new CommonFunction(router, projectService, rolePermissionService, activatedRoute, title);
  }

  ngOnInit(): void {
    this.commonFunction.setPageTitle();
    this.contractorMappingForm = new FormGroup({
      Contractor: new FormControl('', [Validators.required]),
    });

    this.form = new FormGroup({
      FirstName: new FormControl('', [Validators.required]),
      LastName: new FormControl('', [Validators.required]),
      Email: new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$")]),
      Phone: new FormControl('', [Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
      IsDeleted: new FormControl(false),
    });
    this.form.controls['Email'].disable();
    this.contact.Id = this.route.snapshot.params['id']
    this.communicationService._interfaceId = 0;
    
    if (this.contact.Id > 0) {
      this.isEdit = true;
      this.getbyContactId(this.contact.Id,this._projectID);
    }
  }

  getbyContactId(Id: number,projectId:number) {
    try {
      this.SpinnerService.show();
      this.contactService.getContactById(Id, projectId).subscribe({
        next: Data => {
          this.contact = Data[0];
          if (this.contact.ContactContractorMappings.length > 0) {
            this.contractorMappingDataSource.data = this.contact.ContactContractorMappings.filter(x => !x.IsDeleted);
            this.fillContractor();
          } else {
            this.router.navigateByUrl("/AccessDenied");
          }
          
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  fillContractor(): void {
    try {
      this.SpinnerService.show();
      this.contractorService.getAllContractorsByProjectId(this._projectID).subscribe({
        next: contractors => {
          this.contractors = contractors;
          
          this.contact.ContactContractorMappings.forEach(contractor => {
            var resultIndex = this.contractors.findIndex(x=> x.Id == contractor.Contractor.Id);
            if (resultIndex !== -1) {
              this.contractors.splice(resultIndex, 1);
            }
        });

          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  save() {
    try {
      this.SpinnerService.show();

      if (this.form.valid) {
        if (this.contact.Id > 0) {                   
          this.contactService.getContactByIdwithallMapping(this.contact.Id).subscribe({
            next: contact => {
              this.contact.ContactContractorMappings.forEach(x => {
                if (x.IsDeleted == true) {
                  if (contact[0].ContactContractorMappings.filter(y => y.Id == x.Id).length > 0) {
                    contact[0].ContactContractorMappings.filter(y => y.Id == x.Id)[0].IsDeleted = true;
                  }
                }
              })

              if (contact[0].ContactContractorMappings.filter(x => !x.IsDeleted).length == 0) {
                this.contact.IsDeleted = true;
                this.contact.EmailSubscriptionUsers.forEach(x => {
                  x.IsDeleted = true;
                  x.ModifiedBy = this._userRoleMappingId;
                });
              } else {
                contact[0].ContactContractorMappings.forEach(x => {
                  if (x.IsDeleted == true) {

                    if (this.contact.EmailSubscriptionUsers.filter(y => y.ContractorId == x.ContractorId).length > 0) {
                      this.contact.EmailSubscriptionUsers.filter(y => y.ContractorId == x.ContractorId)[0].IsDeleted = true;
                    }
                  }
                })
              }
              this.contact.ModifiedBy = this._userRoleMappingId;


              this.contact.ContactContractorMappings.forEach(x => {
                x.Contractor = null;
              });

              //check newly added and same deleted mapping should not insert into database
              this.contact.ContactContractorMappings = this.contact.ContactContractorMappings.filter(x => (x.Id == 0 && !x.IsDeleted));

              this.contactService.put(this.contact).subscribe({
                next: allContacts => {
                  this.SpinnerService.hide();
                  this.snackBar.openFromComponent(SnackbarComponent, {
                    data: "Contact saved successfully!",
                    duration: myGlobals.snackBarDuration,
                    verticalPosition: myGlobals.snackBarVerticalPosition,
                    horizontalPosition: myGlobals.snackBarHorizontalPosition
                  });
                  this.redirecttoList();
                },
                error: err => {
                  this.SpinnerService.hide();
                  this.errorMessage = err
                  this.dialog.confirmDialog({
                    title: 'Error',
                    message: myGlobals.exceptionmessage,
                    cancelCaption: 'Close',
                  });
                  this.logger.error(err);
                }
              });
            }
          })
          this.SpinnerService.hide();            
        }
      }
      this.SpinnerService.hide();
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  redirecttoList() {
    this.router.navigateByUrl('/Contacts');
  }

  onCancel() {
    this.redirecttoList();
  }

  delete(contractorId: number) {
    var contactmappindex = this.contact.ContactContractorMappings.findIndex(x => x.ContractorId == contractorId && !x.IsDeleted);
    if (contactmappindex > -1) {
      this.contact.ContactContractorMappings[contactmappindex].IsDeleted = true;
      this.contact.ContactContractorMappings[contactmappindex].ModifiedBy = this._userRoleMappingId;
    }
    this.contractorMappingDataSource.data = this.contact.ContactContractorMappings.filter(x => !x.IsDeleted);
  }

  confirmCancelDialog(contractorId: number) {
    try {
      this.dialog
        .confirmDialog({
          title: 'Confirm Action',
          message: 'Do you want to delete contact from this contractor?',
          confirmCaption: 'Confirm',
          cancelCaption: 'Cancel',
        })
        .subscribe((confirmed) => {
          if (confirmed) {
            this.delete(contractorId);
          }
        });
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  addContractorMapping() {
    try {      
      if(this.selectedContractorId > 0){
        var contractorDetail = this.contractors.filter(x=> x.Id == this.selectedContractorId);
        this.contact.ContactContractorMappings.push({ Id: 0, ContactId: 0, ContractorId: this.selectedContractorId, Contractor: { Name: contractorDetail[0].Name }, CreatedBy: this._userRoleMappingId, IsDeleted: false });
      }        

      this.contractorMappingDataSource.data = this.contact.ContactContractorMappings.filter(x => !x.IsDeleted);

      //reset contractor dropdown for new mapping 
      this.contractorMappingForm.controls['Contractor'].reset();

      this.contact.ContactContractorMappings.forEach(contractor => {
        var resultIndex = this.contractors.findIndex(x=> x.Id == this.selectedContractorId);
        if (resultIndex !== -1) {
          this.contractors.splice(resultIndex, 1);
        }
    });

    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }
}
