import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Contractor } from '../../app_data/contractor';
import { ContractorService } from '../../services/contractor.service';
import { NGXLogger } from 'ngx-logger';
import * as myGlobals from '../../app_data/globals';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogService } from '../../services/dialog.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContactService } from 'src/app/services/contact.service';
import { Contact } from 'src/app/app_data/contact';
import { SnackbarComponent } from '../../shared/components/snackbar/snackbar.component';
import { CommonFunction } from 'src/app/app_data/commonFunction';
import { Title } from '@angular/platform-browser';
import { ProjectService } from 'src/app/services/project.service';
import { RolePermissionService } from 'src/app/services/rolepermission.service';

@Component({
  selector: 'app-contact-create',
  templateUrl: './contact-create.component.html',
  styleUrls: ['./contact-create.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ContactCreateComponent implements OnInit {
  form !: FormGroup;
  contractors: Contractor[] = [];
  errorMessage = '';
  contact !: Contact;
  selectedContractorId: number = 0;
  allContacts: Contact[] = [];
  commonFunction!: CommonFunction;
  _projectID = parseInt(sessionStorage.getItem("projectID")!);
  _contractorID = parseInt(sessionStorage.getItem("contractorId")!);
  _userIdLogin = parseInt(sessionStorage.getItem("userId")!);
  _userName = sessionStorage.getItem("userFName") + " " + sessionStorage.getItem("userLName");
  _userRoleMappingId = parseInt(sessionStorage.getItem("userRoleMappingId")!);

  constructor(private contractorService: ContractorService,
    private contactService: ContactService,
    private SpinnerService: NgxSpinnerService,
    private router: Router,
    private activatedRoute: ActivatedRoute,  private title: Title,
    private projectService: ProjectService,private rolePermissionService: RolePermissionService,
    private logger: NGXLogger, private dialog: DialogService,
    private snackBar: MatSnackBar) {
    this.contact = new Contact();
    this.commonFunction = new CommonFunction(router, projectService, rolePermissionService, activatedRoute, title);
  }

  ngOnInit(): void {
    this.commonFunction.setPageTitle();
    this.form = new FormGroup({
      Contractor: new FormControl('', [Validators.required]),
      FirstName: new FormControl('', [Validators.required]),
      LastName: new FormControl('', [Validators.required]),
      Email: new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$")]),
      Phone: new FormControl('', [Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
      IsDeleted: new FormControl(false),
    });

    this.fillContractor();
  }

  fillContractor(): void {
    try {
      this.SpinnerService.show();
      this.contractorService.getAllContractorsByProjectId(this._projectID).subscribe({
        next: contractors => {
          this.contractors = contractors;
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  save() {
    try {
      this.SpinnerService.show();
      if (this.form.valid) {
        this.contactService.getContactByEmail(this.contact.Email).subscribe({
          next: contacts => {
            this.allContacts = contacts.filter(x => !x.IsDeleted);
            let checkContactExists = this.allContacts.filter(x => x.Email === this.contact.Email && x.ProjectId == this._projectID && !x.IsDeleted);
            if (checkContactExists.length > 0) {
              this.SpinnerService.hide();
              this.dialog.confirmDialog({
                title: 'Warning',
                message: 'User email already exists for selected contractor. Please edit the record to add other contractor mapping.',
                cancelCaption: 'Close',
              });
            }
            else {
              this.contact.IsDeleted = false;
              this.contact.CreatedBy = this._userRoleMappingId;
              this.contact.ProjectId = this._projectID;
              this.contact.ContactContractorMappings.push({ Id: 0, ContactId: 0, ContractorId: this.selectedContractorId, CreatedBy: this._userRoleMappingId, IsDeleted: false })
              this.contactService.post(this.contact).subscribe(
                {
                  next: allusers => {
                    this.SpinnerService.hide();
                    this.snackBar.openFromComponent(SnackbarComponent, {
                      data: "Contact created successfully!",
                      duration: myGlobals.snackBarDuration,
                      verticalPosition: myGlobals.snackBarVerticalPosition,
                      horizontalPosition: myGlobals.snackBarHorizontalPosition
                    });
                    this.redirecttoList();
                  },
                  error: err => {
                    this.SpinnerService.hide();
                    this.errorMessage = err
                    this.dialog.confirmDialog({
                      title: 'Error',
                      message: myGlobals.exceptionmessage,
                      cancelCaption: 'Close',
                    });
                    this.logger.error(err);
                  }
                }
              );
            }
          },
          error: err => {
            this.SpinnerService.hide();
            this.errorMessage = err
            this.dialog.confirmDialog({
              title: 'Error',
              message: myGlobals.exceptionmessage,
              cancelCaption: 'Close',
            });
            this.logger.error(err);
          }
        })

      }
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  redirecttoList() {
    this.router.navigateByUrl('/Contacts');
  }

  onCancel() {
    this.redirecttoList();
  }

}
