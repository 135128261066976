import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { MsalGuard } from "@azure/msal-angular";
import { AppLayoutComponent } from "../app-layout/app-layout.component";
import { PermissionGuard } from "../authorization/permission.guard";
import { InvitationCreateComponent } from "./invitation-create.component";
import { InvitationListComponent } from "./invitation-list.component";
import { InvitationComponent } from "./invitation.component";


const invitaionRoutes: Routes = [
  {
    path: '',
    //component: AppLayoutComponent,
    children: [
      {
        path: 'ProjectInvitation', component: InvitationComponent, data: { title: 'Project Invitation' }
      },    
      { path: 'InviteUser', component: InvitationCreateComponent,
        canActivate: [MsalGuard,PermissionGuard], data: { perminssion: 'invite.user', title: 'Invitation' }
      }
    ]
  }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(invitaionRoutes)
  ]
})
export class InvitaionRoutingModule { }
