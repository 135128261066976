import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { InterfacegroupListComponent } from './interfacegroup-list.component';
import { PermissionGuard } from '../authorization/permission.guard';
import { MsalGuard } from '@azure/msal-angular';
import { InterfacegroupEditComponent } from './interfacegroup-edit.component';

const interfacegroupRoutes: Routes = [
  {
    path: '',
     //component: AppLayoutComponent,
    children: [     
      {
        path: 'InterfaceGroups', component: InterfacegroupListComponent,
        canActivate: [MsalGuard,PermissionGuard], data: { perminssion: 'interfacegroup.view', title: 'Interface Groups'}
      },
      { path: 'InterfaceGroupEdit/:id', component: InterfacegroupEditComponent,
        canActivate: [MsalGuard,PermissionGuard], data:{perminssion:'interfacegroup.edit', title: 'Edit Interface Group'} },
    ]
  }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(interfacegroupRoutes)
    ]
})

export class InterfacegroupRoutingModule { }
