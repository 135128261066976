import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RiskListComponent } from './risk-list.component';
import { RiskCreateComponent } from './risk-create.component';
import { AppLayoutComponent } from '../app-layout/app-layout.component';
import { PermissionGuard } from '../authorization/permission.guard';
import { MsalGuard } from '@azure/msal-angular';
import { RouterModule, Routes } from '@angular/router';
import { RiskViewComponent } from './risk-view.component';
import { RiskEditComponent } from './risk-edit.component';
const RiskRoutes: Routes = [
  {
    path: '',
    //component: AppLayoutComponent,
    children: [
      {
        path: 'Risk', component: RiskListComponent,
        canActivate: [MsalGuard, PermissionGuard], data: { perminssion: 'risk.view', title: 'Risk' }
      },
      {
        path: 'RiskCreate', component:RiskCreateComponent,
        canActivate: [MsalGuard, PermissionGuard], data: { perminssion: 'risk.create', title: 'Create Risk' }
      }
      ,
      {
        path: 'RiskView/:id', component: RiskViewComponent,
        canActivate: [MsalGuard, PermissionGuard], data: { perminssion: 'risk.view', title: 'View Risk' }
      }
      ,
      {
        path: 'RiskEdit/:id', component: RiskEditComponent,
        canActivate: [MsalGuard, PermissionGuard], data: { perminssion: 'risk.edit', title: 'Edit Risk' }
      }
    ]
  }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(RiskRoutes)
  ]
})
export class RiskRoutingModule { }
