import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { response } from '../app_data/OdataResponse';
import { Interface } from '../app_data/interface';
import { environment } from 'src/environments/environment';
import { CommonFunction } from '../app_data/commonFunction';

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
    "Accept": "*/*",
  }
  )
}

@Injectable({
  providedIn: 'root'
})


export class InterfaceService {
  _projectTimeZone = sessionStorage.getItem("projectTimeZone")!;
  private apiUrl = environment.apiURl + 'Interfaces';

  filterColumns = [
    { "DisplayName": "Expected Response Date", "Value": "FirstResponseDate", "Type": "Interface" },
    { "DisplayName": "Expected Response Date", "Value": "FirstResponseDate", "Type": "TQ" },

    { "DisplayName": "Requesting Contractor", "Value": "RequestingContractor", "Type": "Interface" },
    { "DisplayName": "Requesting Contractor", "Value": "RequestingContractor", "Type": "TQ" },

    { "DisplayName": "Provider Contractor", "Value": "ProviderContractor", "Type": "Interface" },
    { "DisplayName": "Provider Contractor", "Value": "ProviderContractor", "Type": "TQ" },

    { "DisplayName": "Created Date", "Value": "CreatedDate", "Type": "Interface" },
    { "DisplayName": "Created Date", "Value": "CreatedDate", "Type": "TQ" },

    { "DisplayName": "Interface Group", "Value": "InterfaceGroup", "Type": "Interface" },
    { "DisplayName": "Interface Group", "Value": "InterfaceGroup", "Type": "TQ" },

    { "DisplayName": "Interface Number", "Value": "InterfaceNumber", "Type": "Interface" },
    { "DisplayName": "Technical Query Number", "Value": "InterfaceNumber", "Type": "TQ" },

    { "DisplayName": "Priority", "Value": "Priority", "Type": "Interface" },
    { "DisplayName": "Priority", "Value": "Priority", "Type": "TQ" },

    { "DisplayName": "Status", "Value": "Status", "Type": "Interface" },
    { "DisplayName": "Status", "Value": "Status", "Type": "TQ" },

    { "DisplayName": "Title", "Value": "Title", "Type": "Interface" },
    { "DisplayName": "Title", "Value": "Title", "Type": "TQ" },

    { "DisplayName": "Query Type", "Value": "QueryType", "Type": "TQ" },

    { "DisplayName": "Assigned To", "Value": "AssignedTo", "Type": "Interface" },
    { "DisplayName": "Assigned To", "Value": "AssignedTo", "Type": "TQ" }];

  sortColumns = [
    { "DisplayName": "Newest First", "Value": "NewestFirst" },
    { "DisplayName": "Oldest First", "Value": "OldestFirst" },
    { "DisplayName": "Expected Response Date (Newest First)", "Value": "FRD_NewestFirst" },
    { "DisplayName": "Expected Response Date (Oldest First)", "Value": "FRD_OldestFirst" },
    { "DisplayName": "Status", "Value": "Status" }];

  constructor(private http: HttpClient) {
  }


  getInterfaces(filter: string): Observable<Interface[]> {
    return this.http.get<response>(this.apiUrl + "?$expand=MinutesOfMeetingDetails,ResContractor,InterfaceGroup,RecDiscipline,ResDiscipline,InterfaceStatus,Prioritys,RecContractor,Documents($filter=IsDeleted eq false),InterfaceStatus,Revisions,InterfaceProjectFieldsMappings($filter=IsDeleted eq false;$expand=ProjectFieldsConfiguration),AssignmentHistory($orderby=Id desc;$top=1;$expand=AssignedToUser,AssignedToRole)" + filter )
      .pipe(
        map((data: response) => {
          // convert all dates to timezone datetime        
          return this.updateflag(data.value);
        }),
        catchError(this.handleError)
      );
  }

  getInterfacesByNumberPattern(projectId: number, numberPattern: string): Observable<Interface[]> {
    return this.http.get<response>(this.apiUrl + "?$filter=ProjectId eq " + projectId + " and InterfaceType eq 'TQ' and startswith(Number,'" + numberPattern + "')&$top=1&$orderby=Id desc")
      .pipe(
        map((data: response) => {
          // convert all dates to timezone datetime        
          return this.updateflag(data.value);
        }),
        catchError(this.handleError)
      );
  }

  updateflag(data: Interface[]) {
    // convert all dates to timezone datetime
    data.forEach(x => {
      x.IsApprovedIssuePlannedOverdue = CommonFunction.IsApprovedIssuedPlannedOverDue(new Date(x.ApprovedIssuePlanned!), CommonFunction.getTimeZoneNowUTC(this._projectTimeZone), x.Status);
      x.IsFirstIssuePlannedOverdue = CommonFunction.IsFirstIssuePlannedOverdue(new Date(x.FirstIssuePlanned!), CommonFunction.getTimeZoneNowUTC(this._projectTimeZone), x.Status);
      switch (x.Status) {
        case 1://Identified
          x.InterfaceStatusName = 'Identified';
          break;
        case 2://Open
          x.InterfaceStatusName = (x.IsFirstIssuePlannedOverdue && x.Response == "") ? 'Response Overdue' : (x.IsApprovedIssuePlannedOverdue && x.Response != "") ? 'Closeout Overdue' : 'Open';
          break;
        case 3://Closed;
          x.InterfaceStatusName = 'Closed';
          break;
        case 4://Awaiting for Request approval
          x.InterfaceStatusName = 'Awaiting for Request approval';
          break;
        case 5://Awaiting Response
          x.InterfaceStatusName = (x.IsFirstIssuePlannedOverdue && x.Response == "") ? 'Response Overdue' : (x.IsApprovedIssuePlannedOverdue && x.Response != "") ? 'Closeout Overdue' : 'Awaiting Response';
          break;
        case 6://Awaiting for Response approval
          x.InterfaceStatusName = (x.IsFirstIssuePlannedOverdue && x.Response != "") ? 'Response Overdue' : 'Awaiting for Response approval';
          break;
        case 7://Awaiting for Response acceptance
          x.InterfaceStatusName = (x.IsFirstIssuePlannedOverdue && x.Response == "") ? 'Response Overdue' : (x.IsApprovedIssuePlannedOverdue && x.Response != "") ? 'Closeout Overdue' : 'Awaiting for Response acceptance';
          break;
        case 8: //Awaiting For CloseOut
          x.InterfaceStatusName = (x.IsFirstIssuePlannedOverdue && x.Response == "") ? 'Response Overdue' : (x.IsApprovedIssuePlannedOverdue && x.Response != "") ? 'Closeout Overdue' : 'Awaiting for close out';
          break;
        case 9: //Responded
          x.InterfaceStatusName = (x.IsFirstIssuePlannedOverdue && x.Response == "") ? 'Response Overdue' : (x.IsApprovedIssuePlannedOverdue && x.Response != "") ? 'Closeout Overdue' : 'Responded';
          break;
        case 10: //Response Rejected 
          x.InterfaceStatusName = (x.IsFirstIssuePlannedOverdue && x.Response == "") ? 'Response Overdue' : (x.IsApprovedIssuePlannedOverdue && x.Response != "") ? 'Closeout Overdue' : 'Response Rejected';
          break;
        case 11: //Acceptance Rejected 
          x.InterfaceStatusName = (x.IsFirstIssuePlannedOverdue && x.Response == "") ? 'Response Overdue' : (x.IsApprovedIssuePlannedOverdue && x.Response != "") ? 'Closeout Overdue' : 'Acceptance Rejected';
          break;
      }
    })
    return data;
  }

  getInterfaceswithAdditionalFilters(filter: string): Observable<Interface[]> {
    return this.http.get<response>(this.apiUrl + "?$expand=MinutesOfMeetingDetails,ResContractor,Prioritys,RecContractor,InterfaceGroup,RecDiscipline,ResDiscipline,Documents($filter=IsDeleted eq false),ReferenceLinks($filter=IsDeleted eq false;$expand=CreatedUserRoleMapping($expand=User,Contractor,Role;$filter=IsDeleted eq false)),InterfaceStatus,Revisions,InterfaceProjectFieldsMappings($filter=IsDeleted eq false;$expand=ProjectFieldsConfiguration),InterfaceResponses($expand=CreatedUserRoleMapping($expand=User,Contractor,Role),Contractor),AdditionalInformationHistory,AssignmentHistory($orderby=Id desc;$expand=AssignedToUser,AssignedToRole),ProposedResponseHistory,TechnicalQuery($expand=QueryType)" + filter)
      .pipe(
        map((data: response) => {
          // convert all dates to timezone datetime        
          return this.updateflag(data.value);
        }),
        catchError(this.handleError)
      );
  }
  getAllInterfaces(filter: string): Observable<Interface[]> {
    return this.http.get<response>(this.apiUrl + "?$expand=InterfaceGroup,ResContractor,RecContractor,Prioritys,InterfaceStatus,RecDiscipline,ResDiscipline,Prioritys,CreatedUserRoleMapping($expand=User,Contractor,Role;$filter=IsDeleted eq false),CompletedByUser,InterfaceProjectFieldsMappings($filter=IsDeleted eq false;$expand=ProjectFieldsConfiguration),TechnicalQuery($expand=QueryType,SharedContractor,RouteToUser),AdditionalInformationHistory,AssignmentHistory($orderby=Id desc;$expand=AssignedToUser,AssignedToRole),ProposedResponseHistory" + filter)
      .pipe(
        map((data: response) => {
          // convert all dates to timezone datetime        
          return this.updateflag(data.value);
        }),
        catchError(this.handleError)
      );
  }

  getAllInterfacesByInterfaceGroupIdMOM(projectId: number, interfaceGroupId: number, interfaceType: string): Observable<Interface[]> {
    var interfaceTypeStr = interfaceType.toLowerCase() == "interface" ? "" : interfaceType;
    return this.http.get<response>(this.apiUrl + "?$expand=InterfaceGroup&$filter=ProjectId eq  " + projectId + " and IsDeleted eq false and InterfaceGroup/Id eq " + interfaceGroupId + " and InterfaceType eq '" + interfaceTypeStr + "' and Status ne 3 and Status ne 12 &$orderby=Id desc ")
      .pipe(
        map((data: response) => {
          // convert all dates to timezone datetime        
          return this.updateflag(data.value);
        }),
        catchError(this.handleError)
      );
  }

  getInterfacesByProjectId(Id: number): Observable<Interface[]> {
    return this.http.get<response>(this.apiUrl + "?$expand=InterfaceGroup&$filter=ProjectId eq " + Id + " and IsDeleted eq false &$orderby=Id desc")
      .pipe(
        map((data: response) => {
          // convert all dates to timezone datetime        
          return this.updateflag(data.value);
        }),
        catchError(this.handleError)
      );
  }

  getInterfacesByProjectIdAndModuleId(projectId: number,moduleId:number): Observable<Interface[]> {
    return this.http.get<response>(this.apiUrl + "?$expand=InterfaceGroup&$filter=ProjectId eq " + projectId + " and ModuleId eq "+moduleId+" and IsDeleted eq false &$orderby=Id desc")
      .pipe(
        map((data: response) => {
          // convert all dates to timezone datetime        
          return this.updateflag(data.value);
        }),
        catchError(this.handleError)
      );
  }


  getInterfacesByProjectIdAndInterfaceType(Id: number, interfaceType: string): Observable<Interface[]> {
    return this.http.get<response>(this.apiUrl + "?$expand=InterfaceGroup&$filter=ProjectId eq " + Id + " and InterfaceType eq '" + interfaceType + "' and  IsDeleted eq false &$orderby=Id desc")
      .pipe(
        map((data: response) => {
          // convert all dates to timezone datetime        
          return this.updateflag(data.value);
        }),
        catchError(this.handleError)
      );
  }


  getFilterColumnList() {
    return this.filterColumns;
  }
  getFilterColumnListByType(type: string) {
    return this.filterColumns.filter(x => x.Type == type);
  }


  getSortColumnList() {
    return this.sortColumns;
  }

  getOverallStatusSummaryByProjectId(Id: number) {
    return this.http.get<response>(this.apiUrl + "?$apply=groupby((Status), aggregate($count as InterfaceCount))&$filter=ProjectId eq " + Id + " and IsDeleted eq false ")
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }
  getInterfacesForCount(filter: string): Observable<Interface[]> {
    return this.http.get<response>(this.apiUrl + "?$select=Id" + filter)
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  getInterfaceDataForEmailNotifcationById(interfaceId: number): Observable<Interface[]> {
    return this.http.get<response>(this.apiUrl + "?$filter=ID eq " + interfaceId + " and IsDeleted eq false &$expand=CreatedByRole,Module,Project,RequestApprover,ProposedResponseHistory,AdditionalInformationHistory($filter=IsDeleted eq false;$expand=AssignedToUser),AssignmentHistory($filter=IsDeleted eq false;$expand=AssignedToUser($expand=UserRoleProjects($expand=Role)),AssignedToRole), Prioritys, AcceptedByUser, CompletedByUser, ResContractor, RecContractor, InterfaceStatus, ResDiscipline, RecDiscipline, Prioritys, InterfaceGroup($filter = IsDeleted eq false; $expand = InterfaceGroupTemplateMappings), InterfaceResponses($expand =Contractor), Discussions($expand = Contractor), TechnicalQuery($expand = QueryType), Stage")
      .pipe(
        map((data: response) => {
          // convert all dates to timezone datetime        
          return this.updateflag(data.value);
        }),
        catchError(this.handleError)
      );
  }


  getInterfaceByIdandProjectId(interfaceId: number, projectId: number): Observable<Interface[]> {
    return this.http.get<response>(this.apiUrl + "?$filter=ID eq " + interfaceId + " and ProjectId eq " + projectId + " and IsDeleted eq false &$expand=CreatedUserRoleMapping($expand=User),CreatedByRole,RequestApprover,ProposedResponseHistory,AdditionalInformationHistory,AssignmentHistory,MinutesOfMeetingDetails($filter=IsDeleted eq false),Documents($filter=IsDeleted eq false;$expand=Contractor,CreatedUserRoleMapping($expand=User,Contractor,Role);$orderby=Id desc),Prioritys,AcceptedByUser,CompletedByUser,ReferenceLinks($filter=IsDeleted eq false;$expand=CreatedUserRoleMapping($expand=User,Contractor,Role)),ResContractor,RecContractor,InterfaceStatus,ResDiscipline,RecDiscipline,Prioritys,InterfaceGroup($expand=InterfaceGroupTemplateMappings),InterfaceResponses($expand=CreatedUserRoleMapping($expand=User,Contractor,Role),Contractor),Revisions($expand=InterfaceStatus),TechnicalQuery($expand=QueryType),Stage,InterfaceProjectFieldsMappings($filter=IsDeleted eq false;$expand=ProjectFieldsConfiguration)")
      .pipe(
        map((data: response) => {
          // convert all dates to timezone datetime        
          return this.updateflag(data.value);
        }),
        catchError(this.handleError)
      );
  }
  
  getInterfaceById(interfaceId: number): Observable<Interface[]> {
    return this.http.get<response>(this.apiUrl + "?$filter=ID eq " + interfaceId + " and IsDeleted eq false &$expand=CreatedUserRoleMapping($expand=User),CreatedByRole,RequestApprover,ProposedResponseHistory($expand=CreatedUserRoleMapping($expand=User,Contractor,Role)),AdditionalInformationHistory($expand=AssignedToUser,CreatedUserRoleMapping($expand=User,Contractor,Role)),AssignmentHistory,MinutesOfMeetingDetails($filter=IsDeleted eq false),Documents($filter=IsDeleted eq false;$expand=Contractor,CreatedUserRoleMapping($expand=User,Contractor,Role);$orderby=Id desc),Prioritys,AcceptedByUser,CompletedByUser,ReferenceLinks($filter=IsDeleted eq false;$expand=CreatedUserRoleMapping($expand=User,Contractor,Role)),ResContractor,RecContractor,InterfaceStatus,ResDiscipline,RecDiscipline,Prioritys,InterfaceGroup($expand=InterfaceGroupTemplateMappings),InterfaceResponses($expand=CreatedUserRoleMapping($expand=User,Contractor,Role),Contractor),Revisions($expand=InterfaceStatus),Discussions($expand=Contractor,CreatedUserRoleMapping($expand=User,Contractor,Role)),TechnicalQuery($expand=QueryType),Stage,InterfaceProjectFieldsMappings($filter=IsDeleted eq false;$expand=ProjectFieldsConfiguration)")
      .pipe(
        map((data: response) => {
          // convert all dates to timezone datetime        
          return this.updateflag(data.value);
        }),
        catchError(this.handleError)
      );
  }

  getInterfaceWithAssignmentsProposedHistoryAndAdditionalInformationById(interfaceId: number): Observable<Interface[]> {
    return this.http.get<response>(this.apiUrl + "?$filter=ID eq " + interfaceId + " and IsDeleted eq false &$expand=TechnicalQuery,CreatedUserRoleMapping($expand=User),CreatedByRole,ProposedResponseHistory($expand=CreatedUserRoleMapping($expand=User,Contractor,Role)),AdditionalInformationHistory($expand=AssignedToUser,CreatedUserRoleMapping($expand=User,Contractor,Role)),AssignmentHistory($expand=AssignedToUser,AssignedToRole,CreatedUserRoleMapping($expand=User,Contractor,Role)),Documents($filter=IsDeleted eq false;$expand=Contractor,CreatedUserRoleMapping($expand=User,Contractor,Role);$orderby=Id desc),ReferenceLinks($filter=IsDeleted eq false;$expand=CreatedUserRoleMapping($expand=User,Contractor,Role))")
      .pipe(
        map((data: response) => {
          // convert all dates to timezone datetime        
          return this.updateflag(data.value);
        }),
        catchError(this.handleError)
      );
  }

  getCopiedInterfaceById(interfaceId: number): Observable<Interface[]> {
    return this.http.get<response>(this.apiUrl + "?$filter=ID eq " + interfaceId + " and IsDeleted eq false &$expand=Documents($filter=IsDeleted eq false;$expand=CreatedUserRoleMapping($expand=User,Contractor,Role;$filter=IsDeleted eq false)),ResContractor,RecContractor,InterfaceStatus,ResDiscipline,RecDiscipline,Prioritys,ReferenceLinks($filter=IsDeleted eq false;$expand=CreatedUserRoleMapping($expand=User,Contractor,Role;$filter=IsDeleted eq false)),Discussions($expand=Contractor,CreatedUserRoleMapping($expand=User,Contractor,Role;$filter=IsDeleted eq false)),TechnicalQuery,InterfaceProjectFieldsMappings($filter=IsDeleted eq false;$expand=ProjectFieldsConfiguration)")
      .pipe(
        map((data: response) => {
          // convert all dates to timezone datetime        
          return this.updateflag(data.value);
        }),
        catchError(this.handleError)
      );
  }


  post(model: Interface): Observable<Interface> {
    return this.http.post<Interface>(this.apiUrl, model, httpOptions)
  }


  put(model: Interface): Observable<Interface> {
    return this.http.put<Interface>(this.apiUrl + "/" + model.Id, model, httpOptions);
  }


  private handleError(err: HttpErrorResponse) {

    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {

      errorMessage = `An error occurred: ${err.error.message}`;
    } else {

      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    return throwError(errorMessage);
  }

}
